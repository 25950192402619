:root {
  --enhanced-media-query: "(min-height: 500px) and (max-height: 1500px)";
}

@media only screen and (max-width: 1068px) {
  :root {
    --enhanced-media-query: "(min-height: 415px) and (max-height: 1366px)";
  }
}
@media only screen and (max-width: 734px) {
  :root {
    --enhanced-media-query: "(min-height: 415px) and (max-height: 850px)";
  }
}
.section-hero {
  overflow: hidden;
}
@media only screen and (max-width: 734px) {
  .section-hero {
    padding-bottom: 35px;
    height: 100%;
  }
}

.section-hero .section-content {
  padding-top: 68px;
  padding-bottom: 94px;
}
@media only screen and (max-width: 1068px) {
  .section-hero .section-content {
    padding-top: 54px;
    padding-bottom: 73px;
  }
}
@media only screen and (max-width: 734px) {
  .section-hero .section-content {
    padding-top: 48px;
    padding-bottom: 17px;
  }
}

.section-hero .hero-picture {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 761px;
  --p-height: 432px;
  position: relative;
  margin: 78px auto 72px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-hero .hero-picture {
    --p-width: 571px;
    --p-height: 324px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .section-hero .hero-picture {
    --p-width: 261px;
    --p-height: 149px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-hero .hero-picture {
    margin-top: 48px;
    margin-bottom: 36px;
  }
}
@media only screen and (max-width: 734px) {
  .section-hero .hero-picture {
    margin-top: 30px;
    margin-bottom: 24px;
  }
}
.section-hero .hero-header {
  text-align: center;
}
.section-hero .intro-copy {
  font-weight: 600;
  color: rgb(134, 134, 139);
}
.section-hero .intro-copy a {
  color: rgb(134, 134, 139);
}
@media only screen and (max-width: 734px) {
  .section-hero .intro-copy {
    max-width: 536px;
    margin-left: auto;
    margin-right: auto;
  }
}

html.enhance-base-xp .section-hero .hero-header,
html.enhance-base-xp .section-hero .hero-picture img {
  opacity: 0;
}

.section-flex-tiles picture,
.section-flex-tiles picture img {
  display: block;
}

@media only screen and (max-width: 1068px) {
  .section-flex-tiles .tile-headline.typo-product-tile-headline {
    letter-spacing: -0.15px;
  }
}
.section-flex-tiles picture,
.section-flex-tiles picture img {
  display: block;
}
.section-flex-tiles .tile {
  height: -webkit-fill-available;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-bottom: 0;
  grid-column: span 6;
  box-shadow: var(--fds-box-shadow);
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .tile {
    grid-column: span 12;
    max-width: 380px;
  }
}
.section-flex-tiles .tile-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 0;
  padding-top: 40px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .tile-content {
    padding-top: 19px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .tile-content {
    padding-top: 25px;
  }
}
.section-flex-tiles .tile-cta {
  padding-bottom: 0;
  display: flex;
  justify-content: center;
}
.section-flex-tiles .grid {
  width: 100%;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .grid {
    padding-left: 0;
    padding-right: 0;
  }
}
.section-flex-tiles .grid-item {
  grid-column: span 6;
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .grid-item {
    grid-column: span 12;
    min-height: 500px;
  }
}
.section-flex-tiles .grid-item .copy-wrapper .tile-headline,
.section-flex-tiles .grid-item .tile-cta a {
  display: flex;
}
.section-flex-tiles .grid-item .copy-wrapper .tile-headline span.icon-copy,
.section-flex-tiles .grid-item .tile-cta a span.icon-copy {
  cursor: pointer;
}
.section-flex-tiles .grid-item.half-width {
  display: grid;
  justify-items: stretch;
  align-items: stretch;
}

.section-flex-tiles .grid-item.full-width {
  grid-column: span 12;
  min-height: 500px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .grid-item.full-width {
    min-height: 340px;
  }
}
.section-flex-tiles .grid-item.full-width .tile-content {
  flex-direction: row;
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .grid-item.full-width .tile-content {
    flex-direction: column;
  }
}
.section-flex-tiles .grid-item.full-width .copy-wrapper {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex-basis: 50%;
  max-width: 50%;
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .grid-item.full-width .copy-wrapper {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.section-flex-tiles .grid-item.full-width .picture-wrapper {
  flex-basis: 50%;
  max-width: 50%;
  margin-top: auto;
  justify-content: flex-start;
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .grid-item.full-width .picture-wrapper {
    justify-content: center;
    flex-basis: 100%;
    max-width: 100%;
  }
}

html.enhance-base-xp .section-flex-tiles .grid-item {
  transition: transform 1000ms, opacity 1000ms;
  opacity: 0;
  transform: translateY(50px);
}
html.enhance-base-xp .section-flex-tiles .grid-item.appear {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (min-width: 735px) {
  html.enhance-base-xp .section-flex-tiles .grid-item.appear:nth-child(2n):not(.full-width) {
    transition-delay: 0.3s;
  }
}
.section-flex-tiles .tile-logo img {
  margin: 0 auto;
}
.section-flex-tiles .tile-headline {
  margin-top: 12px;
}

html.text-zoom .section-flex-tiles .tile-headline {
  font-size: var(--large-title);
}
@media only screen and (max-width: 1068px) {
  html.text-zoom .section-flex-tiles .tile-headline {
    font-size: var(--title-3);
  }
}

.section-flex-tiles .tile-price {
  font-size: var(--body);
  line-height: 1.4705882353;
  font-weight: 400;
  letter-spacing: -0.022em;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .tile-price {
    font-size: var(--body);
    line-height: 1.4705882353;
    font-weight: 400;
    letter-spacing: -0.022em;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .tile-price {
    margin-top: 0.6em;
  }
}
.section-flex-tiles .tile-cta {
  margin-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .tile-cta {
    margin-top: 14px;
    padding-bottom: 4px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .tile-cta {
    margin-top: 10px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .tile-cta .button-elevated {
    padding: 8px 16px;
  }
}
.section-flex-tiles .button:not(:last-child) {
  margin-right: 16px;
}
.section-flex-tiles .picture-wrapper {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media only screen and (min-width: 735px) and (max-width: 1068px) {
  .section-flex-tiles .lightning-protection .tile-content {
    padding-top: 16px;
  }
}
.section-flex-tiles .lightning-protection .copy-wrapper,
.section-flex-tiles .lps-health .copy-wrapper,
.section-flex-tiles .cabling-certification .copy-wrapper,
.section-flex-tiles .fusion-splicing .copy-wrapper {
  padding-top: 6px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lightning-protection .copy-wrapper,
  .section-flex-tiles .lps-health .copy-wrapper,
  .section-flex-tiles .cabling-certification .copy-wrapper,
  .section-flex-tiles .fusion-splicing .copy-wrapper {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lightning-protection .copy-wrapper,
  .section-flex-tiles .lps-health .copy-wrapper,
  .section-flex-tiles .cabling-certification .copy-wrapper,
  .section-flex-tiles .fusion-splicing .copy-wrapper {
    padding-bottom: 5px;
  }
}
.section-flex-tiles .lightning-protection .tile-eyebrow,
.section-flex-tiles .lps-health .tile-eyebrow,
.section-flex-tiles .cabling-certification .tile-eyebrow,
.section-flex-tiles .fusion-splicing .tile-eyebrow {
  margin-top: 37px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lightning-protection .tile-eyebrow,
  .section-flex-tiles .lps-health .tile-eyebrow,
  .section-flex-tiles .cabling-certification .tile-eyebrow,
  .section-flex-tiles .fusion-splicing .tile-eyebrow {
    margin-top: 34px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lightning-protection .tile-eyebrow,
  .section-flex-tiles .lps-health .tile-eyebrow,
  .section-flex-tiles .cabling-certification .tile-eyebrow,
  .section-flex-tiles .fusion-splicing .tile-eyebrow {
    margin-top: 5px;
  }
}

.section-flex-tiles .lightning-protection .tile-violator {
  position: absolute;
  width: 100%;
  left: 0;
  top: 61px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lightning-protection .tile-violator {
    top: 35px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lightning-protection .tile-violator {
    top: 15px;
  }
}
.section-flex-tiles .lightning-protection .tile-headline {
  max-width: 384px;
  margin-top: 10px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lightning-protection .tile-headline {
    max-width: 268px;
    margin-top: 12px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lightning-protection .tile-headline {
    max-width: 230px;
    margin-top: 8px;
  }
}
.section-flex-tiles .lightning-protection .tile-cta {
  margin-top: 0.8em;
  padding-bottom: 0;
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lightning-protection .tile-cta {
    padding-bottom: 12px;
  }
}
.section-flex-tiles .lightning-protection .tile-cta .button {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lightning-protection .tile-cta .button {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lightning-protection .tile-cta .button {
    margin-bottom: 8px;
  }
}
.section-flex-tiles .lightning-protection .tile-cta a {
  margin-right: 8px;
  margin-left: 8px;
}

.section-flex-tiles .theme-dark .tile-availability {
  color: rgb(134, 134, 139);
}

.section-flex-tiles .lps-health .copy-wrapper {
  padding-top: 6px;
}
.section-flex-tiles .lps-health .tile-headline {
  max-width: 384px;
  margin-top: 10px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lps-health .tile-headline {
    max-width: 268px;
    margin-top: 12px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lps-health .tile-headline {
    max-width: 230px;
    margin-top: 8px;
  }
}
.section-flex-tiles .lps-health .tile-cta {
  margin-top: 0.8em;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .lps-health .picture-wrapper img {
    width: 334px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .lps-health .picture-wrapper img {
    width: 480px;
  }
}

@media only screen and (min-width: 735px) and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification .tile-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 735px) and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification .tile-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.section-flex-tiles .cabling-certification .copy-wrapper {
  position: relative;
  padding-top: 6px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification .copy-wrapper {
    padding-top: 37px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .cabling-certification .copy-wrapper {
    padding-top: 33px;
  }
}
.section-flex-tiles .cabling-certification .tile-violator {
  position: absolute;
  width: 100%;
  left: 0;
  top: -3px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification .tile-violator {
    top: 13px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .cabling-certification .tile-violator {
    top: 7px;
  }
}
.section-flex-tiles .cabling-certification .tile-logo {
  margin-top: -6px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification .tile-logo {
    margin-bottom: 2px;
  }
}
.section-flex-tiles .cabling-certification .tile-headline {
  max-width: 470px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification .tile-headline {
    max-width: 318px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .cabling-certification .tile-headline {
    max-width: 282px;
  }
}
.section-flex-tiles .cabling-certification .tile-cta {
  padding-bottom: 0;
  margin-top: 0.8em;
}
@media only screen and (min-width: 735px) {
  .section-flex-tiles .cabling-certification.full-width .tile-content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.section-flex-tiles .cabling-certification.full-width .tile-headline {
  max-width: 590px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification.full-width .tile-headline {
    max-width: 310px;
  }
}
.section-flex-tiles .cabling-certification.full-width .tile-cta {
  margin-top: 36px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .cabling-certification.full-width .tile-cta {
    margin-top: 37px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .cabling-certification.full-width .tile-cta {
    margin-top: 0.8em;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .cabling-certification.full-width .picture-wrapper {
    padding-top: 15px;
  }
}

.section-flex-tiles .fusion-splicing .copy-wrapper {
  padding-top: 6px;
}
.section-flex-tiles .fusion-splicing .tile-headline {
  max-width: 384px;
  margin-top: 10px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .fusion-splicing .tile-headline {
    max-width: 268px;
    margin-top: 12px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .fusion-splicing .tile-headline {
    max-width: 230px;
    margin-top: 8px;
  }
}
.section-flex-tiles .fusion-splicing .tile-cta {
  display: block;
  margin-top: 16px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .fusion-splicing .tile-cta {
    margin-top: 12px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .fusion-splicing .tile-cta {
    margin-top: 12px;
  }
}
.section-flex-tiles .fusion-splicing.half-width .picture-wrapper {
  padding-top: 28px;
}
@media only screen and (min-width: 735px) {
  .section-flex-tiles .fusion-splicing.full-width .tile-content {
    padding-top: 0;
  }
}
.section-flex-tiles .fusion-splicing .tile-content {
  background: var(--fds-fill);
}
.section-flex-tiles .fusion-splicing .overview-fusion-splicing {
  position: relative;
  left: 50%;
  margin-left: -341.5px;
}
@media only screen and (max-width: 1068px) {
  .section-flex-tiles .fusion-splicing .overview-fusion-splicing {
    left: 50%;
    margin-left: -164.5px;
  }
}
@media only screen and (max-width: 734px) {
  .section-flex-tiles .fusion-splicing .overview-fusion-splicing {
    left: 50%;
    margin-left: -240px;
  }
}

.overview-lightning-protection {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 678px;
  --p-height: 457px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .overview-lightning-protection {
    --p-width: 334px;
    --p-height: 296px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .overview-lightning-protection {
    --p-width: 480px;
    --p-height: 280px;
  }
}

.overview-lps-health {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 678px;
  --p-height: 457px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .overview-lps-health {
    --p-width: 334px;
    --p-height: 296px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .overview-lps-health {
    --p-width: 480px;
    --p-height: 280px;
  }
}

.overview-cabling-certification {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 678px;
  --p-height: 457px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .overview-cabling-certification {
    --p-width: 334px;
    --p-height: 296px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .overview-cabling-certification {
    --p-width: 480px;
    --p-height: 280px;
  }
}

.overview-fusion-splicing {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 678px;
  --p-height: 457px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .overview-fusion-splicing {
    --p-width: 334px;
    --p-height: 296px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .overview-fusion-splicing {
    --p-width: 480px;
    --p-height: 280px;
  }
}

.overview-fusion-splicing-full {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 690px;
  --p-height: 520px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .overview-fusion-splicing-full {
    --p-width: 350px;
    --p-height: 340px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .overview-fusion-splicing-full {
    --p-width: 380px;
    --p-height: 266px;
  }
}

.section-partner {
  position: relative;
  --transition-duration: 0.25s;
  --image-size: 380px;
  --slide-min-height: 480px;
  --slide-large-min-height: 520px;
  --slide-min-screen-width: 1680;
}
@media only screen and (max-width: 1068px) {
  .section-partner {
    --image-size: 336px;
    --slide-min-height: 380px;
    --slide-large-min-height: 480px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner {
    --image-size: 280px;
    --slide-min-height: 410px;
    --slide-large-min-height: 480px;
    text-align: left;
  }
}
.section-partner picture {
  display: block;
}
.section-partner picture img {
  display: block;
}

html.enhance-base-xp .section-partner .section-content {
  transition: 1000ms;
  opacity: 0;
  transform: translateY(50px);
}
html.enhance-base-xp .section-partner .section-content.appear {
  opacity: 1;
  transform: translateY(0);
}

.section-partner .section-gallery {
  max-width: 1880px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}
.section-partner .section-gallery .gallery {
  position: relative;
  --grid-gap: 20px;
}
.section-partner .section-gallery .picture-wrapper {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@media only screen and (max-width: 734px) {
  .section-partner .section-gallery .picture-wrapper {
    margin-top: unset;
  }
}
.section-partner .section-gallery .gallery-content {
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
}
@media only screen and (max-width: 734px) {
  .section-partner .section-gallery .gallery-content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.section-partner .section-gallery .gallery-content:after, .section-partner .section-gallery .gallery-content:before {
  content: "";
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 220px;
  height: 100%;
  background: linear-gradient(90deg, var(--fds-light-white), rgba(250, 250, 250, 0));
}

@media screen and (max-width: 1880px) {
  .section-partner .section-gallery .gallery-content:after,
  .section-partner .section-gallery .gallery-content:before:before {
    left: calc(50% - 940px);
  }
}
.section-partner .section-gallery .gallery-content:after:after {
  left: auto;
  right: 0;
  background: linear-gradient(270deg, var(--fds-light-white), rgba(250, 250, 250, 0));
}

@media screen and (max-width: 1880px) {
  .section-partner .section-gallery .gallery-content:after {
    right: calc(50% - 940px);
  }
}
.section-partner .section-gallery .item-container {
  max-width: 1332px;
  margin: 0 auto;
  position: relative;
  list-style: none;
  height: 480px;
  padding-bottom: var(--grid-gap);
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-gallery .item-container {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
    height: 380px;
  }
}
@media only screen and (max-width: 1068px) and (min-width: 1441px) {
  .section-partner .section-gallery .item-container {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .section-gallery .item-container {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 1068px) and (max-width: 734px) {
  .section-partner .section-gallery .item-container {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
@media only screen and (max-width: 1068px) and (max-width: 320px) {
  .section-partner .section-gallery .item-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-gallery .item-container {
    height: 410px;
  }
}

html.text-zoom .section-partner .section-gallery .item-container {
  height: 900px;
}
@media only screen and (max-width: 1068px) {
  html.text-zoom .section-partner .section-gallery .item-container {
    height: 750px;
  }
}

.section-partner .section-gallery .gallery-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 380px;
  padding-right: var(--grid-gap);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: normal;
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-gallery .gallery-item {
    width: 329px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-gallery .gallery-item {
    width: 280px;
  }
}

html.text-zoom .section-partner .section-gallery .gallery-item {
  height: 900px;
}
@media only screen and (max-width: 1068px) {
  html.text-zoom .section-partner .section-gallery .gallery-item {
    height: 750px;
  }
}

.section-partner .section-gallery .gallery-item.engaged .section-quote:last-child.engaged .section-quote:last-child {
  animation-name: siri-utterance-last;
}
.section-partner .section-gallery .gallery-item.engaged .section-quote.engaged .section-quote {
  animation-name: siri-utterance;
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.section-partner .section-gallery-3-tiles {
  --slide-min-screen-width: 1260;
}
.section-partner .section-gallery-3-tiles .item-container {
  max-width: 1180px;
}
@media screen and (min-width: 1260px) {
  .section-partner .section-gallery-3-tiles .section-paddlenav {
    display: none;
  }
}

.section-partner .section-gallery-long .gallery-item {
  min-height: 520px;
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-gallery-long .gallery-item {
    min-height: 470px;
    height: 470px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-gallery-long .gallery-item {
    min-height: 470px;
    height: 470px;
  }
}

html.text-zoom .section-partner .section-gallery-long .gallery-item {
  height: 840px;
}
@media only screen and (max-width: 1068px) {
  html.text-zoom .section-partner .section-gallery-long .gallery-item {
    height: 820px;
  }
}

.section-partner .section-gallery-long .section-item-container {
  height: 520px;
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-gallery-long .section-item-container {
    height: 480px;
  }
}

html.text-zoom .section-partner .section-gallery-long .section-item-container {
  height: 840px;
}
@media only screen and (max-width: 1068px) {
  html.text-zoom .section-partner .section-gallery-long .section-item-container {
    height: 820px;
  }
}

.section-partner .section-item-container {
  margin-top: 25px;
}
.section-partner .section-item-container .tile {
  width: 100%;
  text-align: center;
}
.section-partner .section-item-container .tile-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: auto;
  padding: 34px 7.3% 0;
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-item-container .tile-content {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-item-container .tile-content {
    padding-top: 30px;
  }
}

.section-partner .section-copy {
  margin-top: 7px;
}
.section-partner .section-cta {
  display: block;
}
.section-partner .section-quotes-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 352px;
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-quotes-wrapper {
    height: 272px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-quotes-wrapper {
    height: 285px;
  }
}

html.text-zoom .section-partner .section-quotes-wrapper {
  height: 680px;
}
@media only screen and (max-width: 1068px) {
  html.text-zoom .section-partner .section-quotes-wrapper {
    height: 580px;
  }
}
@media only screen and (max-width: 734px) {
  html.text-zoom .section-partner .section-quotes-wrapper {
    height: 460px;
  }
}

.section-partner .section-quote {
  position: absolute;
  opacity: 0;
  margin: 0;
  transform: translate(-50%);
  background: #ffa54d;
  background: linear-gradient(90deg, #ffa54d, #f07bc5 50%, #43aeff);
  -webkit-text-fill-color: transparent;
}

html.ie .section-partner .section-quote {
  background: none;
  color: var(--fds-fill-rev);
}

html.aow .section-partner .section-quote,
html.enhance-base-xp .section-partner .section-quote,
html.no-js .section-partner .section-quote,
html.reduced-motion .section-partner .section-quote {
  opacity: 0;
}

html.aow .section-partner .section-quote:first-child,
html.enhance-base-xp .section-partner .section-quote:first-child,
html.no-js .section-partner .section-quote:first-child,
html.reduced-motion .section-partner .section-quote:first-child {
  opacity: 1;
  transform: translate(0);
}

html.no-enhance-base-xp .section-partner .section-quote {
  opacity: 0;
}
html.no-enhance-base-xp .section-partner .section-quote:first-child {
  opacity: 1;
  transform: translate(0);
}

html.text-zoom .section-partner .section-quote {
  font-size: 30px;
}
html.text-zoom .section-partner .section-quote:first-child {
  animation-delay: 1s;
}
html.text-zoom .section-partner .section-quote:nth-child(2) {
  animation-delay: 3s;
}
html.text-zoom .section-partner .section-quote:last-child {
  animation-delay: 5s;
}

@keyframes siri-utterance {
  0%, 75% {
    opacity: 0;
    transform: translateY(50%);
  }
  5%, 20%, 80%, 95% {
    opacity: 1;
    transform: translateY(0);
  }
  25%, to {
    opacity: 0;
    transform: translateY(-50%);
  }
}
@keyframes siri-utterance-last {
  0%, 75% {
    opacity: 0;
    transform: translateY(50%);
  }
  5%, 20%, 80%, to {
    opacity: 1;
    transform: translateY(0);
  }
  25% {
    opacity: 0;
    transform: translateY(-50%);
  }
}
.section-partner .section-copy,
.section-partner .section-device {
  color: rgb(110, 110, 115);
}
.section-partner .product-wrapper {
  position: absolute;
  bottom: 36px;
  width: 70%;
}
@media only screen and (max-width: 1068px) {
  .section-partner .product-wrapper {
    width: 90%;
  }
}

html.text-zoom .section-partner .product-wrapper {
  position: relative;
  left: 0;
  transform: none;
}

.section-partner .section-paddlenav {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  margin-top: 15px;
}
@media only screen and (min-width: 1441px) {
  .section-partner .section-paddlenav {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-paddlenav {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-paddlenav {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
@media only screen and (max-width: 320px) {
  .section-partner .section-paddlenav {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-paddlenav {
    max-width: 335px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-paddlenav {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-paddlenav {
    margin-top: -10px;
  }
}
.section-partner .section-paddlenav ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  width: 94px;
}
.section-partner .section-paddlenav button {
  position: relative;
  width: 44px;
  height: 44px;
  margin-bottom: 5px;
}
.section-partner .section-paddlenav button .svg-icon {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  fill: var(--fds-fill-secondary-rev);
  transition: opacity 0.15s;
}

.section-partner .section-paddlenav button .svg-icon.icon-hover,
.section-partner .section-paddlenav button:hover .svg-icon {
  opacity: 0;
}

.section-partner .section-paddlenav button:hover .svg-icon.icon-hover {
  opacity: 1;
}

.section-partner .section-paddlenav button:disabled .svg-icon,
.section-partner .section-paddlenav button:disabled:hover .svg-icon {
  fill: rgb(134, 134, 139);
  opacity: 1;
}

.section-partner .section-paddlenav button:disabled .svg-icon.icon-hover,
.section-partner .section-paddlenav button:disabled:hover .svg-icon.icon-hover {
  opacity: 0;
}

.section-partner .overview-easy-to-set-up {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 380px;
  --p-height: 345px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-easy-to-set-up {
    --p-width: 329px;
    --p-height: 294px;
  }
}

.section-partner .overview-smart-control {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 380px;
  --p-height: 290px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-smart-control {
    --p-width: 302px;
    --p-height: 233px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .section-partner .overview-smart-control {
    --p-width: 280px;
    --p-height: 221px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .overview-smart-control {
    margin-top: 11px;
  }
}

.section-partner .overview-seemlessly-connected {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 380px;
  --p-height: 292px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-seemlessly-connected {
    --p-width: 301px;
    --p-height: 232px;
  }
}
@media only screen and (max-width: 734px) and (max-width: 734px) {
  .section-partner .overview-seemlessly-connected {
    --p-width: 280px;
    --p-height: 218px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .overview-seemlessly-connected {
    margin-top: 1px;
  }
}

.section-partner .overview-security-and-privacy {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 380px;
  --p-height: 284px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-security-and-privacy {
    --p-width: 329px;
    --p-height: 261px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-partner .overview-security-and-privacy {
    margin-bottom: -9px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .overview-security-and-privacy {
    margin-bottom: 0px;
    transform: translateY(14px);
  }
}

.section-partner .overview-lighting {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 383px;
  --p-height: 342px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-lighting {
    --p-width: 329px;
    --p-height: 312px;
  }
}

.section-partner .overview-security {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 383px;
  --p-height: 342px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-security {
    --p-width: 329px;
    --p-height: 312px;
  }
}

.section-partner .overview-comfort {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 383px;
  --p-height: 342px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-comfort {
    --p-width: 329px;
    --p-height: 312px;
  }
}

.section-partner .overview-entry {
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 383px;
  --p-height: 342px;
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .section-partner .overview-entry {
    --p-width: 329px;
    --p-height: 312px;
  }
}

.section-partner #entry .product-wrapper {
  bottom: 30px;
}
.section-partner #entry .product-wrapper.product-wrapper-alt {
  bottom: 36px;
}
.section-partner #comfort .product-wrapper.morelines {
  width: 90%;
}
@media only screen and (max-width: 1068px) {
  .section-partner #comfort .product-wrapper.morelines {
    bottom: 30px;
    width: 55%;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner #comfort .product-wrapper.morelines {
    width: 65%;
  }
}
.section-partner #comfort .product-wrapper.product-wrapper-alt.morelines {
  bottom: 22px;
}

@media only screen and (max-width: 1068px) {
  .section-partner .gallery-item--connect .section-cta, .section-partner .gallery-item--control .section-cta {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .gallery-item--connect .section-cta, .section-partner .gallery-item--control .section-cta {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .gallery-item--connect .section-cta {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .gallery-item--connect .picture-wrapper {
    margin-top: auto;
  }
}

html.aow .section-partner .section-content,
html.reduced-motion .section-partner .section-content {
  opacity: 1;
  transform: none;
}

.section-partner {
  padding-top: var(--global-section-padding);
  padding-bottom: var(--global-section-padding);
}
@media only screen and (max-width: 1068px) {
  .section-partner {
    padding-bottom: 42px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner {
    padding-bottom: 37px;
  }
}
.section-partner .section-partner-header a,
.section-partner .tile-wrapper a {
  display: inline-block;
}
.section-partner .section-content {
  width: 100%;
}
@media only screen and (max-width: 734px) {
  .section-partner .section-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.section-partner .section-partner-header {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  text-align: center;
  max-width: 980px;
  margin-bottom: 51px;
}
@media only screen and (min-width: 1441px) {
  .section-partner .section-partner-header {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-partner-header {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-partner-header {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
@media only screen and (max-width: 320px) {
  .section-partner .section-partner-header {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-partner-header {
    margin-bottom: 36px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-partner-header {
    margin-bottom: 20px;
  }
}
.section-partner .section-partner-header .section-intro-copy {
  font-weight: 400;
}
.section-partner .section-partner-header-headline {
  margin-bottom: 3px;
}
@media only screen and (max-width: 1068px) {
  .section-partner .section-partner-header-headline {
    margin-bottom: 3px;
  }
}
@media only screen and (max-width: 734px) {
  .section-partner .section-partner-header-headline {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1680px) {
  .section-partner .section-gallery {
    max-width: 1627px !important;
  }
  .section-partner .section-gallery .item-container {
    max-width: 100% !important;
    transform: translateZ(0px) !important;
  }
  .section-partner .section-gallery .section-paddlenav {
    display: none;
  }
}
.section-partner .section-gallery .tile-wrapper {
  position: relative;
  z-index: 1;
}
.section-partner .section-gallery .picture-wrapper {
  z-index: 0;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateY(-20px);
}

html.enhance-base-xp .section-big-screen > .row {
  transition: 1000ms;
  opacity: 0;
  transform: translateY(50px);
}
html.enhance-base-xp .section-big-screen > .row.appear {
  opacity: 1;
  transform: translateY(0);
}

.section-big-screen .section-partner-header {
  text-align: center;
  width: 100%;
  margin-bottom: -22px;
}
@media only screen and (max-width: 1068px) {
  .section-big-screen .section-partner-header {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 734px) {
  .section-big-screen .section-partner-header {
    margin-bottom: 5px;
  }
}
.section-big-screen .section-partner-header .section-content {
  padding: 0;
}
.section-big-screen .section-partner-header-headline {
  margin-bottom: 22px;
}
@media only screen and (max-width: 1068px) {
  .section-big-screen .section-partner-header-headline {
    margin-bottom: 14px;
  }
}
@media only screen and (max-width: 734px) {
  .section-big-screen .section-partner-header-headline {
    margin-bottom: 0px;
  }
}

@keyframes inline-media-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.links-inline,
.links-stacked {
  margin-left: 0;
  margin-right: 0;
  list-style: none;
}

.links-inline li {
  display: inline;
  margin: 0 0.8em;
}

.links-inline li:first-child {
  margin-left: 0;
}

.links-inline li:last-child {
  margin-right: 0;
}

.links-stacked li {
  margin: 0;
  display: block;
}

.links-stacked li + li {
  margin-top: 0.4em;
}

.tile-button {
  background-color: rgba(210, 210, 215, 0.64);
  color: rgba(0, 0, 0, 0.56);
}

.tile-button:hover {
  background-color: rgba(223, 223, 227, 0.6976);
}

.tile-button:active {
  background-color: rgba(193, 193, 198, 0.6544);
}

.tile-button:hover {
  color: rgba(0, 0, 0, 0.64);
}

.tile-button-wrapper {
  width: 44px;
  height: 44px;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

@media only screen and (max-width: 1068px) {
  .tile-button-wrapper {
    bottom: 13px;
    right: 13px;
  }
}
.tile-button-wrapper:focus {
  outline: none;
}

.tile-button-wrapper:focus .tile-button {
  box-shadow: 0 0 0 4px rgba(0, 113, 227, 0.6);
  outline: none;
}

.tile-button-wrapper:focus .tile-button[data-focus-method=mouse]:not(input):not(textarea):not(select),
.tile-button-wrapper:focus .tile-button[data-focus-method=touch]:not(input):not(textarea):not(select),
.tile-button-wrapper:focus[data-focus-method=mouse]:not(input):not(textarea):not(select) .tile-button,
.tile-button-wrapper:focus[data-focus-method=touch]:not(input):not(textarea):not(select) .tile-button {
  box-shadow: none;
}

.tile-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 100ms linear;
}

.tile-icon {
  fill: currentColor;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
}

.tile-icon[viewBox="0 0 40 40"] {
  width: 100%;
  height: 100%;
  position: unset;
  left: unset;
  transform: unset;
}

.tile-rounded {
  border-radius: var(--fds-box-rounded);
}

.tile-shadow {
  box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.08);
}

.ac-gn-segmentbar-visible body,
.ac-ls-visible body {
  overflow-x: hidden;
}

.section-content {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  padding: 20px 0 60px;
}
@media only screen and (min-width: 1441px) {
  .section-content {
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-content {
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .section-content {
    width: 87.5%;
  }
}
@media only screen and (max-width: 320px) {
  .section-content {
    width: 87.5%;
  }
}
.section-content .section-wrapper {
  text-align: center;
}

@media only screen and (min-width: 1069px) {
  .section-wide .section-content {
    max-width: 1380px;
    width: 100%;
  }
}
.background-alt {
  background-color: var(--fds-light-white);
}

.ac-promo .ac-gf-buystrip-info-copy {
  min-width: 224px;
}

.stack-container {
  display: grid;
  grid-template-columns: 100%;
  position: relative;
}

.stack-item {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}

@keyframes ui-activity-indicator-keyframe-u0560dd {
  0% {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}
ui-activity-indicator.standard {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}

ui-activity-indicator.standard ui-spinner-nib {
  position: absolute;
  left: 50%;
  top: 0;
  width: 3px;
  height: 9px;
  border-radius: 1.5px;
  transform-origin: 0 0;
  animation-name: ui-activity-indicator-keyframe-u0560dd;
  animation-direction: reverse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333);
  background-color: var(--fds-fill);
}

ui-activity-indicator.standard ui-spinner-nib:first-of-type {
  animation-delay: -1s;
  transform: translateY(16px) rotate(0deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(2) {
  animation-delay: -0.9166666667s;
  transform: translateY(16px) rotate(30deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(3) {
  animation-delay: -0.8333333333s;
  transform: translateY(16px) rotate(60deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(4) {
  animation-delay: -0.75s;
  transform: translateY(16px) rotate(90deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(5) {
  animation-delay: -0.6666666667s;
  transform: translateY(16px) rotate(120deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(6) {
  animation-delay: -0.5833333333s;
  transform: translateY(16px) rotate(150deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(7) {
  animation-delay: -0.5s;
  transform: translateY(16px) rotate(180deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(8) {
  animation-delay: -0.4166666667s;
  transform: translateY(16px) rotate(210deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(9) {
  animation-delay: -0.3333333333s;
  transform: translateY(16px) rotate(240deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(10) {
  animation-delay: -0.25s;
  transform: translateY(16px) rotate(270deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(11) {
  animation-delay: -0.1666666667s;
  transform: translateY(16px) rotate(300deg) translateY(-16px) translateX(-1.5px);
}

ui-activity-indicator.standard ui-spinner-nib:nth-of-type(12) {
  animation-delay: -0.0833333333s;
  transform: translateY(16px) rotate(330deg) translateY(-16px) translateX(-1.5px);
}

.section-partner {
  --global-viewport-content-responsive: 87.5vw;
}
.section-partner .my-partner {
  position: relative;
  margin-top: 0;
  padding-bottom: 83px;
  --gallery-side-padding: calc(
    50vw - min(1680px, var(--global-viewport-content-responsive)) / 2
  );
  --scroll-bar-width: 0px;
}
@media (max-width: 734px) {
  .section-partner .my-partner {
    padding-bottom: 78px;
  }
}
.section-partner .my-partner .card-set {
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: var(--gallery-column-gap);
  column-gap: var(--gallery-column-gap);
  grid-row-gap: 0px;
  row-gap: 0px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  list-style: none;
}
.section-partner .my-partner .card-set {
  --gallery-card-set-height: 100%;
}
.section-partner .my-partner .card-set .partner-card {
  height: var(--gallery-card-set-height);
}
.section-partner .my-partner .card-set .card-container {
  height: var(--gallery-card-set-height);
}
.section-partner .my-partner .card-set .card-container {
  transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
}
.section-partner .my-partner .card-set .card,
.section-partner .my-partner .card-set .card-container {
  height: 100%;
}
.section-partner .my-partner.no-paddlenav {
  padding-bottom: 0px;
}
.section-partner .my-partner ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: rgba(0, 0, 0, 0);
}
.section-partner .my-partner.gallery-align-center .scroll-container {
  scroll-padding: var(--gallery-side-padding);
}
.section-partner .my-partner.gallery-align-center .item-container {
  padding: 0 var(--gallery-side-padding);
}
.section-partner .my-partner.gallery-align-center .gallery-item {
  scroll-snap-align: center;
}
.section-partner .my-partner.gallery-align-start .scroll-container {
  scroll-padding: var(--gallery-side-padding);
}
.section-partner .my-partner.gallery-align-start .item-container {
  padding: 0 var(--gallery-side-padding);
}
.section-partner .my-partner.gallery-align-start .gallery-item {
  scroll-snap-align: start;
}
.section-partner .my-partner .scroll-container {
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  padding: 10px 0;
  margin: -10px 0;
}
.section-partner .my-partner .scroll-container::-webkit-scrollbar {
  display: none;
}
.section-partner .my-partner .item-container {
  --gallery-column-gap: 45px;
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: var(--gallery-column-gap);
  column-gap: var(--gallery-column-gap);
  grid-row-gap: 0px;
  row-gap: 0px;
  width: -moz-fit-content;
  width: fit-content;
}
@media (min-width: 1441px) {
  .section-partner .my-partner .item-container {
    --gallery-column-gap: 72px;
  }
}
@media (max-width: 1068px) {
  .section-partner .my-partner .item-container {
    --gallery-column-gap: 72px;
  }
}
@media (max-width: 734px) {
  .section-partner .my-partner .item-container {
    --gallery-column-gap: 45px;
  }
}
.section-partner .my-partner.remove-scrollbar-width .item-container {
  padding-left: var(--gallery-side-padding);
  padding-right: calc(var(--gallery-side-padding) - var(--scroll-bar-width));
}
.section-partner .my-partner .spec-rows-8 .item-container {
  grid-template-rows: repeat(16, auto);
}
.section-partner .my-partner .spec-rows-7 .item-container {
  grid-template-rows: repeat(15, auto);
}
.section-partner .my-partner .spec-rows-6 .item-container {
  grid-template-rows: repeat(14, auto);
}
.section-partner .my-partner .spec-rows-5 .item-container {
  grid-template-rows: repeat(13, auto);
}
.section-partner .my-partner .spec-rows-4 .item-container {
  grid-template-rows: repeat(12, auto);
}
.section-partner .my-partner .spec-rows-3 .item-container {
  grid-template-rows: repeat(11, auto);
}
.section-partner .my-partner .spec-rows-2 .item-container {
  grid-template-rows: repeat(10, auto);
}
.section-partner .my-partner .spec-rows-1 .item-container {
  grid-template-rows: repeat(9, auto);
}
.section-partner html[dir=rtl] .my-partner .partner-paddlenav {
  display: flex;
  justify-content: flex-end;
  right: auto;
  left: var(--gallery-side-padding);
}
.section-partner .my-partner .paddlenav {
  position: absolute;
  top: calc(100% - var(--paddlenav-offset));
  right: var(--gallery-side-padding);
  height: 100%;
  visibility: inherit;
}
@media (max-width: 1068px) {
  .section-partner .my-partner .paddlenav {
    --paddlenav-offset: 59px;
  }
}
@media (max-width: 734px) {
  .section-partner .my-partner .paddlenav {
    --paddlenav-offset: 63px;
  }
}
.section-partner .my-partner .paddlenav.hide {
  visibility: hidden;
}
.section-partner .my-partner .paddlenav .sticky-element {
  position: relative;
  width: 0px;
  height: var(--sticky-height);
  z-index: 2;
}
.section-partner .my-partner .paddlenav .sticky-element .left-item {
  position: absolute;
  right: calc(var(--paddlenav-arrows-offset) + var(--paddlenav-right-offset));
  bottom: calc(var(--paddlenav-arrow-height) * 0.5);
}
.section-partner html[dir=rtl] .my-partner .paddlenav .sticky-element .left-item {
  right: auto;
  left: calc(var(--paddlenav-arrows-offset) + var(--paddlenav-right-offset));
}
.section-partner .my-partner .paddlenav .sticky-element .right-item {
  position: absolute;
  right: var(--paddlenav-right-offset);
  bottom: calc(var(--paddlenav-arrow-height) * 0.5);
}
.section-partner html[dir=rtl] .my-partner .paddlenav .sticky-element .right-item {
  right: auto;
  left: var(--paddlenav-right-offset);
}
.section-partner html[dir=rtl] .my-partner .paddlenav .sticky-element .scrim {
  left: calc(var(--gallery-side-padding) * -1);
}
.section-partner .my-partner .paddlenav .sticky-element .paddlenav-arrow {
  width: var(--paddlenav-arrow-height);
  height: var(--paddlenav-arrow-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-partner html[dir=rtl] .my-partner .paddlenav .sticky-element .paddlenav-arrow {
  transform: rotate(180deg);
}
.section-partner .my-partner .paddlenav .sticky-element .paddlenav-arrow svg {
  pointer-events: none;
  width: 100%;
}
.section-partner html.touch .my-partner .paddlenav .sticky-element .paddlenav-arrow:disabled {
  opacity: 0.42;
}
.section-partner html.touch .my-partner .paddlenav .sticky-element .paddlenav-arrow:hover {
  --ze-paddlenav-arrow-color-hover: var(--ze-paddlenav-arrow-color);
  --ze-paddlenav-background-hover: var(--ze-paddlenav-background);
}
.section-partner .my-partner .paddlenav .sticky-element .paddlenav-arrow::after {
  font-size: var(--large-title);
  padding-inline-start: 0;
}
.section-partner .my-partner .paddlenav .sticky-element .paddlenav-arrow .scrim {
  display: none;
}

.staggered-start .my-partner .scroll-container {
  overflow: hidden;
}

.staggered-start .my-partner .paddlenav .paddlenav-arrow {
  pointer-events: none;
  opacity: 0.42;
}

.theme-dark .staggered-start .my-partner .paddlenav {
  opacity: 0.36;
}

.staggered-end .my-partner .scroll-container {
  overflow: scroll;
}

.section-partner .my-partner .item-container {
  --gallery-column-gap: 20px;
}

[data-core-gallery] {
  min-height: 100px;
  overflow: hidden;
  position: relative;
}

[data-core-gallery-scroller] {
  display: flex;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

[data-core-gallery-fade=true] [data-core-gallery-scroller] {
  position: relative;
}

[data-core-gallery-fade=true] [data-core-gallery-scroller] > * {
  transition: opacity 0.4s ease;
}

[data-core-gallery-fade=true] [data-core-gallery-scroller] > :not(:first-child) {
  inset-inline-start: -50%;
  position: relative;
}

[data-core-gallery-fade=true] [data-core-gallery-scroller] > :first-child {
  opacity: 0;
  z-index: 1;
}

[data-core-gallery-item] {
  cursor: pointer;
  overflow: hidden;
  width: 100%;
}

.rf-cards-grid {
  display: flex;
  justify-content: center;
}
.rf-cards-grid .rf-cards-scroller-itemview {
  transform: none;
}

.rf-cards-scroller {
  position: relative;
}

.rf-cards-scroller-crop {
  height: 29.4117647059rem;
  overflow: hidden;
  padding-bottom: 26px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rf-cards-scroller-crop {
    height: 450px;
  }
}

.rs-halfsize-cardsshelf .rf-cards-scroller-crop {
  height: 14.1176470588rem;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rs-halfsize-cardsshelf .rf-cards-scroller-crop {
    height: 215px;
  }
}

.rf-cards-scroller-platter {
  display: inline-flex;
  padding-bottom: 40px;
  padding-top: 10px;
  vertical-align: top;
  width: 100%;
}
.rf-cards-scroller-platter > div:first-child .rf-cards-scroller-itemview {
  margin-inline-start: 0;
}
.rf-cards-scroller-platter > div:last-child .rf-cards-scroller-itemview {
  padding-inline-end: 22px;
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .rf-cards-scroller-platter > div:last-child .rf-cards-scroller-itemview {
    padding-inline-end: calc(-268.46154px + 28.36538vw);
  }
}
@media only screen and (min-width: 1440px) {
  .rf-cards-scroller-platter > div:last-child .rf-cards-scroller-itemview {
    padding-inline-end: 140px;
  }
}

.rf-cards-scroller-itemview {
  display: flex;
  height: 29.4117647059rem;
  margin-inline-end: 20px;
  transform: translateX(calc(var(--r-sk-logical-factor, 1) * 22px));
}

.rs-halfsize-cardsshelf .rf-cards-scroller-itemview {
  height: 14.1176470588rem;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .rf-cards-scroller-itemview .rf-cards-scroller-itemview {
    transform: translateX(calc(var(--r-sk-logical-factor, 1) * (-268.46154px + 28.36538vw)));
  }
}
@media only screen and (min-width: 1440px) {
  .rf-cards-scroller-itemview .rf-cards-scroller-itemview {
    transform: translateX(calc(var(--r-sk-logical-factor, 1) * 140px));
  }
}

.rf-cards-scroller-2cards {
  flex-direction: column;
  justify-content: space-between;
  width: 28.2352941176rem;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rf-cards-scroller-2cards {
    width: 18.1764705882rem;
  }
}
@media only screen and (max-device-width: 320px) {
  .rf-cards-scroller-2cards {
    width: 15.2941176471rem;
  }
}

.rf-cards-scroller-2cards.rf-cards-scroller-17sizecards {
  width: 18.4117647059rem;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rf-cards-scroller-2cards.rf-cards-scroller-17sizecards {
    width: 18.1764705882rem;
  }
}
@media only screen and (max-device-width: 320px) {
  .rf-cards-scroller-2cards.rf-cards-scroller-17sizecards {
    width: 15.2941176471rem;
  }
}

.rf-cards-scroller-3cards {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 480px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rf-cards-scroller-3cards {
    height: 450px;
    width: 309px;
  }
}
@media only screen and (max-device-width: 320px) {
  .rf-cards-scroller-3cards {
    width: 260px;
  }
}

.rf-cards-scroller-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.rf-cards-scroller-content {
  overscroll-behavior-x: contain;
}

.rf-cards-scroller-item {
  display: flex;
  height: 500px;
  margin: 0 10px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rf-cards-scroller-item {
    height: 100%;
  }
}
.rf-cards-scroller-item:first-child {
  margin-inline-start: 0;
  padding-inline-start: max(22px, env(safe-area-inset-left));
}
.rf-cards-scroller-item:last-child {
  padding-inline-end: max(22px, env(safe-area-inset-right));
}

.rs-productnav-cardsshelf .rf-cards-scroller-itemview {
  height: 148px;
  margin-inline-end: 10px;
  margin: 0 5px;
}
.rs-productnav-cardsshelf .rf-cards-scroller-itemview:first-child {
  margin-inline-start: 0;
}

@media (max-width: 1023px) and (max-device-width: 736px) {
  .rs-productnav-cardsshelf .rf-cards-scroller-item {
    height: 120px;
  }
}
.rs-halfsize-cardsshelf .rf-cards-scroller-item {
  height: 240px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .rs-halfsize-cardsshelf .rf-cards-scroller-item {
    height: 215px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .rf-cards-scroller-item {
    transform: translateX(calc(-268.46154px + 28.36538vw));
  }
  .rf-cards-scroller-item:first-child {
    padding-inline-start: 0;
  }
}
.rs-cardsshelf-nolink .as-util-relatedlink {
  cursor: default !important;
}

.pn-card-content {
  background-color: var(--fds-fill);
  border-radius: var(--fds-box-rounded);
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.0784313725);
  display: flex;
  flex-direction: column;
  height: 29.4117647059rem;
  overflow: hidden;
  position: relative;
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  white-space: normal;
  width: 28.2352941176rem;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-content {
    height: 450px;
    width: 309px;
  }
}
@media only screen and (max-device-width: 320px) {
  .pn-card-content {
    width: 260px;
  }
}
.pn-card-content.pn-card-content-withfullimg {
  flex-direction: row;
  justify-content: center;
}

.pn-card-content-info {
  padding: 1.7647058824rem;
  width: 20rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-content-info {
    padding: 28px;
    width: 253px;
  }
}
@media only screen and (max-device-width: 320px) {
  .pn-card-content-info {
    width: 204px;
  }
}

.pn-card-17 .pn-card-content {
  height: 14.1176470588rem;
  width: 18.4117647059rem;
}
@media (max-width: 1023px) {
  .pn-card-17 .pn-card-content {
    height: 215px;
    width: 309px;
  }
}
@media only screen and (max-device-width: 736px) {
  .pn-card-17 .pn-card-content {
    width: 260px;
  }
}
.pn-card-17 .pn-card-content-info {
  padding-bottom: 0;
  width: 14.8823529412rem;
}
@media only screen and (max-device-width: 320px) {
  .pn-card-17 .pn-card-content-info {
    width: 204px;
  }
}

.pn-card-content-picture {
  height: 118px;
  padding-top: 25px;
}
@media only screen and (max-width: 1068px) {
  .pn-card-content-picture {
    padding-top: 5px;
  }
}
@media only screen and (max-width: 734px) {
  .pn-card-content-picture {
    padding-top: 10px;
  }
}
@media only screen and (max-width: 374px) {
  .pn-card-content-picture {
    padding-top: 20px;
  }
}
.pn-card-content-picture .partner-picture {
  width: 100%;
}
.pn-card-content-picture .partner-picture img {
  width: 100%;
}

.pn-card-content-icon {
  height: 48px;
  padding-bottom: 5px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-content-icon {
    padding-bottom: 0;
  }
}
.pn-card-content-icon .as-svgicon {
  height: 56px;
  position: absolute;
  top: 22px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-content-icon .as-svgicon {
    top: 18px;
  }
}

.pn-card-content-header {
  color: var(--text-fill-secondary-rev);
  font-size: var(--title-1);
  font-weight: 600;
  letter-spacing: 0.007em;
  line-height: 1.1428571429;
  margin-top: 0;
  padding-top: 8px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-content-header {
    font-size: var(--title-3);
    font-weight: 600;
    letter-spacing: 0.011em;
    line-height: 1.1904761905;
  }
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-content-header {
    padding-top: 10px;
  }
}

.pn-card-17 .pn-card-content-header {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.009em;
  line-height: 1.1666666667;
  padding-top: 8px;
}
@media (max-width: 1023px) and (max-device-width: 736px) {
  .pn-card-17 .pn-card-content-header {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.011em;
    line-height: 1.1904761905;
    padding-top: 10px;
  }
}

.pn-card-content-header-eyebrow + .pn-card-content-header,
.pn-card-content-icon + .pn-card-content-header,
.pn-card-content-savingsbadge + .pn-card-content-header {
  padding-top: 0;
}

.pn-card-content-header .pn-card-content-headerlink {
  color: var(--text-fill-secondary-rev);
  display: inline-block;
  text-decoration: none;
}
.pn-card-content-header .pn-card-content-headerlink span {
  pointer-events: none;
}

.rf-ccard-darkbg .pn-card-content-header .pn-card-content-headerlink {
  color: var(--text-fill);
}

@media (min-device-width: 768px) {
  .dd-expand-picture-wrapper .dd-expand-right-large-4 {
    margin-inline-end: -4% !important;
  }
}

@media (min-device-width: 768px) {
  .dd-expand-right-large-4 .dd-expand-right-large-4 {
    margin-inline-end: -4% !important;
  }
}

a[role=button] {
  cursor: pointer;
}

.dd-glyph-elevated {
  height: 56px;
  width: auto;
}

.pn-card-content-icon .dd-glyph-svg {
  position: absolute;
  top: 22px;
}
@media (max-width: 734px) and (max-device-width: 736px) {
  .pn-card-content-icon .dd-glyph-svg {
    top: 18px;
  }
}

.dd-color-white span.dd-highlight,
.dd-color-white svg.dd-glyph-svg {
  color: var(--text-fill);
  fill: var(--fds-fill);
}

.dd-color-razzmatazz span.dd-highlight,
.dd-color-razzmatazz svg.dd-glyph-svg {
  color: rgb(255, 0, 83);
  fill: rgb(255, 0, 83);
}

.dd-color-red-orange span.dd-highlight,
.dd-color-red-orange svg.dd-glyph-svg {
  color: rgb(255, 51, 51);
  fill: rgb(255, 51, 51);
}

.dd-color-persimmon span.dd-highlight,
.dd-color-persimmon svg.dd-glyph-svg {
  color: rgb(234, 84, 24);
  fill: rgb(234, 84, 24);
}

.dd-color-saffron span.dd-highlight,
.dd-color-saffron svg.dd-glyph-svg {
  color: rgb(254, 190, 48);
  fill: rgb(254, 190, 48);
}

.dd-color-apple span.dd-highlight,
.dd-color-apple svg.dd-glyph-svg {
  color: rgb(104, 204, 69);
  fill: rgb(104, 204, 69);
}

.dd-color-forest-green span.dd-highlight,
.dd-color-forest-green svg.dd-glyph-svg {
  color: rgb(45, 155, 0);
  fill: rgb(45, 155, 0);
}

.dd-color-steel-blue span.dd-highlight,
.dd-color-steel-blue svg.dd-glyph-svg {
  color: rgb(63, 110, 184);
  fill: rgb(63, 110, 184);
}

.dd-color-dodger-blue span.dd-highlight,
.dd-color-dodger-blue svg.dd-glyph-svg {
  color: rgb(0, 122, 255);
  fill: rgb(0, 122, 255);
}

.dd-color-blue-violet span.dd-highlight,
.dd-color-blue-violet svg.dd-glyph-svg {
  color: rgb(172, 57, 255);
  fill: rgb(172, 57, 255);
}

.dd-color-fire-engine-red span.dd-highlight,
.dd-color-fire-engine-red svg.dd-glyph-svg {
  color: rgb(208, 17, 43);
  fill: rgb(208, 17, 43);
}

.dd-color-deep-cerise span.dd-highlight,
.dd-color-deep-cerise svg.dd-glyph-svg {
  color: rgb(236, 52, 140);
  fill: rgb(236, 52, 140);
}

.dd-color-teal span.dd-highlight,
.dd-color-teal svg.dd-glyph-svg {
  color: rgb(8, 130, 124);
  fill: rgb(8, 130, 124);
}

.dd-color-fire-dodger-blue span.dd-highlight,
.dd-color-fire-dodger-blue svg.dd-glyph-svg {
  color: rgb(2, 122, 255);
  fill: rgb(2, 122, 255);
}

.dd-color-dodger-blue-92-60 span.dd-highlight,
.dd-color-dodger-blue-92-60 svg.dd-glyph-svg {
  color: rgb(59, 130, 247);
  fill: rgb(59, 130, 247);
}

.dd-color-customize-mac span.dd-highlight {
  background-image: linear-gradient(to right, rgb(248, 171, 94) 0%, rgb(243, 105, 97) 20%, rgb(161, 118, 200) 40%, rgb(117, 155, 235) 60%, rgb(101, 190, 179) 80%, rgb(112, 219, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dd-color-apple-card span.dd-highlight {
  background-image: linear-gradient(to right, rgb(240, 104, 68) 0%, rgb(238, 76, 84) 25%, rgb(212, 94, 149) 50%, rgb(156, 108, 166) 75%, rgb(101, 131, 193) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}