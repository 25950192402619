@charset "UTF-8";
:root {
  --fds-background-color: var(--zem-background-color);
  --fds-fill: rgb(255, 255, 255);
  --fds-fill-alpha-05: rgba(255, 255, 255, 0.05);
  --fds-fill-alpha-10: rgba(255, 255, 255, 0.1);
  --fds-fill-alpha-15: rgba(255, 255, 255, 0.15);
  --fds-fill-alpha-20: rgba(255, 255, 255, 0.2);
  --fds-fill-alpha-30: rgba(255, 255, 255, 0.3);
  --fds-fill-alpha-40: rgba(255, 255, 255, 0.4);
  --fds-fill-alpha-50: rgba(255, 255, 255, 0.5);
  --fds-fill-alpha-60: rgba(255, 255, 255, 0.6);
  --fds-fill-alpha-80: rgba(255, 255, 255, 0.8);
  --fds-fill-secondary: rgb(245 245 247);
  --fds-fill-tertiary: rgb(237, 237, 242);
  --fds-fill-alt: var(--fds-fill-secondary);
  --fds-fill-rev: rgb(0, 0, 0);
  --fds-fill-alpha-05-rev: rgba(0, 0, 0, 0.05);
  --fds-fill-alpha-10-rev: rgba(0, 0, 0, 0.1);
  --fds-fill-alpha-15-rev: rgba(0, 0, 0, 0.15);
  --fds-fill-alpha-20-rev: rgba(0, 0, 0, 0.2);
  --fds-fill-alpha-30-rev: rgba(0, 0, 0, 0.3);
  --fds-fill-alpha-40-rev: rgba(0, 0, 0, 0.4);
  --fds-fill-alpha-50-rev: rgba(0, 0, 0, 0.5);
  --fds-fill-alpha-60-rev: rgba(0, 0, 0, 0.6);
  --fds-fill-alpha-80-rev: rgba(0, 0, 0, 0.8);
  --fds-fill-secondary-rev: rgb(29, 29, 31);
  --fds-fill-tertiary-rev: rgb(16, 16, 16);
  --fds-fill-alt-rev: var(--fds-fill-secondary-rev);
  --fds-glyph: rgb(0, 0, 0);
  --fds-glyph-gray: rgb(29, 29, 31);
  --fds-glyph-gray-alpha: rgba(0, 0, 0, 0.88);
  --fds-glyph-gray-secondary: rgb(110, 110, 115);
  --fds-glyph-gray-secondary-alpha: rgba(0, 0, 0, 0.56);
  --fds-glyph-gray-secondary-alt: rgb(66, 66, 69);
  --fds-glyph-gray-secondary-alt-alpha: rgba(0, 0, 0, 0.72);
  --fds-glyph-gray-tertiary: rgb(134, 134, 139);
  --fds-glyph-gray-tertiary-alpha: rgba(0, 0, 0, 0.48);
  --fds-glyph-blue: rgb(0, 102, 204);
  --fds-glyph-orange: rgb(182, 68, 0);
  --fds-glyph-green: rgb(0, 128, 9);
  --fds-glyph-red: rgb(227, 0, 0);
  --fds-black: rgb(0, 0, 0);
  --fds-black-alpha-05: rgba(0, 0, 0, 0.05);
  --fds-black-alpha-10: rgba(0, 0, 0, 0.1);
  --fds-black-alpha-15: rgba(0, 0, 0, 0.15);
  --fds-black-alpha-20: rgba(0, 0, 0, 0.2);
  --fds-black-alpha-30: rgba(0, 0, 0, 0.3);
  --fds-black-alpha-40: rgba(0, 0, 0, 0.4);
  --fds-black-alpha-50: rgba(0, 0, 0, 0.5);
  --fds-black-alpha-60: rgba(0, 0, 0, 0.6);
  --fds-black-alpha-80: rgba(0, 0, 0, 0.8);
  --fds-white: rgb(255, 255, 255);
  --fds-white-alpha-05: rgba(255, 255, 255, 0.05);
  --fds-white-alpha-10: rgba(255, 255, 255, 0.1);
  --fds-white-alpha-15: rgba(255, 255, 255, 0.15);
  --fds-white-alpha-20: rgba(255, 255, 255, 0.2);
  --fds-white-alpha-30: rgba(255, 255, 255, 0.3);
  --fds-white-alpha-40: rgba(255, 255, 255, 0.4);
  --fds-white-alpha-50: rgba(255, 255, 255, 0.5);
  --fds-white-alpha-60: rgba(255, 255, 255, 0.6);
  --fds-white-alpha-80: rgba(255, 255, 255, 0.8);
  --fds-fill-gray: rgb(29, 29, 31);
  --fds-fill-gray-alpha: rgba(0, 0, 0, 0.88);
  --fds-fill-gray-secondary: rgb(134, 134, 139);
  --fds-fill-gray-secondary-alpha: rgba(0, 0, 0, 0.48);
  --fds-fill-gray-tertiary: rgb(210, 210, 215);
  --fds-fill-gray-tertiary-alpha: rgba(0, 0, 0, 0.16);
  --fds-fill-gray-quaternary: rgb(232, 232, 237);
  --fds-fill-gray-quaternary-alpha: rgba(0, 0, 0, 0.08);
  --fds-fill-blue: rgb(0, 113, 227);
  --fds-fill-orange: rgb(245, 99, 0);
  --fds-fill-orange-secondary: rgb(255, 249, 244);
  --fds-fill-green: rgb(3, 161, 14);
  --fds-fill-green-secondary: rgb(245, 255, 246);
  --fds-fill-red: rgb(227, 0, 0);
  --fds-fill-red-secondary: rgb(255, 242, 244);
  --fds-fill-yellow: rgb(255, 224, 69);
  --fds-fill-yellow-secondary: rgb(255, 254, 242);
  --fds-productred: rgb(175, 30, 45);
  --fds-enviro-green: rgb(0, 217, 89);
  --fds-enviro-neutral: rgb(232, 232, 237);
  --fds-box-shadow: 2px 4px 16px rgb(0 0 0/29%);
  --fds-box-rounded: 1.75rem;
  --fds-button-round: 10px;
  --fds-button-round-small: 0.5rem;
  --fds-animation-enter-exit-in: cubic-bezier(0.14, 1, 0.34, 1);
  --fds-animation-enter-exit-out: cubic-bezier(0.45, 0.1, 0.2, 1);
  --fds-animation-expand-collapse-in: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-expand-collapse-out: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-cubic-bezier-in: cubic-bezier(0, 0, 5, 1);
  --fds-animation-fade-out: cubic-bezier(0, 0, 1, 1);
  --fds-duration-extra-extra-short-in: 100ms;
  --fds-duration-extra-extra-short-out: 100ms;
  --fds-duration-extra-long-in: 1000ms;
  --fds-duration-extra-long-out: 1000ms;
  --fds-duration-extra-short-in: 200ms;
  --fds-duration-extra-short-out: 150ms;
  --fds-duration-long-in: 500ms;
  --fds-duration-long-out: 350ms;
  --fds-duration-medium-in: 400ms;
  --fds-duration-medium-out: 350ms;
  --fds-duration-none: 0ms;
  --fds-duration-short-in: 250ms;
  --fds-duration-short-out: 250ms;
  --global-card-background-color: var(--fds-background-color);
  --global-card-corner-radius: var(--fds-box-rounded);
  --global-card-padding: 32px;
  --global-section-padding: 150px;
  --global-section-background-color: var(--fds-fill);
  --global-section-background-color-rev: var(--fds-fill-rev);
  --global-section-background-color-alt: var(--fds-fill-alt);
  --global-section-background-color-alt-rev: var(--fds-fill-alt-rev);
  --global-scrim-background-color: rgb(250, 250, 252);
  --global-scrim-background-color-dark: rgb(22, 22, 23);
  --global-scrim-background-color-alt: var(--global-section-background-color);
  --global-scrim-background-color-alt-rev: var(
    --global-section-background-color-rev
  );
  --global-spacing-img-to-headline: 100px;
  --global-staggered-delay: 0.15;
  --global-staggered-opacity-duration: 0.9;
  --global-staggered-translate-y: 30px;
  --global-staggered-translate-y-duration: 0.7;
  --global-viewport-content-responsive: 87.5vw;
  --global-animation-easing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --global-select-column-space: 20px;
  --text-fill: var(--fds-fill);
  --text-fill-secondary: var(--fds-fill-secondary);
  --text-fill-alt: var(--fds-fill-secondary);
  --text-fill-rev: var(--fds-fill-rev);
  --text-fill-secondary-rev: var(--fds-fill-secondary-rev);
  --zem-color: rgb(0, 86, 210);
  --zem-color-surface: var(--fds-fill-secondary);
  --zem-url-color: rgb(0, 102, 204);
  --zem-fill-blue: var(--fds-fill-blue);
  --zem-background-color: var(--fds-fill-secondary);
  --zem-border-color: var(--zem-background-color);
}

@media (max-width: 1068px) {
  :root {
    --global-card-padding: 28px;
    --global-section-padding: 120px;
  }
}
@media (max-width: 734px) {
  :root {
    --global-card-padding: 24px;
    --global-section-padding: 90px;
  }
}
html.js [data-anim-lazy-image],
html.js [data-anim-lazy-image] *,
html.js [data-anim-lazy-image] *::after,
html.js [data-anim-lazy-image] *::before,
html.js [data-anim-lazy-image]::after,
html.js [data-anim-lazy-image]::before {
  background-image: none !important;
  mask-image: none !important;
}

:root {
  --font-family: "system-font", "system-ui", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --xxlarge-title: 3rem;
  --xlarge-title: 2.5rem;
  --large-title: 2.25rem;
  --title-1: 1.75rem;
  --title-2: 1.5rem;
  --title-3: 1.3125rem;
  --title-4: 1.1875rem;
  --headline: 1.0625rem;
  --body: 1.0625rem;
  --callout: 1rem;
  --subhead: 0.9375rem;
  --footnote: 0.8125rem;
  --caption-1: 0.75rem;
  --caption-2: 0.6875rem;
  --violet: #8876fe;
  --indigo: #622aff;
  --grape: #6e21e6;
  --plum: #8d1287;
  --azalea: #e71ee6;
  --magenta: #dd04d1;
  --tomato: #f63e54;
  --guava: #fb4d66;
  --tangelo: #ff3e17;
  --coral: #f95c30;
  --persimmon: #e94b1b;
  --pumpkin: #e2620c;
  --orange: #e66702;
  --verdant: #29d246;
  --bright-green: #00d436;
  --new-green: #13be54;
  --avocado: #73b439;
  --green: #0c9d46;
  --blue-green: #26c2ac;
  --peacock-green: #14b1a1;
  --sea-green: #099b9d;
  --teal-green: #2b9ca1;
  --aqua: #039e8f;
  --teal: #049cb7;
  --pool-blue: #00c2ee;
  --azure: #027fff;
  --sparkle-blue: rgb(0, 122, 255);
  --denim: #0056d2;
  --cobalt: #5451fb;
  --sapphire: #3d44c3;
  --night: #23466c;
  --ultramarine: #0d1387;
}

.tag {
  opacity: 0; /* 初始状态 */
  transition: opacity 1.5s ease; /* 添加过渡效果 */
}

.tag.visible {
  opacity: 1; /* 可见状态 */
}

.section-violet {
  --section-color: var(--violet);
}

.section-indigo {
  --section-color: var(--indigo);
}

.section-grape {
  --section-color: var(--grape);
}

.section-plum {
  --section-color: var(--plum);
}

.section-azalea {
  --section-color: var(--azalea);
}

.section-magenta {
  --section-color: var(--magenta);
}

.section-tomato {
  --section-color: var(--tomato);
}

.section-guava {
  --section-color: var(--guava);
}

.section-tangelo {
  --section-color: var(--tangelo);
}

.section-coral {
  --section-color: var(--coral);
}

.section-persimmon {
  --section-color: var(--persimmon);
}

.section-pumpkin {
  --section-color: var(--pumpkin);
}

.section-orange {
  --section-color: var(--orange);
}

.section-verdant {
  --section-color: var(--verdant);
}

.section-bright-green {
  --section-color: var(--bright-green);
}

.section-new-green {
  --section-color: var(--new-green);
}

.section-avocado {
  --section-color: var(--avocado);
}

.section-green {
  --section-color: var(--green);
}

.section-blue-green {
  --section-color: var(--blue-green);
}

.section-peacock-green {
  --section-color: var(--peacock-green);
}

.section-sea-green {
  --section-color: var(--sea-green);
}

.section-teal-green {
  --section-color: var(--teal-green);
}

.section-aqua {
  --section-color: var(--aqua);
}

.section-teal {
  --section-color: var(--teal);
}

.section-pool-blue {
  --section-color: var(--pool-blue);
}

.section-azure {
  --section-color: var(--azure);
}

.section-sparkle-blue {
  --section-color: var(--sparkle-blue);
}

.section-denim {
  --section-color: var(--denim);
}

.section-cobalt {
  --section-color: var(--cobalt);
}

.section-sapphire {
  --section-color: var(--sapphire);
}

.section-night {
  --section-color: var(--night);
}

.section-ultramarine {
  --section-color: var(--ultramarine);
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

abbr,
blockquote,
body,
button,
dd,
dl,
dt,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
input,
legend,
li,
ol,
p,
pre,
ul {
  margin: 0;
  padding: 0;
}

address,
caption,
code,
figcaption,
pre,
th {
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
}

fieldset,
iframe {
  border: 0;
}

caption,
th {
  text-align: left;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

details,
main,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  vertical-align: initial;
}

button {
  background: none;
  border: 0;
  box-sizing: initial;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  vertical-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled {
  cursor: default;
}

:focus {
  outline: 0px solid transparent;
  outline-offset: 1px;
}

:focus[data-focus-method=mouse]:not(input):not(textarea):not(select),
:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

html {
  font-size: 100%;
  quotes: "";
}

body {
  font-size: var(--body);
  line-height: 1.4705882353;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: var(--font-family);
  background-color: var(--zem-background-color);
  color: var(--text-fill-secondary-rev);
  font-style: normal;
}

body,
button,
input,
select,
textarea {
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: var(--fds-black);
}

h1 img,
h2 img,
h3 img,
h4 img,
h5 img,
h6 img {
  display: block;
  margin: 0;
}

h1 + *,
h2 + *,
h3 + *,
h4 + *,
h5 + *,
h6 + * {
  margin-top: 0.8em;
}

h1 + h1,
h1 + h2,
h1 + h3,
h1 + h4,
h1 + h5,
h1 + h6,
h2 + h1,
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
h3 + h1,
h3 + h2,
h3 + h3,
h3 + h4,
h3 + h5,
h3 + h6,
h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + h5,
h4 + h6,
h5 + h1,
h5 + h2,
h5 + h3,
h5 + h4,
h5 + h5,
h5 + h6,
h6 + h1,
h6 + h2,
h6 + h3,
h6 + h4,
h6 + h5,
h6 + h6 {
  margin-top: 0.4em;
}

ol + h1,
ol + h2,
ol + h3,
ol + h4,
ol + h5,
ol + h6,
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6,
ul + h1,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ul + h6 {
  margin-top: 1.6em;
}

.heading-collapsed + * {
  margin-top: 0;
}

ol + *,
p + *,
ul + * {
  margin-top: 0.8em;
}

ol,
ul {
  margin-left: 1.1764705882em;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

nav ol,
nav ul {
  margin: 0;
  list-style: none;
}

li li {
  font-size: 1em;
}

.link,
a {
  color: var(--zem-color);
  letter-spacing: inherit;
  cursor: pointer;
}

.link:link,
.link:visited,
a:link,
a:visited {
  text-decoration: none;
}

.link:hover,
a:hover {
  text-decoration: underline;
}

.link:focus,
a:focus {
  text-decoration: none;
}

.link:active,
a:active {
  text-decoration: none;
}

.link.disabled,
.link :disabled,
a.disabled,
a :disabled {
  opacity: 0.32;
}

.link-flex {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 8px;
}
.link-flex .more {
  padding-top: 3px;
}

.link-inline,
.link-inline:link {
  text-decoration: underline;
}

.link-standalone:link {
  text-decoration: none;
}

.link-standalone:link:focus,
.link-standalone:link:hover {
  text-decoration: underline;
}

.links-inline,
.links-stacked {
  margin-left: 0;
  margin-right: 0;
  list-style: none;
}

.links-inline li {
  display: inline;
  margin: 0 0.8em;
}

.links-inline li:first-child {
  margin-left: 0;
}

.links-inline li:last-child {
  margin-right: 0;
}

.links-stacked li {
  margin: 0;
  display: block;
}

.links-stacked li + li {
  margin-top: 0.4em;
}

b,
strong {
  font-weight: 600;
}

cite,
dfn,
em,
i {
  font-style: italic;
}

sub,
sup {
  position: relative;
  font-size: 0.6em;
  vertical-align: initial;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.small-caps {
  font-variant-caps: all-small-caps;
}

.footnote a {
  vertical-align: inherit;
  color: inherit;
}

.footnote a:hover {
  color: var(--zem-color);
  text-decoration: none;
}

.footnote-reduced {
  font-size: 0.45em;
}

sup.footnote-reduced {
  top: -0.86em;
}

sub.footnote-reduced {
  bottom: -0.36em;
}

.footnote-supglyph {
  position: relative;
  vertical-align: initial;
}

.footnote-supglyph.footnote-reduced {
  font-size: 0.9em;
  top: -0.09em;
}

.footnote-diamond {
  position: relative;
  vertical-align: initial;
  font-feature-settings: "numr";
  font-size: 1em;
}

sup.footnote-diamond {
  top: auto;
}

sub.footnote-diamond {
  bottom: -0.5em;
}

.footnote-diamond.footnote-reduced {
  font-size: 0.9em;
  top: -0.09em;
}

sub.footnote-diamond.footnote-reduced {
  top: auto;
  bottom: -0.52em;
}

.footnote-number {
  position: relative;
  vertical-align: initial;
  font-feature-settings: "numr";
  font-size: 1em;
}

sup.footnote-number {
  top: auto;
}

sub.footnote-number {
  bottom: -0.5em;
}

.footnote-number.footnote-reduced {
  font-size: 0.9em;
  top: -0.09em;
}

sub.footnote-number.footnote-reduced {
  top: auto;
  bottom: -0.52em;
}

abbr {
  border: 0;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-spacebetween {
  justify-content: space-between;
}

.justify-content-spacearound {
  justify-content: space-around;
}

.justify-content-spaceevenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.large-justify-content-start {
  justify-content: flex-start;
}

.large-justify-content-end {
  justify-content: flex-end;
}

.large-justify-content-center {
  justify-content: center;
}

.large-justify-content-spacebetween {
  justify-content: space-between;
}

.large-justify-content-spacearound {
  justify-content: space-around;
}

.large-justify-content-spaceevenly {
  justify-content: space-evenly;
}

.large-align-items-start {
  align-items: flex-start;
}

.large-align-items-center {
  align-items: center;
}

.large-align-items-end {
  align-items: flex-end;
}

.large-align-self-start {
  align-self: flex-start;
}

.large-align-self-center {
  align-self: center;
}

.large-align-self-end {
  align-self: flex-end;
}

@media only screen and (min-width: 1441px) {
  .xlarge-justify-content-start {
    justify-content: flex-start;
  }
  .xlarge-justify-content-end {
    justify-content: flex-end;
  }
  .xlarge-justify-content-center {
    justify-content: center;
  }
  .xlarge-justify-content-spacebetween {
    justify-content: space-between;
  }
  .xlarge-justify-content-spacearound {
    justify-content: space-around;
  }
  .xlarge-justify-content-spaceevenly {
    justify-content: space-evenly;
  }
  .xlarge-align-items-start {
    align-items: flex-start;
  }
  .xlarge-align-items-center {
    align-items: center;
  }
  .xlarge-align-items-end {
    align-items: flex-end;
  }
  .xlarge-align-self-start {
    align-self: flex-start;
  }
  .xlarge-align-self-center {
    align-self: center;
  }
  .xlarge-align-self-end {
    align-self: flex-end;
  }
}
@media only screen and (max-width: 1068px) {
  .medium-justify-content-start {
    justify-content: flex-start;
  }
  .medium-justify-content-end {
    justify-content: flex-end;
  }
  .medium-justify-content-center {
    justify-content: center;
  }
  .medium-justify-content-spacebetween {
    justify-content: space-between;
  }
  .medium-justify-content-spacearound {
    justify-content: space-around;
  }
  .medium-justify-content-spaceevenly {
    justify-content: space-evenly;
  }
  .medium-align-items-start {
    align-items: flex-start;
  }
  .medium-align-items-center {
    align-items: center;
  }
  .medium-align-items-end {
    align-items: flex-end;
  }
  .medium-align-self-start {
    align-self: flex-start;
  }
  .medium-align-self-center {
    align-self: center;
  }
  .medium-align-self-end {
    align-self: flex-end;
  }
}
@media only screen and (max-width: 734px) {
  .small-justify-content-start {
    justify-content: flex-start;
  }
  .small-justify-content-end {
    justify-content: flex-end;
  }
  .small-justify-content-center {
    justify-content: center;
  }
  .small-justify-content-spacebetween {
    justify-content: space-between;
  }
  .small-justify-content-spacearound {
    justify-content: space-around;
  }
  .small-justify-content-spaceevenly {
    justify-content: space-evenly;
  }
  .small-align-items-start {
    align-items: flex-start;
  }
  .small-align-items-center {
    align-items: center;
  }
  .small-align-items-end {
    align-items: flex-end;
  }
  .small-align-self-start {
    align-self: flex-start;
  }
  .small-align-self-center {
    align-self: center;
  }
  .small-align-self-end {
    align-self: flex-end;
  }
}
@media only screen and (max-width: 374px) {
  .small_narrow-justify-content-start {
    justify-content: flex-start;
  }
  .small_narrow-justify-content-end {
    justify-content: flex-end;
  }
  .small_narrow-justify-content-center {
    justify-content: center;
  }
  .small_narrow-justify-content-spacebetween {
    justify-content: space-between;
  }
  .small_narrow-justify-content-spacearound {
    justify-content: space-around;
  }
  .small_narrow-justify-content-spaceevenly {
    justify-content: space-evenly;
  }
  .small_narrow-align-items-start {
    align-items: flex-start;
  }
  .small_narrow-align-items-center {
    align-items: center;
  }
  .small_narrow-align-items-end {
    align-items: flex-end;
  }
  .small_narrow-align-self-start {
    align-self: flex-start;
  }
  .small_narrow-align-self-center {
    align-self: center;
  }
  .small_narrow-align-self-end {
    align-self: flex-end;
  }
}
.selfclear:after,
.selfclear:before {
  content: " ";
  display: table;
}

.selfclear:after {
  clear: both;
}

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

@media only screen and (inverted-colors) {
  .no-inversion {
    filter: invert(1);
  }
}
.nowrap {
  display: inline-block;
  text-decoration: inherit;
  white-space: nowrap;
}

.clear {
  clear: both;
}

.cursor-grab {
  cursor: move;
  cursor: grab;
}

.cursor-grabbing {
  cursor: move;
  cursor: grabbing;
}

.row {
  --bs-gutter-x: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0px;
}

.large-offset-0 {
  margin-left: 0;
}

.large-order-0 {
  order: 0;
}

.large-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.large-offset-1 {
  margin-left: 8.3333333333%;
}

.large-order-1 {
  order: 1;
}

.large-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.large-offset-2 {
  margin-left: 16.6666666667%;
}

.large-order-2 {
  order: 2;
}

.large-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.large-offset-3 {
  margin-left: 25%;
}

.large-order-3 {
  order: 3;
}

.large-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.large-offset-4 {
  margin-left: 33.3333333333%;
}

.large-order-4 {
  order: 4;
}

.large-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.large-offset-5 {
  margin-left: 41.6666666667%;
}

.large-order-5 {
  order: 5;
}

.large-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.large-offset-6 {
  margin-left: 50%;
}

.large-order-6 {
  order: 6;
}

.large-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.large-offset-7 {
  margin-left: 58.3333333333%;
}

.large-order-7 {
  order: 7;
}

.large-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.large-offset-8 {
  margin-left: 66.6666666667%;
}

.large-order-8 {
  order: 8;
}

.large-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.large-offset-9 {
  margin-left: 75%;
}

.large-order-9 {
  order: 9;
}

.large-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.large-offset-10 {
  margin-left: 83.3333333333%;
}

.large-order-10 {
  order: 10;
}

.large-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.large-offset-11 {
  margin-left: 91.6666666667%;
}

.large-order-11 {
  order: 11;
}

.large-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.large-offset-12 {
  margin-left: 100%;
}

.large-order-12 {
  order: 12;
}

.large-centered {
  margin-left: auto;
  margin-right: auto;
}

.large-uncentered {
  margin-left: 0;
  margin-right: 0;
}

.large-last {
  margin-left: auto;
}

.large-notlast {
  margin-left: 0;
}

.large-grow {
  flex: auto;
  max-width: none;
}

.large-ungrow {
  flex: initial;
  max-width: none;
}

@media only screen and (min-width: 1441px) {
  .xlarge-offset-0 {
    margin-left: 0;
  }
  .xlarge-order-0 {
    order: 0;
  }
  .xlarge-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-order-6 {
    order: 6;
  }
  .xlarge-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-order-7 {
    order: 7;
  }
  .xlarge-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-order-8 {
    order: 8;
  }
  .xlarge-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-order-9 {
    order: 9;
  }
  .xlarge-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-order-10 {
    order: 10;
  }
  .xlarge-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-order-11 {
    order: 11;
  }
  .xlarge-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .xlarge-offset-12 {
    margin-left: 100%;
  }
  .xlarge-order-12 {
    order: 12;
  }
  .xlarge-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .xlarge-uncentered {
    margin-left: 0;
    margin-right: 0;
  }
  .xlarge-last {
    margin-left: auto;
  }
  .xlarge-notlast {
    margin-left: 0;
  }
  .xlarge-grow {
    flex: auto;
    max-width: none;
  }
  .xlarge-ungrow {
    flex: initial;
    max-width: none;
  }
}
@media only screen and (max-width: 1068px) {
  .medium-offset-0 {
    margin-left: 0;
  }
  .medium-order-0 {
    order: 0;
  }
  .medium-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-order-1 {
    order: 1;
  }
  .medium-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-order-6 {
    order: 6;
  }
  .medium-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-order-7 {
    order: 7;
  }
  .medium-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-order-8 {
    order: 8;
  }
  .medium-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-order-9 {
    order: 9;
  }
  .medium-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-order-10 {
    order: 10;
  }
  .medium-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-order-11 {
    order: 11;
  }
  .medium-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .medium-offset-12 {
    margin-left: 100%;
  }
  .medium-order-12 {
    order: 12;
  }
  .medium-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .medium-uncentered {
    margin-left: 0;
    margin-right: 0;
  }
  .medium-last {
    margin-left: auto;
  }
  .medium-notlast {
    margin-left: 0;
  }
  .medium-grow {
    flex: auto;
    max-width: none;
  }
  .medium-ungrow {
    flex: initial;
    max-width: none;
  }
}
@media only screen and (max-width: 734px) {
  .small-offset-0 {
    margin-left: 0;
  }
  .small-order-0 {
    order: 0;
  }
  .small-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .small-offset-1 {
    margin-left: 8.3333333333%;
  }
  .small-order-1 {
    order: 1;
  }
  .small-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .small-offset-2 {
    margin-left: 16.6666666667%;
  }
  .small-order-2 {
    order: 2;
  }
  .small-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .small-offset-3 {
    margin-left: 25%;
  }
  .small-order-3 {
    order: 3;
  }
  .small-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .small-offset-4 {
    margin-left: 33.3333333333%;
  }
  .small-order-4 {
    order: 4;
  }
  .small-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .small-offset-5 {
    margin-left: 41.6666666667%;
  }
  .small-order-5 {
    order: 5;
  }
  .small-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .small-offset-6 {
    margin-left: 50%;
  }
  .small-order-6 {
    order: 6;
  }
  .small-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .small-offset-7 {
    margin-left: 58.3333333333%;
  }
  .small-order-7 {
    order: 7;
  }
  .small-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .small-offset-8 {
    margin-left: 66.6666666667%;
  }
  .small-order-8 {
    order: 8;
  }
  .small-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .small-offset-9 {
    margin-left: 75%;
  }
  .small-order-9 {
    order: 9;
  }
  .small-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .small-offset-10 {
    margin-left: 83.3333333333%;
  }
  .small-order-10 {
    order: 10;
  }
  .small-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .small-offset-11 {
    margin-left: 91.6666666667%;
  }
  .small-order-11 {
    order: 11;
  }
  .small-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .small-offset-12 {
    margin-left: 100%;
  }
  .small-order-12 {
    order: 12;
  }
  .small-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .small-uncentered {
    margin-left: 0;
    margin-right: 0;
  }
  .small-last {
    margin-left: auto;
  }
  .small-notlast {
    margin-left: 0;
  }
  .small-grow {
    flex: auto;
    max-width: none;
  }
  .small-ungrow {
    flex: initial;
    max-width: none;
  }
}
@media only screen and (max-width: 374px) {
  .small_narrow-offset-0 {
    margin-left: 0;
  }
  .small_narrow-order-0 {
    order: 0;
  }
  .small_narrow-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .small_narrow-offset-1 {
    margin-left: 8.3333333333%;
  }
  .small_narrow-order-1 {
    order: 1;
  }
  .small_narrow-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .small_narrow-offset-2 {
    margin-left: 16.6666666667%;
  }
  .small_narrow-order-2 {
    order: 2;
  }
  .small_narrow-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .small_narrow-offset-3 {
    margin-left: 25%;
  }
  .small_narrow-order-3 {
    order: 3;
  }
  .small_narrow-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .small_narrow-offset-4 {
    margin-left: 33.3333333333%;
  }
  .small_narrow-order-4 {
    order: 4;
  }
  .small_narrow-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .small_narrow-offset-5 {
    margin-left: 41.6666666667%;
  }
  .small_narrow-order-5 {
    order: 5;
  }
  .small_narrow-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .small_narrow-offset-6 {
    margin-left: 50%;
  }
  .small_narrow-order-6 {
    order: 6;
  }
  .small_narrow-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .small_narrow-offset-7 {
    margin-left: 58.3333333333%;
  }
  .small_narrow-order-7 {
    order: 7;
  }
  .small_narrow-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .small_narrow-offset-8 {
    margin-left: 66.6666666667%;
  }
  .small_narrow-order-8 {
    order: 8;
  }
  .small_narrow-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .small_narrow-offset-9 {
    margin-left: 75%;
  }
  .small_narrow-order-9 {
    order: 9;
  }
  .small_narrow-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .small_narrow-offset-10 {
    margin-left: 83.3333333333%;
  }
  .small_narrow-order-10 {
    order: 10;
  }
  .small_narrow-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .small_narrow-offset-11 {
    margin-left: 91.6666666667%;
  }
  .small_narrow-order-11 {
    order: 11;
  }
  .small_narrow-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .small_narrow-offset-12 {
    margin-left: 100%;
  }
  .small_narrow-order-12 {
    order: 12;
  }
  .small_narrow-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .small_narrow-uncentered {
    margin-left: 0;
    margin-right: 0;
  }
  .small_narrow-last {
    margin-left: auto;
  }
  .small_narrow-notlast {
    margin-left: 0;
  }
  .small_narrow-grow {
    flex: auto;
    max-width: none;
  }
  .small_narrow-ungrow {
    flex: initial;
    max-width: none;
  }
}
@media only screen and (max-width: 320px) {
  .xsmall-offset-0 {
    margin-left: 0;
  }
  .xsmall-order-0 {
    order: 0;
  }
  .xsmall-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xsmall-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xsmall-order-1 {
    order: 1;
  }
  .xsmall-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xsmall-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xsmall-order-2 {
    order: 2;
  }
  .xsmall-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .xsmall-offset-3 {
    margin-left: 25%;
  }
  .xsmall-order-3 {
    order: 3;
  }
  .xsmall-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xsmall-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xsmall-order-4 {
    order: 4;
  }
  .xsmall-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xsmall-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xsmall-order-5 {
    order: 5;
  }
  .xsmall-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .xsmall-offset-6 {
    margin-left: 50%;
  }
  .xsmall-order-6 {
    order: 6;
  }
  .xsmall-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xsmall-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xsmall-order-7 {
    order: 7;
  }
  .xsmall-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xsmall-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xsmall-order-8 {
    order: 8;
  }
  .xsmall-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .xsmall-offset-9 {
    margin-left: 75%;
  }
  .xsmall-order-9 {
    order: 9;
  }
  .xsmall-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xsmall-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xsmall-order-10 {
    order: 10;
  }
  .xsmall-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xsmall-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xsmall-order-11 {
    order: 11;
  }
  .xsmall-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .xsmall-offset-12 {
    margin-left: 100%;
  }
  .xsmall-order-12 {
    order: 12;
  }
  .xsmall-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .xsmall-uncentered {
    margin-left: 0;
    margin-right: 0;
  }
  .xsmall-last {
    margin-left: auto;
  }
  .xsmall-notlast {
    margin-left: 0;
  }
  .xsmall-grow {
    flex: auto;
    max-width: none;
  }
  .xsmall-ungrow {
    flex: initial;
    max-width: none;
  }
}
.row-reverse .column {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0px;
}

.row-reverse .large-offset-0 {
  margin-right: 0;
}

.row-reverse .large-offset-1 {
  margin-right: 8.3333333333%;
}

.row-reverse .large-offset-2 {
  margin-right: 16.6666666667%;
}

.row-reverse .large-offset-3 {
  margin-right: 25%;
}

.row-reverse .large-offset-4 {
  margin-right: 33.3333333333%;
}

.row-reverse .large-offset-5 {
  margin-right: 41.6666666667%;
}

.row-reverse .large-offset-6 {
  margin-right: 50%;
}

.row-reverse .large-offset-7 {
  margin-right: 58.3333333333%;
}

.row-reverse .large-offset-8 {
  margin-right: 66.6666666667%;
}

.row-reverse .large-offset-9 {
  margin-right: 75%;
}

.row-reverse .large-offset-10 {
  margin-right: 83.3333333333%;
}

.row-reverse .large-offset-11 {
  margin-right: 91.6666666667%;
}

.row-reverse .large-offset-12 {
  margin-right: 100%;
}

.row-reverse .large-last {
  margin-right: auto;
}

.row-reverse .large-notlast {
  margin-right: 0;
}

@media only screen and (min-width: 1441px) {
  .row-reverse .xlarge-offset-0 {
    margin-right: 0;
  }
  .row-reverse .xlarge-offset-1 {
    margin-right: 8.3333333333%;
  }
  .row-reverse .xlarge-offset-2 {
    margin-right: 16.6666666667%;
  }
  .row-reverse .xlarge-offset-3 {
    margin-right: 25%;
  }
  .row-reverse .xlarge-offset-4 {
    margin-right: 33.3333333333%;
  }
  .row-reverse .xlarge-offset-5 {
    margin-right: 41.6666666667%;
  }
  .row-reverse .xlarge-offset-6 {
    margin-right: 50%;
  }
  .row-reverse .xlarge-offset-7 {
    margin-right: 58.3333333333%;
  }
  .row-reverse .xlarge-offset-8 {
    margin-right: 66.6666666667%;
  }
  .row-reverse .xlarge-offset-9 {
    margin-right: 75%;
  }
  .row-reverse .xlarge-offset-10 {
    margin-right: 83.3333333333%;
  }
  .row-reverse .xlarge-offset-11 {
    margin-right: 91.6666666667%;
  }
  .row-reverse .xlarge-offset-12 {
    margin-right: 100%;
  }
  .row-reverse .xlarge-last {
    margin-right: auto;
  }
  .row-reverse .xlarge-notlast {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1068px) {
  .row-reverse .medium-offset-0 {
    margin-right: 0;
  }
  .row-reverse .medium-offset-1 {
    margin-right: 8.3333333333%;
  }
  .row-reverse .medium-offset-2 {
    margin-right: 16.6666666667%;
  }
  .row-reverse .medium-offset-3 {
    margin-right: 25%;
  }
  .row-reverse .medium-offset-4 {
    margin-right: 33.3333333333%;
  }
  .row-reverse .medium-offset-5 {
    margin-right: 41.6666666667%;
  }
  .row-reverse .medium-offset-6 {
    margin-right: 50%;
  }
  .row-reverse .medium-offset-7 {
    margin-right: 58.3333333333%;
  }
  .row-reverse .medium-offset-8 {
    margin-right: 66.6666666667%;
  }
  .row-reverse .medium-offset-9 {
    margin-right: 75%;
  }
  .row-reverse .medium-offset-10 {
    margin-right: 83.3333333333%;
  }
  .row-reverse .medium-offset-11 {
    margin-right: 91.6666666667%;
  }
  .row-reverse .medium-offset-12 {
    margin-right: 100%;
  }
  .row-reverse .medium-last {
    margin-right: auto;
  }
  .row-reverse .medium-notlast {
    margin-right: 0;
  }
}
@media only screen and (max-width: 734px) {
  .row-reverse .small-offset-0 {
    margin-right: 0;
  }
  .row-reverse .small-offset-1 {
    margin-right: 8.3333333333%;
  }
  .row-reverse .small-offset-2 {
    margin-right: 16.6666666667%;
  }
  .row-reverse .small-offset-3 {
    margin-right: 25%;
  }
  .row-reverse .small-offset-4 {
    margin-right: 33.3333333333%;
  }
  .row-reverse .small-offset-5 {
    margin-right: 41.6666666667%;
  }
  .row-reverse .small-offset-6 {
    margin-right: 50%;
  }
  .row-reverse .small-offset-7 {
    margin-right: 58.3333333333%;
  }
  .row-reverse .small-offset-8 {
    margin-right: 66.6666666667%;
  }
  .row-reverse .small-offset-9 {
    margin-right: 75%;
  }
  .row-reverse .small-offset-10 {
    margin-right: 83.3333333333%;
  }
  .row-reverse .small-offset-11 {
    margin-right: 91.6666666667%;
  }
  .row-reverse .small-offset-12 {
    margin-right: 100%;
  }
  .row-reverse .small-last {
    margin-right: auto;
  }
  .row-reverse .small-notlast {
    margin-right: 0;
  }
}
@media only screen and (max-width: 374px) {
  .row-reverse .small_narrow-offset-0 {
    margin-right: 0;
  }
  .row-reverse .small_narrow-offset-1 {
    margin-right: 8.3333333333%;
  }
  .row-reverse .small_narrow-offset-2 {
    margin-right: 16.6666666667%;
  }
  .row-reverse .small_narrow-offset-3 {
    margin-right: 25%;
  }
  .row-reverse .small_narrow-offset-4 {
    margin-right: 33.3333333333%;
  }
  .row-reverse .small_narrow-offset-5 {
    margin-right: 41.6666666667%;
  }
  .row-reverse .small_narrow-offset-6 {
    margin-right: 50%;
  }
  .row-reverse .small_narrow-offset-7 {
    margin-right: 58.3333333333%;
  }
  .row-reverse .small_narrow-offset-8 {
    margin-right: 66.6666666667%;
  }
  .row-reverse .small_narrow-offset-9 {
    margin-right: 75%;
  }
  .row-reverse .small_narrow-offset-10 {
    margin-right: 83.3333333333%;
  }
  .row-reverse .small_narrow-offset-11 {
    margin-right: 91.6666666667%;
  }
  .row-reverse .small_narrow-offset-12 {
    margin-right: 100%;
  }
  .row-reverse .small_narrow-last {
    margin-right: auto;
  }
  .row-reverse .small_narrow-notlast {
    margin-right: 0;
  }
}
@media only screen and (max-width: 320px) {
  .row-reverse .xsmall-offset-0 {
    margin-right: 0;
  }
  .row-reverse .xsmall-offset-1 {
    margin-right: 8.3333333333%;
  }
  .row-reverse .xsmall-offset-2 {
    margin-right: 16.6666666667%;
  }
  .row-reverse .xsmall-offset-3 {
    margin-right: 25%;
  }
  .row-reverse .xsmall-offset-4 {
    margin-right: 33.3333333333%;
  }
  .row-reverse .xsmall-offset-5 {
    margin-right: 41.6666666667%;
  }
  .row-reverse .xsmall-offset-6 {
    margin-right: 50%;
  }
  .row-reverse .xsmall-offset-7 {
    margin-right: 58.3333333333%;
  }
  .row-reverse .xsmall-offset-8 {
    margin-right: 66.6666666667%;
  }
  .row-reverse .xsmall-offset-9 {
    margin-right: 75%;
  }
  .row-reverse .xsmall-offset-10 {
    margin-right: 83.3333333333%;
  }
  .row-reverse .xsmall-offset-11 {
    margin-right: 91.6666666667%;
  }
  .row-reverse .xsmall-offset-12 {
    margin-right: 100%;
  }
  .row-reverse .xsmall-last {
    margin-right: auto;
  }
  .row-reverse .xsmall-notlast {
    margin-right: 0;
  }
}
.grid {
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid .grid {
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.grid-item {
  position: relative;
  box-sizing: border-box;
}

.grid-item {
  background-color: initial;
}

.large-span-0 {
  grid-column: span 0;
}

.large-span-1 {
  grid-column: span 1;
}

.large-span-2 {
  grid-column: span 2;
}

.large-span-3 {
  grid-column: span 3;
}

.large-span-4 {
  grid-column: span 4;
}

.large-span-5 {
  grid-column: span 5;
}

.large-span-6 {
  grid-column: span 6;
}

.large-span-7 {
  grid-column: span 7;
}

.large-span-8 {
  grid-column: span 8;
}

.large-span-9 {
  grid-column: span 9;
}

.large-span-10 {
  grid-column: span 10;
}

.large-span-11 {
  grid-column: span 11;
}

.large-span-12 {
  grid-column: span 12;
}

@media only screen and (min-width: 1441px) {
  .xlarge-span-0 {
    grid-column: span 0;
  }
  .xlarge-span-1 {
    grid-column: span 1;
  }
  .xlarge-span-2 {
    grid-column: span 2;
  }
  .xlarge-span-3 {
    grid-column: span 3;
  }
  .xlarge-span-4 {
    grid-column: span 4;
  }
  .xlarge-span-5 {
    grid-column: span 5;
  }
  .xlarge-span-6 {
    grid-column: span 6;
  }
  .xlarge-span-7 {
    grid-column: span 7;
  }
  .xlarge-span-8 {
    grid-column: span 8;
  }
  .xlarge-span-9 {
    grid-column: span 9;
  }
  .xlarge-span-10 {
    grid-column: span 10;
  }
  .xlarge-span-11 {
    grid-column: span 11;
  }
  .xlarge-span-12 {
    grid-column: span 12;
  }
}
@media only screen and (max-width: 1068px) {
  .medium-span-0 {
    grid-column: span 0;
  }
  .medium-span-1 {
    grid-column: span 1;
  }
  .medium-span-2 {
    grid-column: span 2;
  }
  .medium-span-3 {
    grid-column: span 3;
  }
  .medium-span-4 {
    grid-column: span 4;
  }
  .medium-span-5 {
    grid-column: span 5;
  }
  .medium-span-6 {
    grid-column: span 6;
  }
  .medium-span-7 {
    grid-column: span 7;
  }
  .medium-span-8 {
    grid-column: span 8;
  }
  .medium-span-9 {
    grid-column: span 9;
  }
  .medium-span-10 {
    grid-column: span 10;
  }
  .medium-span-11 {
    grid-column: span 11;
  }
  .medium-span-12 {
    grid-column: span 12;
  }
}
@media only screen and (max-width: 734px) {
  .small-span-0 {
    grid-column: span 0;
  }
  .small-span-1 {
    grid-column: span 1;
  }
  .small-span-2 {
    grid-column: span 2;
  }
  .small-span-3 {
    grid-column: span 3;
  }
  .small-span-4 {
    grid-column: span 4;
  }
  .small-span-5 {
    grid-column: span 5;
  }
  .small-span-6 {
    grid-column: span 6;
  }
  .small-span-7 {
    grid-column: span 7;
  }
  .small-span-8 {
    grid-column: span 8;
  }
  .small-span-9 {
    grid-column: span 9;
  }
  .small-span-10 {
    grid-column: span 10;
  }
  .small-span-11 {
    grid-column: span 11;
  }
  .small-span-12 {
    grid-column: span 12;
  }
}
@media only screen and (max-width: 374px) {
  .small_narrow-span-0 {
    grid-column: span 0;
  }
  .small_narrow-span-1 {
    grid-column: span 1;
  }
  .small_narrow-span-2 {
    grid-column: span 2;
  }
  .small_narrow-span-3 {
    grid-column: span 3;
  }
  .small_narrow-span-4 {
    grid-column: span 4;
  }
  .small_narrow-span-5 {
    grid-column: span 5;
  }
  .small_narrow-span-6 {
    grid-column: span 6;
  }
  .small_narrow-span-7 {
    grid-column: span 7;
  }
  .small_narrow-span-8 {
    grid-column: span 8;
  }
  .small_narrow-span-9 {
    grid-column: span 9;
  }
  .small_narrow-span-10 {
    grid-column: span 10;
  }
  .small_narrow-span-11 {
    grid-column: span 11;
  }
  .small_narrow-span-12 {
    grid-column: span 12;
  }
}
@media only screen and (max-width: 320px) {
  .xsmall-span-0 {
    grid-column: span 0;
  }
  .xsmall-span-1 {
    grid-column: span 1;
  }
  .xsmall-span-2 {
    grid-column: span 2;
  }
  .xsmall-span-3 {
    grid-column: span 3;
  }
  .xsmall-span-4 {
    grid-column: span 4;
  }
  .xsmall-span-5 {
    grid-column: span 5;
  }
  .xsmall-span-6 {
    grid-column: span 6;
  }
  .xsmall-span-7 {
    grid-column: span 7;
  }
  .xsmall-span-8 {
    grid-column: span 8;
  }
  .xsmall-span-9 {
    grid-column: span 9;
  }
  .xsmall-span-10 {
    grid-column: span 10;
  }
  .xsmall-span-11 {
    grid-column: span 11;
  }
  .xsmall-span-12 {
    grid-column: span 12;
  }
}
.more-block {
  margin-top: 0.5em;
}

.icon-wrapper .icon,
.icon-wrapper .icon-after::after,
.icon-wrapper .icon-before::before,
.icon-wrapper .more:not(.icon-before)::after {
  display: inline;
  position: static;
}

a.icon-wrapper {
  text-decoration: none;
}

a.icon-wrapper:hover .icon-copy {
  text-decoration: underline;
}

button .more {
  padding-top: 4px;
}

body {
  min-width: 320px;
}

.large-hide {
  display: none;
}

.large-show {
  display: block;
}

.large-show-inline {
  display: inline;
}

.large-show-inlineblock {
  display: inline-block;
}

@media only screen and (min-width: 1441px) {
  .xlarge-hide {
    display: none;
  }
  .xlarge-show {
    display: block;
  }
  .xlarge-show-inline {
    display: inline;
  }
  .xlarge-show-inlineblock {
    display: inline-block;
  }
}
@media only screen and (max-width: 1068px) {
  .medium-hide {
    display: none;
  }
  .medium-show {
    display: block;
  }
  .medium-show-inline {
    display: inline;
  }
  .medium-show-inlineblock {
    display: inline-block;
  }
}
@media only screen and (max-width: 734px) {
  .small-hide {
    display: none;
  }
  .small-show {
    display: block;
  }
  .small-show-inline {
    display: inline;
  }
  .small-show-inlineblock {
    display: inline-block;
  }
}
@media only screen and (max-width: 374px) {
  .small_narrow-hide {
    display: none;
  }
  .small_narrow-show {
    display: block;
  }
  .small_narrow-show-inline {
    display: inline;
  }
  .small_narrow-show-inlineblock {
    display: inline-block;
  }
}
@media only screen and (max-width: 320px) {
  .xsmall-hide {
    display: none;
  }
  .xsmall-show {
    display: block;
  }
  .xsmall-show-inline {
    display: inline;
  }
  .xsmall-show-inlineblock {
    display: inline-block;
  }
}
.viewport-content {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}
@media only screen and (min-width: 1441px) {
  .viewport-content {
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .viewport-content {
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .viewport-content {
    width: 87.5%;
  }
}
@media only screen and (max-width: 320px) {
  .viewport-content {
    width: 87.5%;
  }
}

br.large {
  display: block;
}
@media only screen and (max-width: 1068px) {
  br.large {
    display: none;
  }
}
@media only screen and (max-width: 734px) {
  br.large {
    display: none;
  }
}

br.medium {
  display: none;
}
@media only screen and (max-width: 1068px) {
  br.medium {
    display: block;
  }
}
@media only screen and (max-width: 734px) {
  br.medium {
    display: none;
  }
}

br.small {
  display: none;
}
@media only screen and (max-width: 1068px) {
  br.small {
    display: none;
  }
}
@media only screen and (max-width: 734px) {
  br.small {
    display: block;
  }
}

.button {
  --button-text-weight: 500;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font-size: ver(--body);
  line-height: 1.1764805882;
  font-weight: var(--button-text-weight);
  letter-spacing: -0.022em;
  min-width: 30px;
  padding: 7px 15px;
  border-radius: var(--fds-button-round);
  background: var(--zem-fill-blue);
  color: var(--text-fill);
  border: 1px solid transparent;
  --ze-button-margin-horizontal: 14px;
  --ze-button-margin-vertical: 14px;
}
.button:hover {
  text-decoration: none;
}
.button:active {
  outline: none;
}
.button.disabled, .button:disabled {
  cursor: default;
  pointer-events: none;
}
.button:focus, .button:hover {
  background: #0077ed;
  color: var(--text-fill);
  border-color: transparent;
}
.button:focus {
  box-shadow: 0 0 0 0px transparent, 0 0 0 0px transparent;
  outline: none;
}
.button:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button:active {
  background: #006edb;
  color: var(--text-fill);
  border-color: transparent;
}
.button.disabled, .button:disabled {
  background: var(--zem-fill-blue);
  color: var(--text-fill);
  opacity: var(--ze-link-disabled-opacity);
}

.button-block {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-radius: var(--fds-button-round-small);
  --ze-button-margin-horizontal: 9px;
  --ze-button-margin-vertical: 9px;
}

.button-neutral {
  background: var(--fds-fill-gray);
  color: var(--text-fill);
  border-color: transparent;
}
.button-neutral:focus, .button-neutral:hover {
  background: #272729;
  color: var(--text-fill);
  border-color: transparent;
}
.button-neutral:focus {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.button-neutral:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-neutral:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-neutral:active {
  background: #18181a;
  color: var(--text-fill);
  border-color: transparent;
}
.button-neutral.disabled, .button-neutral:disabled {
  background: var(--fds-fill-gray);
  color: var(--text-fill);
  opacity: var(--ze-link-disabled-opacity);
}

.button-secondary {
  background: #757579;
  color: var(--text-fill);
  border-color: transparent;
}
.button-secondary:focus, .button-secondary:hover {
  background: #79797d;
  color: var(--text-fill);
  border-color: transparent;
}
.button-secondary:focus {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.button-secondary:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-secondary:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-secondary:active {
  background: #747478;
  color: var(--text-fill);
  border-color: transparent;
}
.button-secondary.disabled, .button-secondary:disabled {
  background: #757579;
  color: var(--text-fill);
  opacity: 0.56;
}

.button-secondary-alpha {
  background: rgba(0, 0, 0, 0.08);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-secondary-alpha:focus, .button-secondary-alpha:hover {
  background: rgba(0, 0, 0, 0.07);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-secondary-alpha:focus {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.button-secondary-alpha:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-secondary-alpha:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-secondary-alpha:active {
  background: rgba(0, 0, 0, 0.09);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-secondary-alpha.disabled, .button-secondary-alpha:disabled {
  background: rgba(0, 0, 0, 0.08);
  color: var(--text-fill-rev);
  opacity: 0.56;
}

.button-tertiary {
  background: transparent;
  color: rgb(0, 102, 204);
  border-color: rgb(0, 102, 204);
}
.button-tertiary:focus, .button-tertiary:hover {
  background: #0077ed;
  color: var(--text-fill);
  border-color: transparent;
}
.button-tertiary:focus {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.button-tertiary:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-tertiary:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-tertiary:active {
  background: #006edb;
  color: var(--text-fill);
  border-color: transparent;
}
.button-tertiary.disabled, .button-tertiary:disabled {
  background: transparent;
  color: rgb(0, 102, 204);
  opacity: var(--ze-link-disabled-opacity);
}

.button-tertiary-neutral {
  background: transparent;
  color: var(--text-fill-secondary);
  border-color: var(--fds-fill-secondary-rev);
}
.button-tertiary-neutral:focus, .button-tertiary-neutral:hover {
  background: #272729;
  color: var(--text-fill);
  border-color: transparent;
}
.button-tertiary-neutral:focus {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.button-tertiary-neutral:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-tertiary-neutral:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-tertiary-neutral:active {
  background: #18181a;
  color: var(--text-fill-alt);
  border-color: transparent;
}
.button-tertiary-neutral.disabled, .button-tertiary-neutral:disabled {
  background: transparent;
  color: var(--text-fill-secondary);
  opacity: var(--ze-link-disabled-opacity);
}

.button-base {
  background: var(--fds-glyph);
  color: var(--text-fill);
  border-color: transparent;
  text-decoration: none;
}
.button-base:focus, .button-base:hover {
  background: var(--fds-glyph);
  color: var(--text-fill);
  border-color: transparent;
}
.button-base:focus {
  box-shadow: 0 0 0 0px transparent, 0 0 0 0px transparent;
  outline: none;
}
.button-base:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-base:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-base:active {
  background: var(--fds-glyph);
  color: var(--text-fill);
  border-color: transparent;
}
.button-base.disabled, .button-base:disabled {
  background: #757579;
  color: var(--text-fill);
  opacity: 0.56;
}

.button-alt {
  background: var(--fds-fill-alt);
  color: var(--text-fill-rev);
  border-color: transparent;
  text-decoration: none;
}
.button-alt:focus, .button-alt:hover {
  background: var(--zem-background-color);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-alt:focus {
  box-shadow: 0 0 0 0px transparent, 0 0 0 0px transparent;
  outline: none;
}
.button-alt:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-alt:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-alt:active {
  background: var(--fds-fill-alt);
  color: var(--text-fill-alt);
  border-color: transparent;
}
.button-alt.disabled, .button-alt:disabled {
  background: #757579;
  color: var(--text-fill);
  opacity: 0.56;
}

.button-alt-alpha {
  background: rgba(0, 0, 0, 0.08);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-alt-alpha:focus, .button-alt-alpha:hover {
  background: rgba(0, 0, 0, 0.07);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-alt-alpha:focus {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.button-alt-alpha:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-alt-alpha:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-alt-alpha:active {
  background: rgba(0, 0, 0, 0.09);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.button-alt-alpha.disabled, .button-alt-alpha:disabled {
  background: rgba(0, 0, 0, 0.08);
  color: var(--text-fill-rev);
  opacity: 0.56;
}

.button-always-dark {
  background: var(--fds-black);
  color: var(--text-white);
  border-color: transparent;
  text-decoration: none;
}
.button-always-dark:focus, .button-always-dark:hover {
  background: var(--fds-fill-gray);
  color: var(--text-white);
  border-color: transparent;
}
.button-always-dark:focus {
  box-shadow: 0 0 0 0px transparent, 0 0 0 0px transparent;
  outline: none;
}
.button-always-dark:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .button-always-dark:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.button-always-dark:active {
  background: var(--fds-black);
  color: var(--text-white);
  border-color: transparent;
}
.button-always-dark.disabled, .button-always-dark:disabled {
  background: #757579;
  color: var(--text-fill);
  opacity: 0.56;
}

.button-super {
  font-size: var(--body);
  line-height: 1.1764805882;
  font-weight: 400;
  letter-spacing: -0.022em;
  border-style: solid;
  border-width: 1px;
  min-width: 30px;
  padding: 17px 30px;
  --ze-button-margin-horizontal: 22px;
  --ze-button-margin-vertical: 22px;
}
.button-super.button-block {
  border-radius: var(--fds-button-round);
  --ze-button-margin-horizontal: 14px;
  --ze-button-margin-vertical: 14px;
}

.button-elevated {
  font-size: var(--body);
  line-height: 1.1764805882;
  font-weight: 400;
  letter-spacing: -0.022em;
  border-style: solid;
  border-width: 1px;
  min-width: 28px;
  padding: 11px 21px;
  --ze-button-margin-horizontal: 18px;
  --ze-button-margin-vertical: 18px;
}
.button-elevated.button-block {
  border-radius: var(--fds-button-round);
  --ze-button-margin-horizontal: 11px;
  --ze-button-margin-vertical: 11px;
}

.button-reduced {
  font-size: 12px;
  line-height: 1.3333733333;
  font-weight: 400;
  letter-spacing: -0.01em;
  border-style: solid;
  border-width: 1px;
  min-width: 25px;
  padding: 3px 10px;
  --ze-button-margin-horizontal: 10px;
  --ze-button-margin-vertical: 10px;
}
.button-reduced.button-block {
  border-radius: var(--fds-button-round-small);
  --ze-button-margin-horizontal: 6px;
  --ze-button-margin-vertical: 6px;
}

.button-learn {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
  margin-top: 30px;
}
@media (max-width: 734px) {
  .button-learn {
    margin-top: 30px;
  }
}

.button-margin {
  margin-right: var(--ze-button-margin-horizontal);
  margin-bottom: var(--ze-button-margin-vertical);
}

.whileTap {
  transition: all 0.25s var(--global-animation-easing-function);
}
.whileTap:focus {
  outline: 0;
  border: 0;
  animation: squeeze 0.25s var(--global-animation-easing-function);
}

@keyframes squeeze {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.App {
  margin: 0;
  min-height: 100%;
}

html.safari .tile {
  clip-path: inset(1px 1px 1px 1px round var(--tile-border-radius));
}

:root {
  --grid-gutter: 25px;
  --grid-max-width: calc(1440px - var(--grid-gutter) * 2);
  --grid-background-color: var(--zem-background-color);
  --tile-padding-top: 36px;
  --tile-padding-bottom: 40px;
  --tile-padding-left: var(--tile-padding-bottom);
  --tile-padding-right: var(--tile-padding-left);
  --tile-border-radius: var(--fds-box-rounded);
}
@media only screen and (max-width: 1068px) {
  :root {
    --grid-gutter: 20px;
    --tile-padding-top: 30px;
    --tile-padding-bottom: 35px;
  }
}
@media only screen and (max-width: 734px) {
  :root {
    --grid-gutter: 15px;
    --grid-max-width: 480px;
    --tile-padding-bottom: 25px;
    --tile-padding-bottom: 30px;
  }
}
@media only screen and (max-width: 374px) {
  :root {
    --tile-border-radius: 0;
  }
}

.tile {
  --tile-background-color: var(--fds-fill);
  --tile-body-padding-top: 70px;
  --tile-body-padding-bottom: var(--tile-padding-bottom);
  --tile-copy-max-width: 655px;
}
@media only screen and (max-width: 1068px) {
  .tile {
    --tile-body-padding-top: 60px;
  }
}
@media only screen and (max-width: 734px) {
  .tile {
    --tile-body-padding-top: 50px;
  }
}

.tile {
  border-radius: var(--tile-border-radius);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--tile-background-color);
}

.tile-content {
  background-color: var(--tile-background-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.tile-header {
  padding-top: var(--tile-padding-top);
  padding-left: var(--tile-padding-left);
  padding-right: var(--tile-padding-right);
  max-width: var(--tile-copy-max-width);
}

.violator {
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-block;
  background: transparent;
  border: 1px solid #bf4800;
  color: #bf4800;
  font-size: var(--body);
  line-height: 1.1764805882;
  font-weight: 400;
  letter-spacing: -0.022em;
  border-radius: var(--fds-box-rounded);
  padding: 7px 18px;
}

.violator-inline {
  margin-left: 10px;
  display: inline-block;
}

.violator-secondary {
  background: transparent;
  border-color: var(--fds-glyph-gray-secondary-alpha);
  color: var(--fds-glyph-gray-secondary-alpha);
}

.violator-reduced {
  font-size: 12px;
  line-height: 1.3333733333;
  font-weight: 400;
  letter-spacing: -0.01em;
  border-radius: var(--fds-button-round);
  padding: 3px 11px;
}
.violator-reduced.violator-inline {
  margin-left: 6px;
  display: inline-block;
}

.violator-frameless {
  box-sizing: border-box;
  white-space: nowrap;
  display: block;
  color: #bf4800;
}

.violator-frameless-inline {
  margin-left: 10px;
  display: inline-block;
}

.violator-frameless.violator-frameless-reduced {
  font-size: 12px;
  line-height: 1.3333733333;
  font-weight: 400;
  letter-spacing: -0.01em;
  border-radius: 0;
  padding: 0;
  display: block;
}

.violator-frameless.violator-frameless-reduced.violator-frameless-inline {
  margin-left: 6px;
  display: inline-block;
}

.theme-dark {
  --theme-dark-background: var(--fds-black);
  --theme-dark-primary-text: var(--fds-fill);
  --theme-dark-secondary-text: var(--text-fill-secondary);
  background-color: var(--theme-dark-background);
  color: var(--theme-dark-secondary-text);
}
.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6 {
  color: var(--theme-dark-secondary-text);
}
.theme-dark .link,
.theme-dark a {
  color: #2997ff;
}

.theme-dark .tile-headline,
.tile-headline {
  color: var(--tile-overlay-background-color, var(--section-color));
}

.theme-dark .footnote a {
  color: inherit;
}
.theme-dark .footnote a:hover {
  color: #2997ff;
}

.theme-dark .button {
  --theme-dark-button-primary: var(--zem-fill-blue);
  --theme-dark-button-text: var(--text-fill);
  background: var(--theme-dark-button-primary);
  color: var(--theme-dark-button-text);
}
.theme-dark .button:focus, .theme-dark .button:hover {
  background: #0077ed;
  color: var(--theme-dark-button-text);
  border-color: transparent;
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--theme-dark-button-primary);
  outline: none;
}
.theme-dark .button:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button:active {
  background: var(--theme-dark-button-primary);
  color: var(--theme-dark-button-text);
  border-color: transparent;
}
.theme-dark .button.disabled, .theme-dark .button:disabled {
  background: var(--theme-dark-button-primary);
  color: var(--theme-dark-button-text);
  opacity: 0.32;
}

.theme-dark .button-always-dark {
  --always-dark-background: var(--fds-black);
  background: var(--fds-black);
  color: var(--fds-white);
}
.theme-dark .button-always-dark:focus, .theme-dark .button-always-dark:hover {
  background: var(--fds-fill-gray);
  color: var(--fds-white);
  border-color: transparent;
  outline: none;
}
.theme-dark .button-always-dark:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button-always-dark:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button-always-dark:active {
  background: var(--always-dark-background);
  color: var(--fds-white);
  border-color: transparent;
}
.theme-dark .button-always-dark.disabled, .theme-dark .button-always-dark:disabled {
  background: var(--fds-fill-gray);
  color: var(--fds-white-alpha-30);
  opacity: 0.32;
}

.theme-dark .button-alt {
  background: #757579;
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-alt:focus, .theme-dark .button-alt:hover {
  background: #79797d;
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-alt:focus {
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.theme-dark .button-alt:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button-alt:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button-alt:active {
  background: #747478;
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-alt.disabled, .theme-dark .button-alt:disabled {
  background: #757579;
  color: var(--text-fill);
  opacity: 0.56;
}

.theme-dark .button-alt-alpha {
  background: rgba(255, 255, 255, 0.2);
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-alt-alpha:focus, .theme-dark .button-alt-alpha:hover {
  background: rgba(255, 255, 255, 0.21);
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-alt-alpha:focus {
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.theme-dark .button-alt-alpha:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button-alt-alpha:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button-alt-alpha:active {
  background: rgba(255, 255, 255, 0.19);
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-alt-alpha.disabled, .theme-dark .button-alt-alpha:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: var(--text-fill);
  opacity: 0.56;
}

.theme-dark .button-tertiary {
  background: transparent;
  color: #2997ff;
  border-color: #2997ff;
}
.theme-dark .button-tertiary:focus, .theme-dark .button-tertiary:hover {
  background: #0077ed;
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-tertiary:focus {
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.theme-dark .button-tertiary:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button-tertiary:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button-tertiary:active {
  background: #006edb;
  color: var(--text-fill);
  border-color: transparent;
}
.theme-dark .button-tertiary.disabled, .theme-dark .button-tertiary:disabled {
  background: transparent;
  color: #2997ff;
  opacity: var(--ze-link-disabled-opacity);
}

.theme-dark .button-tertiary-neutral {
  background: transparent;
  color: var(--text-fill-secondary);
  border-color: var(--zem-border-color);
}
.theme-dark .button-tertiary-neutral:focus, .theme-dark .button-tertiary-neutral:hover {
  background: var(--fds-fill);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.theme-dark .button-tertiary-neutral:focus {
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.theme-dark .button-tertiary-neutral:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button-tertiary-neutral:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button-tertiary-neutral:active {
  background: var(--fds-fill-tertiary);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.theme-dark .button-tertiary-neutral.disabled, .theme-dark .button-tertiary-neutral:disabled {
  background: transparent;
  color: var(--text-fill-secondary);
  opacity: var(--ze-link-disabled-opacity);
}

.theme-dark .button-neutral {
  background: var(--fds-background-color);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.theme-dark .button-neutral:focus, .theme-dark .button-neutral:hover {
  background: var(--fds-fill);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.theme-dark .button-neutral:focus[data-focus-method=mouse]:not(input):not(textarea):not(select), .theme-dark .button-neutral:focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  box-shadow: none;
}
.theme-dark .button-neutral:active {
  background: var(--fds-fill-tertiary);
  color: var(--text-fill-rev);
  border-color: transparent;
}
.theme-dark .button-neutral:focus {
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.theme-dark .button-neutral.disabled, .theme-dark .button-neutral:disabled {
  background: var(--fds-background-color);
  color: var(--text-fill-rev);
  opacity: 0.32;
}

.theme-dark .tile {
  --tile-background-color: black;
  background-color: var(--tile-background-color);
}

.theme-dark .tile-button {
  background-color: rgba(66, 66, 69, 0.72);
  color: rgba(255, 255, 255, 0.8);
}
.theme-dark .tile-button:hover {
  background-color: rgba(73, 73, 76, 0.7256);
}
.theme-dark .tile-button:active {
  background-color: rgba(57, 57, 60, 0.7424);
}
.theme-dark .tile-button:hover {
  color: var(--text-fill);
}

.theme-dark .tile-button-wrapper:focus {
  outline: none;
}
.theme-dark .tile-button-wrapper:focus .tile-button {
  box-shadow: 0 0 0 3px black, 0 0 0 5px var(--zem-fill-blue);
  outline: none;
}
.theme-dark .tile-button-wrapper:focus .tile-button[data-focus-method=mouse]:not(input):not(textarea):not(select),
.theme-dark .tile-button-wrapper:focus .tile-button-wrapper:focus[data-focus-method=mouse]:not(input):not(textarea):not(select) .tile-button,
.theme-dark .tile-button-wrapper:focus .tile-button-wrapper:focus[data-focus-method=touch]:not(input):not(textarea):not(select) .tile-button {
  box-shadow: none;
}
.theme-dark .tile-button-wrapper:focus .tile-button[data-focus-method=touch]:not(input):not(textarea):not(select),
.theme-dark .tile-button-wrapper:focus .tile-button-wrapper:focus[data-focus-method=touch]:not(input):not(textarea):not(select) .tile-button {
  box-shadow: none;
}
.theme-dark .sosumi {
  color: var(--fds-glyph-gray-secondary-alpha);
}
.theme-dark .sosumi a {
  color: #a1a1a6;
}
.theme-dark .violator,
.theme-dark .violator-frameless {
  background: transparent;
  border-color: #f56300;
  color: #f56300;
}
.theme-dark .violator-secondary {
  background: transparent;
  border-color: #86868b;
  color: #86868b;
}

.paddlenav {
  --ze-paddlenav-diameter: 36px;
  --ze-paddlenav-arrow-color: rgba(0, 0, 0, 0.56);
  --ze-paddlenav-arrow-color-hover: rgba(0, 0, 0, 0.64);
  --ze-paddlenav-arrow-color-active: rgba(0, 0, 0, 0.64);
  --ze-paddlenav-background: transparent;
  --ze-paddlenav-background-hover: transparent;
  --ze-paddlenav-background-active: transparent;
  --paddlenav-offset: 60px;
  --ze-paddlenav-background: rgba(210, 210, 215, 0.64);
  --ze-paddlenav-background-hover: rgba(223, 223, 227, 0.6976);
  --ze-paddlenav-background-active: rgba(193, 193, 198, 0.6544);
}

.paddlenav-alpha {
  --ze-paddlenav-background: rgba(210, 210, 215, 0.64);
  --ze-paddlenav-background-hover: rgba(223, 223, 227, 0.6976);
  --ze-paddlenav-background-active: rgba(193, 193, 198, 0.6544);
}

.theme-dark .paddlenav-alpha {
  --ze-paddlenav-arrow-color: rgba(255, 255, 255, 0.8);
  --ze-paddlenav-arrow-color-hover: rgb(255, 255, 255);
  --ze-paddlenav-arrow-color-active: rgb(255, 255, 255);
  --ze-paddlenav-background: rgba(66, 66, 69, 0.72);
  --ze-paddlenav-background-hover: rgba(73, 73, 76, 0.7256);
  --ze-paddlenav-background-active: rgba(57, 57, 60, 0.7424);
}

.sticky-element {
  --sticky-height: 60px;
  --paddlenav-arrow-height: 36px;
  --paddlenav-arrows-offset: 128px;
  --paddlenav-right-offset: calc(var(--paddlenav-arrow-height) * -0.5);
}

.paddlenav,
.paddlenav ul {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paddlenav .paddlenav-arrow {
  background: var(--ze-paddlenav-background);
  color: var(--ze-paddlenav-arrow-color);
  display: block;
  width: var(--ze-paddlenav-diameter);
  height: var(--ze-paddlenav-diameter);
  border-radius: var(--ze-paddlenav-diameter);
  font-style: normal;
  overflow: hidden;
  position: absolute;
  text-align: center;
  line-height: 1;
  transition: background 100ms linear, color 100ms linear, opacity 100ms linear;
}
.paddlenav .paddlenav-arrow-next,
.paddlenav .paddlenav-arrow-previous {
  margin-top: calc(var(--ze-paddlenav-diameter) * -0.5);
  top: 50%;
}

html[dir=rtl] .paddlenav .paddlenav-arrow-next,
html[dir=rtl] .paddlenav .paddlenav-arrow-previous {
  transform: rotate(180deg);
}

.paddlenav .paddlenav-arrow-down,
.paddlenav .paddlenav-arrow-up {
  margin-left: calc(var(--ze-paddlenav-diameter) * -0.5);
  left: 50%;
}

.paddlenav .paddlenav-arrow svg path {
  fill: var(--ze-paddlenav-arrow-color);
  transition: fill 100ms linear;
}

.paddlenav .paddlenav-arrow:hover {
  background: var(--ze-paddlenav-background-hover);
  color: var(--ze-paddlenav-arrow-color-hover);
  text-decoration: none;
}
.paddlenav .paddlenav-arrow:hover svg path {
  fill: var(--ze-paddlenav-arrow-color-hover);
}
.paddlenav .paddlenav-arrow:active {
  background: var(--ze-paddlenav-background-active);
  color: var(--ze-paddlenav-arrow-color-active);
}
.paddlenav .paddlenav-arrow:active svg path {
  fill: var(--ze-paddlenav-arrow-color-active);
}
.paddlenav .paddlenav-arrow:focus-visible {
  outline: 2px solid var(--ze-focus-color, --zem-fill-blue);
  outline-offset: var(--ze-focus-offset-container, 3px);
}
.paddlenav .paddlenav-arrow.disabled,
.paddlenav .paddlenav-arrow:disabled {
  pointer-events: none;
  opacity: var(--ze-link-disabled-opacity, 0.42);
}
.paddlenav .paddlenav-arrow-previous {
  inset-inline-start: 18px;
}
.paddlenav .paddlenav-arrow-next {
  inset-inline-end: 18px;
}
.paddlenav .paddlenav-arrow-up {
  top: 18px;
}
.paddlenav .paddlenav-arrow-down {
  bottom: 18px;
}

.paddlenav-elevated {
  --ze-paddlenav-diameter: 56px;
}

.paddlenav-super {
  --ze-paddlenav-diameter: 90px;
}

.paddlenav-solid {
  --ze-paddlenav-background: rgb(232, 232, 237);
  --ze-paddlenav-background-hover: #ececf0;
  --ze-paddlenav-background-active: #dfdfe4;
}

.paddlenav-justified.paddlenav-outside .paddlenav-arrow-previous {
  inset-inline-start: 18px;
  inset-inline-start: calc(var(--ze-paddlenav-diameter) * -1 - 18px);
}
.paddlenav-justified.paddlenav-outside .paddlenav-arrow-next {
  inset-inline-end: 18px;
  inset-inline-end: calc(var(--ze-paddlenav-diameter) * -1 - 18px);
}

.paddlenav-side-aligned .paddlenav-arrow-next,
.paddlenav-side-aligned .paddlenav-arrow-previous {
  top: 100%;
  margin-top: calc(var(--ze-paddlenav-diameter) * -1 - 18px);
}
.paddlenav-side-aligned .paddlenav-arrow-previous {
  inset-inline-start: auto;
  inset-inline-end: calc(var(--ze-paddlenav-diameter) + 36px);
}
.paddlenav-side-aligned .paddlenav-arrow-next {
  inset-inline-end: 18px;
}
.paddlenav-side-aligned.paddlenav-outside .paddlenav-arrow-next, .paddlenav-side-aligned.paddlenav-outside .paddlenav-arrow-previous {
  top: 100%;
  margin-top: 18px;
}
.paddlenav-side-aligned.paddlenav-outside .paddlenav-arrow-previous {
  inset-inline-start: auto;
  inset-inline-end: calc(var(--ze-paddlenav-diameter) + 36px);
  inset-inline-end: calc(var(--ze-paddlenav-diameter) + 18px);
}
.paddlenav-side-aligned.paddlenav-outside .paddlenav-arrow-next {
  inset-inline-end: 18px;
  inset-inline-end: 0;
}

.paddlenav-centered .paddlenav-arrow-next,
.paddlenav-centered .paddlenav-arrow-previous {
  top: 100%;
  margin-top: calc(var(--ze-paddlenav-diameter) * -1 - 18px);
}
.paddlenav-centered .paddlenav-arrow {
  inset-inline-end: auto;
  inset-inline-start: auto;
}
.paddlenav-centered .paddlenav-arrow-previous {
  margin-inline-start: calc(var(--ze-paddlenav-diameter) * -1 - 9px);
}
.paddlenav-centered .paddlenav-arrow-next {
  margin-inline-start: 9px;
}
.paddlenav-centered.paddlenav-outside .paddlenav-arrow-next, .paddlenav-centered.paddlenav-outside .paddlenav-arrow-previous {
  top: 100%;
  margin-top: 18px;
}
.paddlenav-centered.paddlenav-outside .paddlenav-arrow {
  inset-inline-end: auto;
  inset-inline-start: auto;
}
.paddlenav-centered.paddlenav-outside .paddlenav-arrow-previous {
  margin-inline-start: calc(var(--ze-paddlenav-diameter) * -1 - 9px);
}
.paddlenav-centered.paddlenav-outside .paddlenav-arrow-next {
  margin-inline-start: 9px;
}

.with-paddlenav {
  position: relative;
}

html.no-touch .with-paddlenav-onhover .paddlenav-arrow {
  opacity: 0;
  transition: background 100ms linear, color 100ms linear, opacity 100ms linear;
}
html.no-touch .with-paddlenav-onhover .paddlenav-arrow:focus-visible,
html.no-touch .with-paddlenav-onhover:hover .paddlenav-arrow {
  opacity: 1;
}
html.no-touch .with-paddlenav-onhover .paddlenav-arrow.disabled,
html.no-touch .with-paddlenav-onhover .paddlenav-arrow:disabled {
  opacity: 0;
}

.with-paddlenav-visuallyhidden .paddlenav-arrow {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0 0 99.9% 99.9%);
}
.with-paddlenav-visuallyhidden .paddlenav-arrow:focus-visible, .with-paddlenav-visuallyhidden .paddlenav-arrow:hover {
  clip: auto;
  clip-path: none;
}

body {
  position: relative;
}

footer {
  --footer-background: var(--fds-fill-secondary);
  --footer-title-color: rgba(0, 0, 0, 0.8);
  --footer-text-color: rgba(0, 0, 0, 0.55);
  --footer-link-color: var(--zem-color);
  --footer-border-color: rgba(0, 0, 0, 0.2);
  --footer-space: 0.5rem;
  --footer-link: var(--footer-link-color);
  --footer-pipe-border: 1px solid var(--footer-pipe-color);
  --footer-directory-title-color: var(--footer-link);
  --footer-directory-title-color-hover: var(--footer-directory-title-color);
  --footer-section-border-color: var(--footer-border-color);
  background-color: var(--footer-background);
  width: 100%;
  padding: 0 calc(var(--footer-space) * 3);
  padding-bottom: calc(var(--footer-space) * 6);
}
@media only screen and (max-width: 734px) {
  footer {
    padding: 0 calc(var(--footer-space) * 3);
    padding-bottom: calc(var(--footer-space) * 3);
  }
}

#zem-globalfooter {
  max-width: 1680px;
  font-size: 12px;
  line-height: 1.3333733333;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: var(--footer-text-color);
  overflow: hidden;
  z-index: 1;
  margin: auto;
  direction: ltr;
}
#zem-globalfooter a {
  color: var(--footer-link-color);
  text-decoration: none;
  text-decoration: underline;
}
#zem-globalfooter .focus {
  color: var(--footer-directory-title-color);
}
#zem-globalfooter strong {
  font-size: 12px;
  font-weight: 600;
  color: var(--footer-directory-title-color);
  padding-bottom: 0.3em;
}
#zem-globalfooter .zem-gf-footer-call {
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--footer-border-color);
}
#zem-globalfooter .zem-gf-copyright {
  font-size: 12px;
}