.section-about {
  padding-top: var(--global-section-padding);
  padding-bottom: var(--global-section-padding);
}

.section-about-content {
  margin-top: 0;
  padding-bottom: var(--global-section-padding);
}

.as-container-column.as-banner {
  margin-top: 68px;
}

.as-container-column.as-banner .column-item {
  padding-bottom: 0;
}

.as-container-column.about-zem-bg {
  --about-background-color: var(--zem-background-color);
  background: var(--about-background-color);
}

.main > .as-container-column.as-columns-bg:first-child {
  margin-top: 0px;
}

.main[data-env=author] > .as-container-column.as-columns-bg:first-child {
  margin-top: 0px;
}

.as-imagegrid-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
@media only screen and (min-width: 1441px) {
  .as-imagegrid-wrapper {
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .as-imagegrid-wrapper {
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .as-imagegrid-wrapper {
    width: 87.5%;
  }
}

.as-imagegrid-wrapper.as-imagegrid--elevated {
  margin-top: 68px;
  row-gap: 68px;
}
@media only screen and (max-width: 1068px) {
  .as-imagegrid-wrapper.as-imagegrid--elevated {
    margin-top: 54px;
    row-gap: 54px;
  }
}
@media only screen and (max-width: 734px) {
  .as-imagegrid-wrapper.as-imagegrid--elevated {
    margin-top: 48px;
    row-gap: 48px;
  }
}

.as-imagegrid-wrapper.as-imagegrid--base {
  margin-top: 54px;
  row-gap: 54px;
}
@media only screen and (max-width: 1068px) {
  .as-imagegrid-wrapper.as-imagegrid--base {
    margin-top: 48px;
    row-gap: 48px;
  }
}
@media only screen and (max-width: 734px) {
  .as-imagegrid-wrapper.as-imagegrid--base {
    margin-top: 40px;
    row-gap: 40px;
  }
}

.as-imagegrid-wrapper.as-imagegrid--reduced {
  margin-top: 32px;
  row-gap: 32px;
}
@media only screen and (max-width: 1068px) {
  .as-imagegrid-wrapper.as-imagegrid--reduced {
    margin-top: 28px;
    row-gap: 28px;
  }
}
@media only screen and (max-width: 734px) {
  .as-imagegrid-wrapper.as-imagegrid--reduced {
    margin-top: 24px;
    row-gap: 24px;
  }
}

.as-imagegrid-wrapper.as-imagegrid--1up .as-imagegrid-item {
  padding: 0px;
}

.as-imagegrid-wrapper .as-imagegrid-item {
  padding: 0 10px;
}

.as-imagegrid-wrapper .as-imagegrid-item a,
.as-imagegrid-wrapper .as-imagegrid-item div {
  text-align: center;
  color: #1d1d1f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0 auto;
  max-width: 100%;
}

.as-imagegrid-wrapper .as-imagegrid-item span {
  word-wrap: break-word;
  width: 100%;
}

.as-imagegrid-wrapper .as-imagegrid-item .as-imagegrid-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

.as-imagegrid-wrapper.as-imagegrid--1up + .as-page-title,
.as-imagegrid-wrapper.as-imagegrid--1up + .as-section-title {
  margin-top: 32px;
}

@media only screen and (max-width: 1068px) {
  .as-imagegrid-wrapper.as-imagegrid--1up + .as-page-title,
  .as-imagegrid-wrapper.as-imagegrid--1up + .as-section-title {
    margin-top: 28px;
  }
}
@media only screen and (max-width: 734px) {
  .as-imagegrid-wrapper.as-imagegrid--1up + .as-page-title,
  .as-imagegrid-wrapper.as-imagegrid--1up + .as-section-title {
    margin-top: 24px;
  }
}
.as-imagegrid-wrapper.as-imagegrid--5up .as-imagegrid-item {
  width: 20%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.as-imagegrid-wrapper.as-imagegrid--7up .as-imagegrid-item {
  width: -webkit-calc(100% / 7);
  width: 14.2857142857%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.as-tile-wrapper .as-tile .as-tile-image {
  display: grid;
}

.as-tile-wrapper .as-tile .as-tile-image img {
  --width-large: 980px;
  --width-small: 480px;
  display: block;
  height: auto;
}

@media only screen and (min-width: 735px) {
  .as-tile-wrapper .as-tile .as-tile-image img {
    max-width: var(--width-large);
  }
}
@media only screen and (max-width: 734px) {
  .as-tile-wrapper .as-tile .as-tile-image img {
    max-width: var(--width-small);
  }
}
/* end as-imagegrid.scss */
.section-about-overview {
  padding-top: 18px;
  padding-bottom: 94px;
}
@media only screen and (max-width: 1068px) {
  .section-about-overview {
    padding-top: 14px;
    padding-bottom: 73px;
  }
}
@media only screen and (max-width: 734px) {
  .section-about-overview {
    padding-top: 28px;
    padding-bottom: 17px;
  }
}

/* as-pagetitle.scss */
.as-page-title {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  margin-top: 68px;
  word-wrap: break-word;
  text-align: center;
}
@media only screen and (min-width: 1441px) {
  .as-page-title {
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .as-page-title {
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .as-page-title {
    width: 87.5%;
  }
}
@media only screen and (max-width: 1068px) {
  .as-page-title {
    margin-top: 54px;
  }
}
@media only screen and (max-width: 734px) {
  .as-page-title {
    margin-top: 48px;
  }
}

.as-page-title.as-left {
  text-align: left;
}

.as-page-title.as-right {
  text-align: right;
}

.as-page-title.as-page-title--light h1,
.as-page-title.as-page-title--light p {
  color: var(--text-fill-secondary);
}

/* end as-pagetitle.scss */
/* as-sectiontitle.scss */
.as-section-title {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  margin-top: 68px;
  word-wrap: break-word;
  text-align: center;
}

@media only screen and (min-width: 1441px) {
  .as-section-title {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .as-section-title {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .as-section-title {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
.as-section-title__heading {
  color: var(---text-fill-secondary-rev);
}

.as-section-title__intro {
  color: var(---text-fill-secondary-rev);
}

.as-section-title.as-right {
  text-align: right;
}

.as-section-title.as-left {
  text-align: left;
}

.as-section-title--light .as-section-title__heading {
  color: var(---text-fill-secondary);
}

.as-section-title--light .as-section-title__intro {
  color: var(---text-fill-secondary);
}

.as-section-title .as-section-title__heading + .as-section-title__intro {
  margin-top: 0.8em;
}

/* end as-sectiontitle.scss */