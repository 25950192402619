.section.no-pad-top {
  padding-top: 0;
}

.section.no-pad,
.section.no-pad-bottom {
  padding-bottom: 0;
}

.section.no-pad {
  padding-top: 0;
}

.section.background-alt {
  background-color: var(--global-section-background-color-alt);
}

.section.background-alt.theme-dark,
.theme-dark .section.background-alt {
  background-color: var(--global-section-background-color-alt-dark);
}

.section-content-responsive {
  margin-inline-start: auto;
  margin-inline-end: auto;
  width: 87.5vw;
  max-width: 1680px;
}

.section-welcome {
  padding-top: 80px;
}
@media (max-width: 1068px) {
  .section-welcome {
    padding-top: 72px;
  }
}
@media (max-width: 734px) {
  .section-welcome {
    padding-top: 56px;
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  grid-gap: 28px;
  gap: 28px;
  padding-bottom: 80px;
  margin-top: -10px;
}
@media (min-width: 735px) {
  .section-header {
    align-items: flex-end;
  }
}
@media (max-width: 1068px) {
  .section-header {
    grid-gap: 24px;
    gap: 24px;
    padding-bottom: 64px;
  }
}
@media (max-width: 734px) {
  .section-header {
    flex-direction: column;
    padding-bottom: 48px;
    align-content: flex-start;
    grid-gap: 17px;
    gap: 17px;
    margin-top: -6px;
  }
}

.section-header .section-header-cta-container {
  position: relative;
  top: -4px;
}
@media (max-width: 1068px) {
  .section-header .section-header-cta-container {
    top: -2px;
  }
}
@media (max-width: 734px) {
  .section-header .section-header-cta-container {
    top: 0;
  }
}

@media (min-width: 735px) {
  .section-header .section-header-headline {
    max-width: 75%;
  }
}
.section-header .visuallyhidden + .section-header-headline {
  margin-top: 0;
}

.section-header-cta-list-wrapper {
  display: inline-block;
  flex-shrink: 0;
}

.section-header-cta-list {
  margin-inline-start: 0;
  display: flex;
  grid-gap: 28px;
  gap: 28px;
}
@media (max-width: 1068px) {
  .section-header-cta-list {
    grid-gap: 24px;
    gap: 24px;
  }
}
@media (max-width: 734px) {
  .section-header-cta-list {
    flex-wrap: wrap;
    grid-row-gap: 10px;
  }
}

.section-header-cta-item {
  list-style-type: none;
  display: inline;
}

.card-cta-modal-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  border-radius: var(--global-card-corner-radius);
  z-index: 1;
  width: 100%;
  height: 100%;
}
.card-cta-modal-button .card-cta-modal-button-icon {
  --scrim-background-color: rgb(51, 51, 54);
  --scrim-hover-background-color: rgb(55, 55, 58);
  --scrim-active-background-color: rgb(47, 47, 50);
  --icon-color: var(--fds-fill-gray-tertiary);
  --icon-interaction-color: var(--fds-fill);
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: 36px;
  width: 36px;
  outline: none;
  position: absolute;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  margin: 0;
  padding: 0;
  border: 0;
  justify-content: center;
  cursor: pointer;
  transition: background-color 100ms linear, color 100ms linear;
  background-color: var(--scrim-background-color);
  color: var(--icon-color);
}
.card-cta-modal-button .card-cta-modal-button-icon:hover {
  background-color: var(--scrim-hover-background-color);
  color: var(--icon-interaction-color);
}
.card-cta-modal-button .card-cta-modal-button-icon:active {
  background-color: var(--scrim-active-background-color);
  color: var(--icon-interaction-color);
}

.card-cta-modal-button .card-cta-modal-button-icon .card-cta-modal-button-small-icon {
  fill: currentColor;
  pointer-events: none;
}
.card-cta-modal-button .card-modal-button-small-icon {
  width: 18px;
  height: 18px;
}
.card-cta-modal-button .card-cta-button-small-icon {
  width: 36px;
  height: 36px;
}

.gallery {
  position: relative;
  margin-top: 0;
  padding-bottom: 83px;
  --gallery-side-padding: calc(
    50vw - min(1680px, var(--global-viewport-content-responsive)) / 2
  );
  --scroll-bar-width: 0px;
}
@media (max-width: 734px) {
  .gallery {
    padding-bottom: 78px;
  }
}

.gallery.no-paddlenav {
  padding-bottom: 0px;
}

.gallery ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: rgba(0, 0, 0, 0);
}

.gallery.gallery-align-center .scroll-container {
  scroll-padding: var(--gallery-side-padding);
}
.gallery.gallery-align-center .item-container {
  padding: 0 var(--gallery-side-padding);
}
.gallery.gallery-align-center .gallery-item {
  scroll-snap-align: center;
}

.gallery.gallery-align-start .scroll-container {
  scroll-padding: var(--gallery-side-padding);
}
.gallery.gallery-align-start .item-container {
  padding: 0 var(--gallery-side-padding);
}
.gallery.gallery-align-start .gallery-item {
  scroll-snap-align: start;
}

.gallery .scroll-container {
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  padding: 10px 0;
  margin: -10px 0;
}
.gallery .scroll-container::-webkit-scrollbar {
  display: none;
}

.gallery .item-container {
  --gallery-column-gap: 45px;
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: var(--gallery-column-gap);
  column-gap: var(--gallery-column-gap);
  grid-row-gap: 0px;
  row-gap: 0px;
  width: -moz-fit-content;
  width: fit-content;
}
@media (min-width: 1441px) {
  .gallery .item-container {
    --gallery-column-gap: 72px;
  }
}
@media (max-width: 1068px) {
  .gallery .item-container {
    --gallery-column-gap: 72px;
  }
}
@media (max-width: 734px) {
  .gallery .item-container {
    --gallery-column-gap: 45px;
  }
}

.gallery.remove-scrollbar-width .item-container {
  padding-left: var(--gallery-side-padding);
  padding-right: calc(var(--gallery-side-padding) - var(--scroll-bar-width));
}

.gallery .spec-rows-8 .item-container {
  grid-template-rows: repeat(16, auto);
}
.gallery .spec-rows-7 .item-container {
  grid-template-rows: repeat(15, auto);
}
.gallery .spec-rows-6 .item-container {
  grid-template-rows: repeat(14, auto);
}
.gallery .spec-rows-5 .item-container {
  grid-template-rows: repeat(13, auto);
}
.gallery .spec-rows-4 .item-container {
  grid-template-rows: repeat(12, auto);
}
.gallery .spec-rows-3 .item-container {
  grid-template-rows: repeat(11, auto);
}
.gallery .spec-rows-2 .item-container {
  grid-template-rows: repeat(10, auto);
}
.gallery .spec-rows-1 .item-container {
  grid-template-rows: repeat(9, auto);
}

.gallery .paddlenav {
  position: absolute;
  top: calc(100% - var(--paddlenav-offset));
  right: var(--gallery-side-padding);
  visibility: inherit;
}

html[dir=rtl] .gallery .paddlenav {
  display: flex;
  justify-content: flex-end;
  right: auto;
  left: var(--gallery-side-padding);
}

@media (max-width: 1068px) {
  .gallery .paddlenav {
    --paddlenav-offset: 59px;
  }
}
@media (max-width: 734px) {
  .gallery .paddlenav {
    --paddlenav-offset: 63px;
  }
}
.gallery .paddlenav.hide {
  visibility: hidden;
}
.gallery .paddlenav .sticky-element {
  position: relative;
  width: 0px;
  height: var(--sticky-height);
  z-index: 2;
}
.gallery .paddlenav .sticky-element .left-item {
  position: absolute;
  right: calc(var(--paddlenav-arrows-offset) + var(--paddlenav-right-offset));
  bottom: calc(var(--paddlenav-arrow-height) * 0.5);
}

html[dir=rtl] .gallery .paddlenav .sticky-element .left-item {
  right: auto;
  left: calc(var(--paddlenav-arrows-offset) + var(--paddlenav-right-offset));
}

.gallery .paddlenav .sticky-element .right-item {
  position: absolute;
  right: var(--paddlenav-right-offset);
  bottom: calc(var(--paddlenav-arrow-height) * 0.5);
}

html[dir=rtl] .gallery .paddlenav .sticky-element .right-item {
  right: auto;
  left: var(--paddlenav-right-offset);
}

html[dir=rtl] .gallery .paddlenav .sticky-element .scrim {
  left: calc(var(--gallery-side-padding) * -1);
}

.gallery .paddlenav .sticky-element .paddlenav-arrow {
  width: var(--paddlenav-arrow-height);
  height: var(--paddlenav-arrow-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

html[dir=rtl] .gallery .paddlenav .sticky-element .paddlenav-arrow {
  transform: rotate(180deg);
}

.gallery .paddlenav .sticky-element .paddlenav-arrow svg {
  pointer-events: none;
  width: 100%;
}

html.touch .gallery .paddlenav .sticky-element .paddlenav-arrow:disabled {
  opacity: 0.42;
}
html.touch .gallery .paddlenav .sticky-element .paddlenav-arrow:hover {
  --ze-paddlenav-arrow-color-hover: var(--ze-paddlenav-arrow-color);
  --ze-paddlenav-background-hover: var(--ze-paddlenav-background);
}

.gallery .paddlenav .sticky-element .paddlenav-arrow::after {
  font-size: var(--large-title);
  padding-inline-start: 0;
}
.gallery .paddlenav .sticky-element .paddlenav-arrow .scrim {
  display: none;
}

.staggered-start .gallery .scroll-container {
  overflow: hidden;
}

.staggered-start .gallery .paddlenav .paddlenav-arrow {
  pointer-events: none;
  opacity: 0.42;
}

.theme-dark .staggered-start .gallery .paddlenav {
  opacity: 0.36;
}

.staggered-end .gallery .scroll-container {
  overflow: scroll;
}

.section-consider {
  padding-top: var(--global-section-padding);
  padding-bottom: var(--global-section-padding);
}
.section-consider .section-intro-copy {
  font-weight: 400;
}
.section-consider .gallery .item-container {
  --gallery-column-gap: 20px;
}

.grid-item .essential-card .card-modifier {
  --gallery-side-padding: calc(
    50vw - min(1680px, var(--global-viewport-content-responsive)) / 2
  );
  width: calc(50vw - var(--gallery-column-gap) * 0.5 - var(--gallery-side-padding));
}

@media (max-width: 734px) {
  .essential-card .card-modifier {
    width: 260px;
    min-height: 480px;
  }
}
.card-viewport-content {
  height: 100%;
}

.essential-card-content {
  padding-top: 24px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
@media (max-width: 1068px) {
  .essential-card-content {
    padding-top: var(--global-card-padding);
  }
}
@media (max-width: 734px) {
  .essential-card-content {
    padding-top: var(--global-card-padding);
  }
}
.essential-card-content.text-bottom {
  padding-bottom: 24px;
  padding-top: 0;
  flex-direction: column-reverse;
}
@media (max-width: 1068px) {
  .essential-card-content.text-bottom {
    padding-bottom: var(--global-card-padding);
  }
}
@media (max-width: 734px) {
  .essential-card-content.text-bottom {
    padding-bottom: var(--global-card-padding);
  }
}
.essential-card-content .essential-card-block-link {
  color: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  text-decoration: none;
  top: 0;
  width: 100%;
  z-index: 2;
}
.essential-card-content .essential-card-text-container {
  position: relative;
  z-index: 2;
  max-width: 400px;
}
@media (min-width: 1441px) {
  .essential-card-content .essential-card-text-container {
    max-width: 480px;
  }
}
@media (max-width: 1068px) {
  .essential-card-content .essential-card-text-container {
    max-width: 100%;
  }
}
.essential-card-content .essential-card-badge {
  font-size: 12px;
  line-height: 1.3333733333;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
  padding-bottom: 0;
  padding-top: 0;
  display: block;
}
.essential-card-content .essential-card-header {
  margin-bottom: 12px;
}
.essential-card-content .essential-card-copy {
  margin: 0 0 0.8em;
}
.essential-card-content .essential-card-image {
  position: relative;
  z-index: 1;
}
.essential-card-content .essential-card-image.image-top-left {
  left: calc(var(--global-card-padding) * -1);
  top: calc(var(--global-card-padding) * -1);
  align-self: start;
}
.essential-card-content .essential-card-image.image-top-center {
  top: calc(var(--global-card-padding) * -1);
}
.essential-card-content .essential-card-image.image-top-right {
  right: calc(var(--global-card-padding) * -1);
  top: calc(var(--global-card-padding) * -1);
  align-self: end;
}
.essential-card-content .essential-card-image.image-bottom-left {
  bottom: calc(var(--global-card-padding) * -1);
  left: calc(var(--global-card-padding) * -1);
  align-self: start;
}
.essential-card-content .essential-card-image.image-bottom-center {
  bottom: calc(var(--global-card-padding) * -1);
}
.essential-card-content .essential-card-image.image-bottom-right {
  bottom: calc(var(--global-card-padding) * -1);
  right: calc(var(--global-card-padding) * -1);
  align-self: end;
}
.essential-card-content .responsive-image {
  position: relative;
  display: flex;
}

html.text-zoom .project-card {
  -webkit-nbsp-mode: space;
  word-break: break-word;
}
html.text-zoom:hover {
  border: none;
}

.project-card {
  --bs-card-bg: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.project-card .card-modifier {
  background: var(--global-card-background-color);
  border-radius: var(--global-card-corner-radius);
  box-sizing: border-box;
  filter: drop-shadow(var(--fds-box-shadow));
  box-shadow: var(--fds-box-shadow);
  clip-path: inset(1px 1px 1px 1px round var(--global-card-corner-radius));
  height: 100%;
  min-height: 560px;
  overflow: hidden;
  position: relative;
  will-change: transform;
}
@media (min-width: 1441px) {
  .project-card .card-modifier {
    min-height: 620px;
  }
}
@media (max-width: 734px) {
  .project-card .card-modifier {
    min-width: 260px;
  }
}
.project-card .card-modifier.card-padding {
  padding: var(--global-card-padding);
}
@media (max-width: 734px) {
  .project-card .card-modifier.full-bleed-small {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 734px) {
  .project-card .card-modifier.full-bleed-small .card-viewport-content {
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 980px;
  }
}
@media (max-width: 734px) and (min-width: 1441px) {
  .project-card .card-modifier.full-bleed-small .card-viewport-content {
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 980px;
  }
}
@media (max-width: 734px) and (max-width: 1068px) {
  .project-card .card-modifier.full-bleed-small .card-viewport-content {
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 692px;
  }
}
@media (max-width: 734px) {
  .project-card .card-modifier.full-bleed-small .card-viewport-content {
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 87.5%;
  }
}

.project-card .card-modifier.theme-dark,
.theme-dark .project-card .card-modifier {
  background: rgb(36, 36, 38);
}

.project-card .card-modifier.theme-dark p,
.theme-dark .project-card .card-modifier p {
  color: var(--text-fill);
}

.background-alt .project-card .card-modifier {
  background: var(--fds-white);
}

.background-alt .project-card .card-modifier.theme-dark,
.theme-dark .background-alt .project-card .card-modifier {
  background: var(--fds-black);
}

.background-alt .project-card .card-modifier.theme-dark p,
.theme-dark .background-alt .project-card .card-modifier p {
  color: var(--text-fill);
}

.project-card .card-modifier.fixed-width {
  width: 372px;
}
@media (min-width: 1441px) {
  .project-card .card-modifier.fixed-width {
    width: 405px;
  }
}
@media (max-width: 1068px) {
  .project-card .card-modifier.fixed-width {
    width: 344px;
  }
}
@media (max-width: 734px) {
  .project-card .card-modifier.fixed-width {
    width: 260px;
  }
}
.project-card .card-modifier.fixed-width-network {
  width: 372px;
}
@media (min-width: 1441px) {
  .project-card .card-modifier.fixed-width-network {
    width: 504px;
  }
}
@media (max-width: 1068px) {
  .project-card .card-modifier.fixed-width-network {
    width: 344px;
  }
}
@media (max-width: 734px) {
  .project-card .card-modifier.fixed-width-network {
    width: 260px;
  }
}
.project-card .card-modifier.has-trigger-button {
  padding-inline-end: 77px;
  padding-bottom: 57px;
}
@media (max-width: 1068px) {
  .project-card .card-modifier.has-trigger-button {
    padding-inline-end: 73px;
  }
}

.gallery .card-set {
  --gallery-card-set-height: 100%;
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: var(--gallery-column-gap);
  column-gap: var(--gallery-column-gap);
  grid-row-gap: 0px;
  row-gap: 0px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  list-style: none;
}
.gallery .card-set .project-card,
.gallery .card-set .card-container {
  height: var(--gallery-card-set-height);
}

.feature-card {
  transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
}

html.no-touch .feature-card:hover {
  transform: scale(1.0161290323);
}
@media (min-width: 1441px) {
  html.no-touch .feature-card:hover {
    transform: scale(1.0148148148);
  }
}
@media (max-width: 1068px) {
  html.no-touch .feature-card:hover {
    transform: scale(1.0174418605);
  }
}
@media (max-width: 734px) {
  html.no-touch .feature-card:hover {
    transform: scale(1.0230769231);
  }
}

.feature-card .card-modifier {
  max-height: 744px;
  min-height: 680px;
}

html.text-zoom .feature-card .card-modifier .feature-card-copy .feature-card-headline,
html.text-zoom .feature-card .card-modifier .feature-card-copy .feature-card-label {
  background-color: rgba(255, 255, 255, 0.92);
}

html.text-zoom .feature-card .card-modifier.theme-dark .feature-card-copy .feature-card-headline,
html.text-zoom .feature-card .card-modifier.theme-dark .feature-card-copy .feature-card-label,
html.text-zoom .theme-dark .feature-card .card-modifier .feature-card-copy .feature-card-headline,
html.text-zoom .theme-dark .feature-card .card-modifier .feature-card-copy .feature-card-label {
  background-color: rgba(0, 0, 0, 0.88);
}

.feature-card .card-modifier {
  max-height: 744px;
  min-height: 680px;
}
@media (min-width: 1441px) {
  .feature-card .card-modifier {
    max-height: 804px;
    min-height: 740px;
  }
}
@media (max-width: 1068px) {
  .feature-card .card-modifier {
    max-height: 684px;
    min-height: 628px;
  }
}
@media (max-width: 734px) {
  .feature-card .card-modifier {
    max-height: 630px;
    min-height: 480px;
  }
}
.feature-card .feature-card-copy {
  padding-bottom: 523px;
}
@media (min-width: 1441px) {
  .feature-card .feature-card-copy {
    padding-bottom: 583px;
  }
}
@media (max-width: 1068px) {
  .feature-card .feature-card-copy {
    padding-bottom: 490px;
  }
}
@media (max-width: 734px) {
  .feature-card .feature-card-copy {
    padding-bottom: 300px;
  }
}

.feature-card-content .feature-card-image-container {
  position: absolute;
  margin: 0;
}
.feature-card-content .feature-card-image-container.image-top-left {
  left: 0;
  top: 0;
}
.feature-card-content .feature-card-image-container.image-top-center {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.feature-card-content .feature-card-image-container.image-top-right {
  right: 0;
  top: 0;
}
.feature-card-content .feature-card-image-container.image-bottom-left {
  bottom: 0;
  left: 0;
}
.feature-card-content .feature-card-image-container.image-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.feature-card-content .feature-card-image-container.image-bottom-right {
  bottom: 0;
  right: 0;
}
.feature-card-content .feature-card-image-container.image-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.feature-card-content .feature-card-image-container .feature-card-image {
  position: relative;
  display: flex;
}
.feature-card-content .feature-card-copy {
  position: relative;
  z-index: 2;
}
.feature-card-content .feature-card-label + .feature-card-headline {
  margin-top: 8px;
}

.banner-card {
  --copy-column-v-padding: 56px;
  --copy-max-width: 100%;
  box-sizing: border-box;
  background: var(--global-card-background-color);
  border-radius: var(--global-card-corner-radius);
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
  box-shadow: var(--fds-box-shadow);
}
@media (max-width: 734px) {
  .banner-card {
    --copy-column-v-padding: 48px;
  }
}

.banner-card.theme-dark,
.theme-dark .banner-card {
  background: rgb(36, 36, 38);
  color: var(--text-fill);
}

@media (max-width: 1068px) {
  .section-banner .banner-card-banner {
    height: var(--max-height);
  }
}
.section-banner {
  padding-top: var(--global-section-padding);
  padding-bottom: var(--global-section-padding);
}
.section-banner .section-intro-copy {
  font-weight: 400;
}

@media (inverted-colors) and (inverted-colors) {
  .section-banner .banner-card-headline {
    filter: invert(1);
  }
}
@media (min-width: 1441px) {
  html.no-touch .banner-card-1-up:hover {
    transform: scale(1.0035714286);
  }
}
html.no-touch .banner-card-1-up:hover {
  transform: scale(1.0047619048);
}
@media (max-width: 1068px) {
  html.no-touch .banner-card-1-up:hover {
    transform: scale(1.0064171123);
  }
}
@media (max-width: 734px) {
  html.no-touch .banner-card-1-up:hover {
    transform: scale(1.0093312597);
  }
}
html.no-touch .banner-card-2-up:hover {
  transform: scale(1.0096774194);
}
@media (min-width: 1441px) {
  html.no-touch .banner-card-2-up:hover {
    transform: scale(1.0072289157);
  }
}
@media (max-width: 1068px) {
  html.no-touch .banner-card-2-up:hover {
    transform: scale(1.0131147541);
  }
}
@media (max-width: 734px) {
  html.no-touch .banner-card-2-up:hover {
    transform: scale(1.0192616372);
  }
}

.banner-card .banner-card-row {
  --h-padding: 32px;
  --copy-column-v-margin: 328px;
}
@media (min-width: 1441px) {
  .banner-card .banner-card-row {
    --copy-column-v-margin: 393px;
  }
}
@media (max-width: 1068px) {
  .banner-card .banner-card-row {
    --h-padding: 28px;
    --copy-column-v-margin: 347px;
  }
}
@media (max-width: 734px) {
  .banner-card .banner-card-row {
    --h-padding: 24px;
    --copy-column-v-margin: 258px;
  }
}

.banner-card .banner-card-copy-column {
  padding: var(--copy-column-v-padding) var(--h-padding) 0;
  z-index: 1;
}

.banner-card.banner-card-text-row-placement-bottom .banner-card-image-column {
  margin-bottom: var(--copy-column-v-margin);
}

.banner-card.banner-card-text-row-placement-bottom .banner-card-image-column .banner-card-image {
  margin-top: auto;
}

.banner-card.banner-card-text-row-placement-top .banner-card-copy-column {
  margin-bottom: var(--copy-column-v-margin);
}

.banner-card.banner-card-image-type-inline.banner-card-text-row-placement-bottom .banner-card-image-column .banner-card-image {
  margin-bottom: calc(var(--copy-column-v-margin) * -1);
}

.banner-card.banner-card-image-type-inline.banner-card-text-row-placement-top .banner-card-image-column .banner-card-image {
  margin-top: calc(var(--copy-column-v-margin) * -1);
}

@media (min-width: 1069px) {
  .banner-card.banner-card-1-up.banner-card-image-type-inline .banner-card-image-column .banner-card-image {
    margin: 0 auto;
  }
}
.banner-card .banner-card-badge {
  display: block;
  margin-bottom: 8px;
}

.banner-card .banner-card-copy,
.banner-card .banner-card-headline {
  max-width: var(--copy-max-width);
  margin-left: auto;
  margin-right: auto;
}

.banner-card .banner-card-copy {
  margin-top: 1.1em;
}
@media (min-width: 1441px) {
  .banner-card .banner-card-copy {
    margin-top: 0.8em;
  }
}
@media (max-width: 1068px) {
  .banner-card .banner-card-copy {
    margin-top: 0.7em;
  }
}
@media (max-width: 734px) {
  .banner-card .banner-card-copy {
    margin-top: 0.8em;
  }
}

@media (max-width: 734px) {
  .banner-card .banner-card-image-column {
    padding: 0 var(--h-padding);
  }
}
@media (min-width: 1069px) {
  .banner-card-1-up {
    text-align: left;
  }
}
@media (min-width: 1069px) {
  .banner-card-1-up .banner-card-row {
    --h-padding: 6.25%;
    padding-inline-start: var(--h-padding);
  }
}
@media (min-width: 1069px) {
  .banner-card-1-up .banner-card-row .banner-card-copy-column {
    padding: var(--copy-column-v-padding) 0;
    margin: 0;
  }
}
@media (min-width: 1069px) {
  .banner-card-1-up:not(.banner-card-text-alignment-center) .banner-card-copy,
  .banner-card-1-up:not(.banner-card-text-alignment-center) .banner-card-headline {
    margin-inline-start: 0;
  }
}
@media (min-width: 1069px) {
  .banner-card-1-up .banner-card-copy-column + .banner-card-image-column {
    margin-bottom: 0;
  }
}
.banner-card-text-row-placement-bottom .banner-card-copy-column {
  padding-top: 0;
  padding-bottom: var(--copy-column-v-padding);
}

@media (min-width: 1069px) {
  .banner-card-text-alignment-center {
    text-align: center;
  }
}
.banner-card .banner-card-active-area {
  display: none;
}
.banner-card .banner-card-active-area:hover {
  cursor: pointer;
}

.banner-card-ctas-primary-neutral a:not(.button),
.banner-card-ctas-secondary-neutral a:not(.button) {
  color: var(--ze-body-text-color);
}

.banner-card.has-ctas .banner-card-active-area {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.banner-card.has-ctas .banner-card-row {
  pointer-events: none;
}
.banner-card.has-ctas .banner-card-ctas {
  pointer-events: all;
}

.banner-card-ctas {
  display: inline-flex;
  align-items: center;
  grid-gap: 33px;
  gap: 33px;
  margin-top: 31px;
}
@media (max-width: 734px) {
  .banner-card-ctas {
    margin-top: 33px;
    grid-gap: 27px;
    gap: 27px;
  }
}
.banner-card-ctas.banner-card-ctas-stacked {
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
}
@media (max-width: 734px) {
  .banner-card-ctas.banner-card-ctas-stacked {
    grid-gap: 14px;
    gap: 14px;
  }
}

@media (min-width: 735px) {
  .banner-card .banner-card-button {
    --ze-button-padding-horizontal: 22px;
    --ze-button-padding-vertical: 12px;
    --ze-button-min-width-basis: 70px;
    --ze-button-margin-horizontal: 18px;
    --ze-button-margin-vertical: 18px;
    font-size: 17px;
    line-height: 1.1764805882;
    font-weight: 400;
    letter-spacing: -0.022em;
    text-decoration: none;
  }
}
.banner-card .banner-card-button .icon-copy a {
  text-decoration: none;
  color: black;
}

.banner-card .banner-card-button:hover .icon-copy {
  text-decoration: none;
}

@media (min-width: 1069px) {
  .banner-card-1-up.banner-card-image-type-inline .banner-card-image-column {
    justify-content: start;
  }
}
.banner-card .banner-card-button .icon-copy a {
  text-decoration: none;
  color: black;
}

.banner-card.banner-card-image-type-background .banner-card-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.banner-card.banner-card-image-type-background .banner-card-image img {
  height: 100%;
  width: 100%;
  object-fit: none;
  object-position: center bottom;
}

@media (min-width: 1069px) {
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-center-top img {
    object-position: center top;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-center-bottom img {
    object-position: center bottom;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-center-center img {
    object-position: center;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-top img {
    object-position: left top;
  }
  html[dir=rtl] .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-top img {
    object-position: right top;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-bottom img {
    object-position: left bottom;
  }
  html[dir=rtl] .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-bottom img {
    object-position: right bottom;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-center img {
    object-position: left center;
  }
  html[dir=rtl] .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-center img {
    object-position: right center;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-top img {
    object-position: right top;
  }
  html[dir=rtl] .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-top img {
    object-position: left top;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-bottom img {
    object-position: right bottom;
  }
  html[dir=rtl] .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-bottom img {
    object-position: left bottom;
  }
  .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-center img {
    object-position: right center;
  }
  html[dir=rtl] .banner-card-1-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-center img {
    object-position: left center;
  }
}
@media (min-width: 1069px) {
  .banner-card-1-up.banner-card-image-type-background .banner-card-image img {
    object-position: center center;
  }
}
.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-center-top img {
  object-position: center top;
}
.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-center-center img {
  object-position: center;
}
.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-top img {
  object-position: left top;
}

html[dir=rtl] .banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-top img {
  object-position: right top;
}

.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-bottom img {
  object-position: left bottom;
}

html[dir=rtl] .banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-bottom img {
  object-position: right bottom;
}

.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-center img {
  object-position: left center;
}

html[dir=rtl] .banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-start-center img {
  object-position: right center;
}

.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-top img {
  object-position: right top;
}

html[dir=rtl] .banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-top img {
  object-position: left top;
}

.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-bottom img {
  object-position: right bottom;
}

html[dir=rtl] .banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-bottom img {
  object-position: left bottom;
}

.banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-center img {
  object-position: right center;
}

html[dir=rtl] .banner-card-2-up.banner-card-image-type-background .banner-card-image.background-image-position-large-end-center img {
  object-position: left center;
}

.banner-card.banner-card-image-type-background .banner-card-image.background-image-fit-cover img {
  object-fit: cover;
}
.banner-card.banner-card-image-type-background .banner-card-image.background-image-fit-contain img {
  object-fit: contain;
}

@media (max-width: 1068px) {
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-center-top img {
    object-position: center top;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-center-bottom img {
    object-position: center bottom;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-center-center img {
    object-position: center;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-start-top img {
    object-position: left top;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-start-top img {
    object-position: right top;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-start-bottom img {
    object-position: left bottom;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-start-bottom img {
    object-position: right bottom;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-start-center img {
    object-position: left center;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-start-center img {
    object-position: right center;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-end-top img {
    object-position: right top;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-end-top img {
    object-position: left top;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-end-bottom img {
    object-position: right bottom;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-end-bottom img {
    object-position: left bottom;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-end-center img {
    object-position: right center;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-medium-end-center img {
    object-position: left center;
  }
}
@media (max-width: 734px) {
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-center-top img {
    object-position: center top;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-center-bottom img {
    object-position: center bottom;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-center-center img {
    object-position: center;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-start-top img {
    object-position: left top;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-start-top img {
    object-position: right top;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-start-bottom img {
    object-position: left bottom;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-start-bottom img {
    object-position: right bottom;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-start-center img {
    object-position: left center;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-start-center img {
    object-position: right center;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-end-top img {
    object-position: right top;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-end-top img {
    object-position: left top;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-end-bottom img {
    object-position: right bottom;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-end-bottom img {
    object-position: left bottom;
  }
  .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-end-center img {
    object-position: right center;
  }
  html[dir=rtl] .banner-card.banner-card-image-type-background .banner-card-image.background-image-position-small-end-center img {
    object-position: left center;
  }
}
@media (max-width: 734px) {
  .banner-card-image {
    width: 100%;
  }
  .banner-card-image img {
    display: block;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}
.banner-card {
  --min-height: 580px;
  --max-height: 680px;
  --min-width: 0px;
  display: flex;
  min-height: var(--min-height);
  max-height: var(--max-height);
  min-width: var(--min-width);
}
@media (min-width: 1441px) {
  .banner-card {
    --min-height: 640px;
    --max-height: 740px;
  }
}
@media (max-width: 734px) {
  .banner-card {
    --min-height: 480px;
    --max-height: 560px;
  }
}
.banner-card .banner-card-row {
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

@media (min-width: 1069px) {
  .banner-card .banner-card-copy-column {
    --width: 400px;
    width: var(--width);
    flex-shrink: 0;
  }
}
@media (min-width: 1069px) and (min-width: 1441px) {
  .banner-card .banner-card-copy-column {
    --width: 480px;
  }
}
@media (max-width: 1068px) {
  .banner-card .banner-card-copy-column {
    --width: 600px;
    max-width: var(--width);
  }
}
.banner-card .banner-card-image-column {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-width: 1069px) {
  .banner-card-1-up {
    --height: 480px;
    height: var(--height);
    align-items: center;
    min-height: 0;
    max-height: none;
  }
}
@media (min-width: 1069px) and (min-width: 1441px) {
  .banner-card-1-up {
    --height: 580px;
  }
}
.banner-card-1-up .banner-card-row {
  box-sizing: border-box;
}
@media (min-width: 1069px) {
  .banner-card-1-up .banner-card-row {
    align-items: center;
    flex-direction: row;
  }
}

.banner-card-2-up {
  width: 50%;
}
@media (max-width: 734px) {
  .banner-card-2-up {
    --min-width: 304px;
  }
}

@media (min-width: 1069px) {
  .banner-card-text-column-placement-end.banner-card-1-up {
    direction: rtl;
  }
}
@media (min-width: 1069px) {
  .banner-card-text-column-placement-end.banner-card-1-up .banner-card-copy-column {
    direction: ltr;
  }
}
@media (max-width: 1068px) {
  .banner-card-text-row-placement-bottom.banner-card-1-up .banner-card-row {
    flex-direction: column-reverse;
  }
}
.banner-card-text-row-placement-bottom.banner-card-2-up .banner-card-row {
  flex-direction: column-reverse;
}

@media (min-width: 1069px) {
  html[dir=rtl] .banner-card-1-up {
    text-align: right;
  }
}
@media (min-width: 1069px) {
  html[dir=rtl] .banner-card-text-column-placement-end.banner-card-1-up {
    direction: ltr;
  }
}
@media (min-width: 1069px) {
  html[dir=rtl] .banner-card-text-column-placement-end.banner-card-1-up .banner-card-copy-column {
    direction: rtl;
  }
}
@media (min-width: 1069px) {
  html[dir=rtl] .banner-card-text-alignment-center {
    text-align: center;
  }
}
.banner-card-headline {
  font-size: var(--large-title);
  line-height: 1.125;
  font-weight: 600;
  letter-spacing: 0.004em;
}
@media (max-width: 1068px) {
  .banner-card-headline {
    font-size: var(--large-title);
    line-height: 1.1666666667;
    letter-spacing: 0.009em;
  }
}
@media (max-width: 734px) {
  .banner-card-headline {
    font-size: var(--title-1);
    line-height: 1.1 0.1;
    letter-spacing: 0.011em;
  }
}

@media (max-width: 1068px) {
  .section-banner .banner-card-banner {
    height: var(--max-height);
  }
}
@media (inverted-colors) and (inverted-colors) {
  .section-banner .banner-card-headline {
    filter: invert(1);
  }
}
.section-work-network {
  padding-top: var(--global-section-padding);
  padding-bottom: var(--global-section-padding);
}
.section-work-network .section-intro-copy {
  font-weight: 400;
}