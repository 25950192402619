@charset "UTF-8";
html.reduced-motion .tile-button {
  transition: none;
}
html.reduced-motion .tile-overlay-content {
  transition: none;
}
html.reduced-motion .tile-overlay-copy {
  transform: translateY(0);
  transition: none;
}

:root {
  --section-intro-padding-top: 100px;
  --section-intro-padding-bottom: 40px;
  --headline-descender-height: 11px;
  --headline-descender-offset: calc(var(--headline-descender-height) * -1);
  --cta-descender-height: 8px;
  --cta-descender-offset: calc(var(--cta-descender-height) * -1);
  --cta-margin-top: 21px;
}
@media only screen and (max-width: 1068px) {
  :root {
    --section-intro-padding-top: 92px;
    --section-intro-padding-bottom: 35px;
    --headline-descender-height: 8px;
    --cta-descender-height: 7px;
    --cta-margin-top: 23px;
  }
}
@media only screen and (max-width: 734px) {
  :root {
    --section-intro-padding-top: 73px;
    --section-intro-padding-bottom: 30px;
    --headline-descender-height: 7px;
    --cta-margin-top: 20px;
  }
}

.section-intro {
  box-sizing: border-box;
  max-width: calc(var(--grid-max-width) + var(--grid-gutter) * 2);
  margin-left: auto;
  margin-right: auto;
  padding: var(--section-intro-padding-top) calc(var(--tile-padding-right) + var(--grid-gutter)) var(--section-intro-padding-bottom) calc(var(--tile-padding-left) + var(--grid-gutter));
}
@media only screen and (max-width: 374px) {
  .section-intro {
    padding-left: var(--tile-padding-left);
    padding-right: var(--tile-padding-right);
  }
}
.section-intro.section-intro-no-pad-top {
  padding-top: 0;
}
.section-intro .section-intro-headline {
  color: var(--tile-overlay-background-color, var(--section-color));
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: var(--headline-descender-offset);
}
.section-intro .section-intro-violator {
  margin-top: 27px;
}
.section-intro .section-intro-cta {
  margin-top: var(--cta-margin-top);
  margin-bottom: var(--cta-descender-offset);
}

@media only screen and (min-width: 735px) {
  .tile-body-blockquote-fullbleed .tile-body-blockquote {
    padding-left: 0;
    padding-right: 0;
  }
}
.tile-body-blockquote-fullbleed .grid {
  width: 100%;
}
@media only screen and (max-width: 734px) {
  .tile-body-blockquote-fullbleed .grid {
    --grid-gutter: 0;
  }
}
@media only screen and (min-width: 735px) {
  .tile-body-blockquote-fullbleed .blockquote-content {
    align-self: center;
    position: relative;
  }
}
@media only screen and (min-width: 1069px) {
  .tile-body-blockquote-fullbleed .blockquote-content {
    left: 84px;
    grid-column-start: 1;
    grid-column-end: 6;
  }
}
@media only screen and (min-width: 735px) and (max-width: 1068px) {
  .tile-body-blockquote-fullbleed .blockquote-content {
    left: -20px;
    grid-column-start: 2;
    grid-column-end: 6;
  }
}
@media only screen and (max-width: 734px) {
  .tile-body-blockquote-fullbleed .blockquote-content {
    --typographyBaseline: 6px;
    padding-bottom: calc(var(--tile-body-padding-top) - var(--typographyBaseline));
  }
}

.tile-body-blockquote-fullbleed .asset-content {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 735px) {
  .tile-body-blockquote-fullbleed .asset-content {
    grid-column-start: 6;
    grid-column-end: 13;
  }
}

@media only screen and (min-width: 1069px) {
  .tile-body-blockquote-fullbleed .tile-blockquote {
    max-width: 100%;
  }
}
@media only screen and (min-width: 735px) and (max-width: 1068px) {
  .tile-body-blockquote-fullbleed .tile-blockquote {
    max-width: 324px;
    min-width: 250px;
  }
}
@media only screen and (max-width: 1068px) {
  .tile-body-blockquote-fullbleed-large .tile-body-blockquote {
    padding-top: 0;
  }
  .tile-body-blockquote-fullbleed-large .tile-body-blockquote .tile-blockquote .typo-blockquote-copy {
    font-size: 19px;
    line-height: 1.4211026316;
    font-weight: 600;
    letter-spacing: 0.012em;
    margin-top: 0.3em;
  }
}
@media only screen and (max-width: 1068px) and (max-width: 1068px) {
  .tile-body-blockquote-fullbleed-large .tile-body-blockquote .tile-blockquote .typo-blockquote-copy {
    font-size: var(--body);
    line-height: 1.4705882353;
    font-weight: 600;
    letter-spacing: -0.022em;
  }
}
@media only screen and (min-width: 1069px) {
  .tile-body-blockquote-fullbleed-large .tile-body-blockquote {
    padding-left: 0;
    padding-right: 0;
  }
}
.tile-body-blockquote-fullbleed-large .grid {
  width: 100%;
}
@media only screen and (max-width: 1068px) {
  .tile-body-blockquote-fullbleed-large .grid {
    --grid-gutter: 0;
  }
}
@media only screen and (min-width: 1069px) {
  .tile-body-blockquote-fullbleed-large .blockquote-content {
    grid-column-start: 1;
    grid-column-end: 6;
    align-self: center;
    position: relative;
    left: 84px;
  }
}
@media only screen and (max-width: 1068px) {
  .tile-body-blockquote-fullbleed-large .blockquote-content {
    --typographyBaseline: 6px;
    padding-bottom: calc(var(--tile-body-padding-top) - var(--typographyBaseline));
  }
}
.tile-body-blockquote-fullbleed-large .asset-content {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 1069px) {
  .tile-body-blockquote-fullbleed-large .asset-content {
    grid-column-start: 6;
    grid-column-end: 13;
  }
}
@media only screen and (min-width: 1069px) {
  .tile-body-blockquote-fullbleed-large .tile-blockquote {
    max-width: 370px;
  }
}

.grid-wrapper {
  background-color: var(--grid-background-color);
}

.grid {
  max-width: var(--grid-max-width);
}
@media only screen and (max-width: 374px) {
  .grid {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid-item.will-change .tile {
  transform: translateZ(0);
}
.grid-item.will-change .tile-button {
  will-change: transform;
}

.grid-item.will-change .tile-content {
  transform: translateZ(0);
}
.grid-item.tile-theme-dark .tile-content .tile-body,
.grid-item.tile-theme-dark .tile-content .tile-copy,
.grid-item.tile-theme-dark .tile-content .tile-headline {
  color: var(--text-fill);
}
.grid-item.tile-theme-dark .tile-button {
  background-color: var(--fds-fill);
}
.grid-item.tile-theme-dark .tile-button {
  color: var(--tile-overlay-background-color);
}
.grid-item.tile-theme-dark .tile-button .tile-overlay-toggle:checked ~ .tile-overlay .tile-button {
  color: var(--tile-overlay-background-color);
}
.grid-item.with-bg-image .blockquote-content,
.grid-item.with-bg-image .tile-blockquote,
.grid-item.with-bg-image .tile-header {
  z-index: 1;
  position: relative;
}
.grid-item.with-bg-image .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
}
.grid-item.no-pad-inline .tile-body {
  padding-inline: 0;
}

.grid-item .blockquote-icon {
  margin-block-end: 30px;
}
@media only screen and (max-width: 1068px) {
  .grid-item .blockquote-icon {
    margin-block-end: 25px;
  }
}
@media only screen and (max-width: 734px) {
  .grid-item .blockquote-icon {
    margin-block-end: 20px;
  }
}

html.text-zoom .grid-item .tile-overlay-toggle ~ .tile-overlay .tile-overlay-copy {
  max-width: none;
}

html.firefox div[data-component-list~=HardwareZoom] glass-screen::before {
  content: "";
  background: var(--fds-black);
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  top: -5px;
  height: 7px;
  z-index: -1;
  border-radius: 100%;
}

@media only screen and (min-width: 1069px) {
  .grid .tile {
    min-height: var(--tile-height-large, 774px);
  }
}
@media only screen and (min-width: 735px) and (max-width: 1068px) {
  .grid .tile {
    min-height: var(--tile-height-medium, 588px);
  }
}
@media only screen and (max-width: 734px) {
  .grid .tile {
    min-height: var(--tile-height-small, 460px);
  }
}
.grid .grid-item {
  height: 100%;
}
.grid .grid-item .tile-body {
  --typographyBaseline: 6px;
  padding-top: calc(var(--tile-body-padding-top) - var(--typographyBaseline));
}
.grid .grid-item .tile-body-blockquote {
  box-sizing: initial;
}
.grid .grid-item .tile-body-blockquote.large-span-6 {
  align-self: center;
  max-width: 442px;
}
.grid .grid-item.large-span-6:not([class*=medium-span]) .tile-body-blockquote,
.grid .grid-item .medium-span-6 .tile-body-blockquote {
  align-self: center;
}
.grid .grid-item-icon-blockquote .tile-body-blockquote {
  padding-bottom: calc(var(--tile-body-padding-bottom) + 30px);
}

@media only screen and (max-width: 374px) {
  .grid .grid-item .tile,
  .grid .grid-item.will-change .tile {
    transform: none;
  }
}
@media only screen and (max-width: 1068px) {
  .grid .grid-item-icon-blockquote .tile-body-blockquote {
    padding-bottom: calc(var(--tile-body-padding-bottom) + 20px);
  }
  .grid .grid-item.large-span-6:not([class*=medium-span]) .tile-body-blockquote,
  .grid .grid-item.medium-span-6 .tile-body-blockquote {
    max-width: 304px;
  }
}
@media only screen and (max-width: 734px) {
  .grid .grid-item-icon-blockquote .tile-body-blockquote {
    padding-bottom: calc(var(--tile-body-padding-bottom) + 10px);
  }
  .grid .grid-item.small-span-12 .tile-body-blockquote {
    max-width: 243px;
  }
}
:root {
  --highlight-duration: 0.5s;
  --highlight-color: #f2e4f7;
  --highlight-text-color: #a753d3;
}

.home-hero {
  overflow-x: hidden;
}
@media only screen and (max-width: 734px) {
  .home-hero {
    padding-bottom: 35px;
    height: 100%;
  }
}

.home-hero .section-content {
  padding-top: 18px;
  padding-bottom: 94px;
}
@media only screen and (max-width: 1068px) {
  .home-hero .section-content {
    padding-top: 14px;
    padding-bottom: 73px;
  }
}
@media only screen and (max-width: 734px) {
  .home-hero .section-content {
    padding-top: 28px;
    padding-bottom: 17px;
  }
}

.home-hero .hero-header {
  text-align: center;
}

html.enhance-base-xp .home-hero .hero-header,
html.enhance-base-xp .home-hero .hero-picture img {
  opacity: 0;
}

.home-hero {
  overflow-x: hidden;
}
@media only screen and (max-width: 734px) {
  .home-hero {
    margin-bottom: -12px;
  }
}
.home-hero .typo-hero-headline {
  margin-top: 0.15em;
}
.home-hero .typo-hero-intro {
  margin-top: 0.65em;
}
@media only screen and (max-width: 1068px) {
  .home-hero .typo-hero-intro {
    margin-top: 0.8em;
  }
}
@media only screen and (max-width: 734px) {
  .home-hero .typo-hero-intro {
    margin-top: 1.1em;
  }
}

.home-hero .hero-lockup {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  padding-block-end: 50px;
}
@media only screen and (min-width: 1441px) {
  .home-hero .hero-lockup {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .home-hero .hero-lockup {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .home-hero .hero-lockup {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}
@media only screen and (max-width: 1068px) {
  .home-hero .hero-lockup {
    padding-block-end: 40px;
  }
}
@media only screen and (max-width: 734px) {
  .home-hero .hero-lockup {
    padding-block-end: 20px;
  }
}
.home-hero .hero-lockup figure {
  margin-right: -6px;
}
@media only screen and (max-width: 1068px) {
  .home-hero .hero-lockup figure {
    margin-right: -4px;
  }
}
@media only screen and (max-width: 734px) {
  .home-hero .hero-lockup figure {
    margin-right: -2px;
  }
}
@media only screen and (max-width: 734px) {
  .home-hero .hero-lockup img {
    height: 326px;
    width: 734px;
    vertical-align: middle;
  }
}

.section-hero-header {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}
@media only screen and (min-width: 1441px) {
  .section-hero-header {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-hero-header {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .section-hero-header {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}

html.enhanced .section-hero-copy {
  margin-top: 0;
}
html.enhanced .section-hero-copy > * {
  opacity: 0;
  transition: opacity 0.6s linear;
  pointer-events: none;
}
html.enhanced .section-hero-copy .section-hero-copy-body.reveal ~ .section-hero-cta-wrapper,
html.enhanced .section-hero-copy .section-hero-copy-body.reveal ~ .typo-hero-intro,
html.enhanced .section-hero-copy > *.reveal {
  opacity: 1;
  pointer-events: auto;
}
html.enhanced .section-hero-copy .typo-hero-eyebrow,
html.enhanced .section-hero-copy .typo-hero-headline {
  opacity: 1;
}
@media only screen and (min-width: 735px) and (max-width: 1068px) and (max-aspect-ratio: 17/20) {
  html.enhanced .section-hero-copy .typo-hero-intro {
    opacity: 1;
    pointer-events: auto;
  }
}
@media only screen and (min-width: 1069px) and (min-height: 1132px) {
  html.enhanced .section-hero-copy .typo-hero-eyebrow,
  html.enhanced .section-hero-copy .typo-hero-headline {
    opacity: 1;
  }
}

.section-hero-copy {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media only screen and (max-width: 374px) {
  .section-hero-copy {
    display: flex;
    flex-direction: column;
  }
}
.section-hero-copy .typo-hero-eyebrow .visuallyhidden {
  top: 100vh;
}
@media only screen and (max-width: 734px) {
  .section-hero-copy .typo-hero-eyebrow .visuallyhidden {
    top: 125vh;
  }
}
.section-hero-copy .section-hero-copy-availability {
  color: var(--fds-glyph-gray-secondary-alpha);
}

.section-hero-cta-wrapper {
  margin: 0;
}

.home-hero .typo-hero-intro-cta[data-focus-method=key] {
  opacity: 1;
  pointer-events: auto;
}

.section-hero-header--ax-update-focus-visible--reveal .section-hero-copy .section-hero-copy-availability,
.section-hero-header--ax-update-focus-visible--reveal .section-hero-copy .section-hero-copy-body,
.section-hero-header--ax-update-focus-visible--reveal .section-hero-copy .typo-hero-eyebrow,
.section-hero-header--ax-update-focus-visible--reveal .section-hero-copy .typo-hero-headline,
.section-hero-header--ax-update-focus-visible--reveal .section-hero-copy .typo-hero-intro-cta,
.section-hero-header--ax-update-focus-visible:focus-within .section-hero-copy .section-hero-copy-availability,
.section-hero-header--ax-update-focus-visible:focus-within .section-hero-copy .section-hero-copy-body,
.section-hero-header--ax-update-focus-visible:focus-within .section-hero-copy .typo-hero-eyebrow,
.section-hero-header--ax-update-focus-visible:focus-within .section-hero-copy .typo-hero-headline,
.section-hero-header--ax-update-focus-visible:focus-within .section-hero-copy .typo-hero-intro-cta {
  opacity: 1;
}

.section-commitment {
  --section-color: var(--fds-black);
  padding-top: 30px;
  padding-bottom: var(--global-section-padding);
}
.section-commitment .tile {
  min-height: 0;
}
.section-commitment .section-intro {
  --section-intro-padding-bottom: 44px;
}
@media only screen and (max-width: 1068px) {
  .section-commitment .section-intro {
    --section-intro-padding-bottom: 40px;
  }
}
@media only screen and (max-width: 734px) {
  .section-commitment .section-intro {
    --section-intro-padding-bottom: 30px;
  }
}
.section-commitment .section-intro-copy {
  max-width: 56.7647058824rem;
  margin-top: 15px;
}
@media only screen and (max-width: 1068px) {
  .section-commitment .section-intro-copy {
    max-width: 49.4117647059rem;
  }
}
@media only screen and (max-width: 734px) {
  .section-commitment .section-intro-copy {
    max-width: none;
  }
}
.section-commitment .section-intro-cta {
  margin-top: 18px;
}
@media only screen and (max-width: 734px) {
  .section-commitment .section-intro-cta {
    margin-top: 16px;
  }
}
.section-commitment .section-intro-coming-soon {
  color: var(--fds-glyph-gray-secondary-alpha);
}
.section-commitment .section-intro-coming-soon,
.section-commitment .section-intro-copy,
.section-commitment .section-intro-cta {
  font-weight: 400;
}
.section-commitment .section-intro-cta,
.section-commitment .section-intro-headline {
  margin-bottom: 0;
}
@media only screen and (min-width: 1069px) {
  .section-commitment figure {
    background-size: cover !important;
    height: auto;
  }
}

.section-commitment .grid-item-writing .tile-body,
.section-commitment .grid-item-siri .tile-body,
.section-commitment .grid-item-images .tile-body {
  padding-inline: 0;
  justify-content: end;
}

.section-target {
  --section-color: var(--fds-black);
  padding-top: 30px;
}

.section-strengths {
  --section-color: var(--black);
}

.section-strengths .tile,
.section-target .tile,
.section-goals .tile,
.section-commitment .tile {
  box-shadow: var(--fds-box-shadow);
}
.section-strengths .section-intro-cta,
.section-target .section-intro-cta,
.section-goals .section-intro-cta,
.section-commitment .section-intro-cta {
  font-weight: 400;
  color: white;
  margin-top: 18px;
}

.section-target .grid-item-tapback .tile-content {
  background: linear-gradient(to bottom right, #027fff 50%, #4eeec2 125%);
}
.section-target .grid-item-tapback .tile-blockquote {
  margin-top: -40px;
  max-width: 80%;
}
@media only screen and (max-width: 1068px) {
  .section-target .grid-item-tapback .tile-blockquote {
    margin-top: -25px;
    max-width: 90%;
  }
}
@media only screen and (max-width: 734px) {
  .section-target .grid-item-tapback .tile-blockquote {
    margin-top: -20px;
  }
}

.section-target .grid-item-satellite {
  --tile-height-small: 520px;
}
@media only screen and (max-width: 734px) {
  .section-target .grid-item-satellite .tile-body-blockquote {
    max-width: none !important;
    color: white;
  }
}

.section-target .grid-item-schedule .image-container {
  height: 470px;
}
@media only screen and (max-width: 1068px) {
  .section-target .grid-item-schedule .image-container {
    height: 340px;
  }
}
@media only screen and (max-width: 734px) {
  .section-target .grid-item-schedule .image-container {
    height: 260px;
  }
}

.section-goals {
  --section-color: var(--black);
}

.section-goals .grid-item-synced.grid-item.small-span-12 .tile-body.tile-body-blockquote {
  max-width: none;
}

.section-goals .grid-item-synced {
  --tile-height-small: 534px;
}
.section-goals .grid-item-synced .tile-content {
  background: linear-gradient(135deg, #14b1a1 10%, #6eefc2);
}
.section-goals .grid-item-synced .flex {
  display: flex;
  align-items: center;
}
.section-goals .grid-item-synced .dots {
  --dot-size: 8px;
  position: relative;
  width: 28px;
  height: var(--dot-size);
}
.section-goals .grid-item-synced .dot {
  display: inline-block;
  position: absolute;
  top: 0;
  left: calc(10px * var(--dot-index));
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
  animation: none;
  transform: scale(0.5) translateZ(0);
}
.section-goals .grid-item-synced .svg {
  margin-inline: 10px;
}
@media only screen and (max-width: 1068px) {
  .section-goals .grid-item-synced .svg {
    margin-inline: 8px;
    width: auto;
    height: 75px;
  }
}
.section-goals .grid-item-synced .svg-ipad {
  margin-inline-start: 0;
}
.section-goals .grid-item-synced .svg-macbook {
  margin-inline: 0;
}
@media only screen and (max-width: 374px) {
  .section-goals .grid-item-synced .nowrap {
    display: inline;
    white-space: normal;
  }
}

.section-goals .grid-item-synced .blockquote-content .flex.play-anim .dot {
  animation: pulse 2s 2;
  animation-delay: calc(0.3s * var(--dot-index) + var(--dot-delay, 0s));
}

@keyframes pulse {
  50% {
    transform: scale(1) translateZ(0);
    background-color: var(--fds-fill);
  }
  0%, 25%, 75%, 100% {
    transform: scale(0.5) translateZ(0);
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.section-game-mode {
  --section-color: var(--denim);
}

html.text-zoom .section-game-mode .grid-item-performance {
  grid-column: span 12;
}
html.text-zoom .section-game-mode .grid-item-responsiveness {
  grid-column: span 12;
}
html.text-zoom .section-game-mode .grid-item-responsiveness figure {
  align-self: center;
}

@media only screen and (min-width: 735px) {
  .section-game-mode .grid-item-responsiveness .tile-body {
    padding-inline-end: 0;
  }
}
@media only screen and (max-width: 734px) {
  .section-game-mode .grid-item-responsiveness .tile-body {
    padding-inline: 0;
  }
}
@media only screen and (max-width: 440px) {
  .section-game-mode .grid-item-responsiveness .tile-body {
    padding-inline-start: var(--tile-padding-left);
  }
}
@media only screen and (max-width: 734px) {
  .section-game-mode .grid-item-responsiveness figure {
    max-width: 100%;
    background-position: 50%;
  }
}
@media only screen and (max-width: 440px) {
  .section-game-mode .grid-item-responsiveness figure {
    background-position: 0;
  }
}

.section-apple-tv {
  --section-color: var(--tomato);
}
.section-apple-tv .grid-item-insight .caption {
  font-size: 12px;
  line-height: 1.3333733333;
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-block-start: 30px;
}
.section-apple-tv .grid-item-insight .caption a {
  color: inherit;
}
.section-apple-tv .grid-item-dialogue {
  --tile-height-small: 680px;
}

html.text-zoom .section-apple-tv .grid-item-insight {
  grid-column: span 12;
}
html.text-zoom .section-apple-tv .grid-item-dialogue {
  --tile-height-large: 29.4117647059rem;
  grid-column: span 12;
}

.overview-fast-customer-service {
  width: 392px;
  height: 398px;
  background-size: 392px 398px;
  background-repeat: no-repeat;
  background-image: url("./images/services_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-fast-customer-service {
    background-image: url("./images/services_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-fast-customer-service {
    width: 502px;
    height: 464px;
    background-size: 502px 464px;
    background-image: url("./images/services_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-fast-customer-service {
    background-image: url("./images/services_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-fast-customer-service {
    width: 480px;
    height: 502px;
    background-size: 480px 502px;
    background-image: url("./images/services_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-fast-customer-service {
    background-image: url("./images/services_small_2x.jpg");
  }
}

.overview-fast-customer-service.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/services_large.jpg");
  background-size: cover !important;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-fast-customer-service.scalable {
    background-image: url("./images/services_large_2x.jpg");
  }
}
.overview-fast-customer-service.scalable::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media only screen and (max-width: 1068px) {
  .overview-fast-customer-service.scalable {
    background-image: url("./images/services_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-fast-customer-service.scalable {
    background-image: url("./images/services_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-fast-customer-service.scalable::after {
    padding-bottom: 70%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-fast-customer-service.scalable {
    background-image: url("./images/services_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-fast-customer-service.scalable {
    background-image: url("./images/services_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-fast-customer-service.scalable::after {
    padding-bottom: 80%;
  }
}

.overview-fast-customer-service.center {
  position: relative;
  left: 50%;
  margin-left: -196px;
}
@media only screen and (max-width: 1068px) {
  .overview-fast-customer-service.center {
    left: 50%;
    margin-left: -251px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-fast-customer-service.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-fast-customer-service.use-width {
  max-width: 446px;
}
@media only screen and (max-width: 1068px) {
  .overview-fast-customer-service.use-width {
    max-width: 758px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-fast-customer-service.use-width {
    max-width: 480px;
  }
}

.overview-reasonable-pricing {
  width: 452px;
  height: 596px;
  background-size: 452px 596px;
  background-repeat: no-repeat;
  background-image: url("./images/writing_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-reasonable-pricing {
    background-image: url("./images/writing_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-reasonable-pricing {
    width: 760px;
    height: 576px;
    background-size: 760px 576px;
    background-image: url("./images/writing_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-reasonable-pricing {
    background-image: url("./images/writing_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-reasonable-pricing {
    width: 480px;
    height: 416px;
    background-size: 480px 416px;
    background-image: url("./images/writing_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-reasonable-pricing {
    background-image: url("./images/writing_small_2x.jpg");
  }
}

.overview-reasonable-pricing.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/writing_large.jpg");
  background-size: cover !important;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-reasonable-pricing.scalable {
    background-image: url("./images/writing_large_2x.jpg");
  }
}
.overview-reasonable-pricing.scalable::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media only screen and (max-width: 1068px) {
  .overview-reasonable-pricing.scalable {
    background-image: url("./images/writing_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-reasonable-pricing.scalable {
    background-image: url("./images/writing_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-reasonable-pricing.scalable::after {
    padding-bottom: 70%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-reasonable-pricing.scalable {
    background-image: url("./images/writing_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-reasonable-pricing.scalable {
    background-image: url("./images/writing_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-reasonable-pricing.scalable::after {
    padding-bottom: 80%;
  }
}

.overview-reasonable-pricing.center {
  position: relative;
  left: 50%;
  margin-left: -226px;
}
@media only screen and (max-width: 1068px) {
  .overview-reasonable-pricing.center {
    left: 50%;
    margin-left: -380px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-reasonable-pricing.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-reasonable-pricing.use-width {
  max-width: 446px;
}
@media only screen and (max-width: 1068px) {
  .overview-reasonable-pricing.use-width {
    max-width: 758px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-reasonable-pricing.use-width {
    max-width: 480px;
  }
}

.overview-expert-team-member {
  width: 446px;
  height: 444px;
  background-size: 446px 444px;
  background-repeat: no-repeat;
  background-image: url("./images/expert_team_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-expert-team-member {
    background-image: url("./images/expert_team_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-expert-team-member {
    width: 758px;
    height: 502px;
    background-size: 758px 502px;
    background-image: url("./images/expert_team_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-expert-team-member {
    background-image: url("./images/expert_team_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-expert-team-member {
    width: 480px;
    height: 378px;
    background-size: 480px 378px;
    background-image: url("./images/expert_team_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-expert-team-member {
    background-image: url("./images/expert_team_small_2x.jpg");
  }
}

.overview-expert-team-member.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/expert_team_large.jpg");
  background-size: cover !important;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-expert-team-member.scalable {
    background-image: url("./images/expert_team_large_2x.jpg");
  }
}
.overview-expert-team-member.scalable::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media only screen and (max-width: 1068px) {
  .overview-expert-team-member.scalable {
    background-image: url("./images/expert_team_medium.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-expert-team-member.scalable {
    background-image: url("./images/expert_team_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-expert-team-member.scalable::after {
    padding-bottom: 70%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-expert-team-member.scalable {
    background-image: url("./images/expert_team_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-expert-team-member.scalable {
    background-image: url("./images/expert_team_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-expert-team-member.scalable::after {
    padding-bottom: 80%;
  }
}

.overview-expert-team-member.center {
  position: relative;
  left: 50%;
  margin-left: -223px;
}
@media only screen and (max-width: 1068px) {
  .overview-expert-team-member.center {
    left: 50%;
    margin-left: -379px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-expert-team-member.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-expert-team-member.use-width {
  max-width: 446px;
}
@media only screen and (max-width: 1068px) {
  .overview-expert-team-member.use-width {
    max-width: 758px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-expert-team-member.use-width {
    max-width: 480px;
  }
}

.overview-game-mode-accessory-responsiveness {
  width: 413px;
  height: 464px;
  background-size: 413px 464px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/joystick_large_2x.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-game-mode-accessory-responsiveness {
    background-image: url("../../assets/images/joystick_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-game-mode-accessory-responsiveness {
    width: 411px;
    height: 462px;
    background-size: 411px 462px;
    background-image: url("../../assets/images/joystick_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-game-mode-accessory-responsiveness {
    background-image: url("../../assets/images/joystick_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-game-mode-accessory-responsiveness {
    width: 356px;
    height: 393px;
    background-size: 356px 393px;
    background-image: url("../../assets/images/joystick_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-game-mode-accessory-responsiveness {
    background-image: url("../../assets/images/joystick_small_2x.jpg");
  }
}

.overview-game-mode-accessory-responsiveness.scalable {
  background-repeat: no-repeat;
  background-image: url("../../assets/images/joystick_large_2x.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-size: contain !important;
  background-position: 50%;
}
.overview-game-mode-accessory-responsiveness.scalable::after {
  content: "";
  display: block;
  padding-bottom: 112.3486682809%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-game-mode-accessory-responsiveness.scalable {
    background-image: url("../../assets/images/joystick_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-game-mode-accessory-responsiveness.scalable {
    background-image: url("../../assets/images/joystick_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-game-mode-accessory-responsiveness.scalable {
    background-image: url("../../assets/images/joystick_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-game-mode-accessory-responsiveness.scalable::after {
    padding-bottom: 112.4087591241%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-game-mode-accessory-responsiveness.scalable {
    background-image: url("../../assets/images/joystick_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-game-mode-accessory-responsiveness.scalable {
    background-image: url("../../assets/images/joystick_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-game-mode-accessory-responsiveness.scalable::after {
    padding-bottom: 110.393258427%;
  }
}

.overview-game-mode-accessory-responsiveness.center {
  position: relative;
  left: 50%;
  margin-left: -206.5px;
}
@media only screen and (max-width: 1068px) {
  .overview-game-mode-accessory-responsiveness.center {
    left: 50%;
    margin-left: -205.5px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-game-mode-accessory-responsiveness.center {
    left: 50%;
    margin-left: -178px;
  }
}
.overview-game-mode-accessory-responsiveness.use-width {
  max-width: 413px;
}
@media only screen and (max-width: 1068px) {
  .overview-game-mode-accessory-responsiveness.use-width {
    max-width: 411px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-game-mode-accessory-responsiveness.use-width {
    max-width: 356px;
  }
}

.hero-lockup {
  overflow: hidden; /* 确保内容不会溢出 */
  width: 100%; /* 确保外层 div 的宽度 */
  transition: width 0.3s ease; /* 平滑过渡 */
}

.overview-zem-hero-2025 {
  width: 100%; /* figure 的初始宽度 */
  height: auto; /* 根据需要设置 */
}

.responsive-image {
  width: 100%; /* 确保图片宽度占满 figure */
  height: auto; /* 保持纵横比 */
  transition: width 0.3s ease; /* 图片的平滑过渡 */
  -webkit-border-bottom-right-radius: var(--global-card-corner-radius);
  -webkit-border-bottom-left-radius: var(--global-card-corner-radius);
  -moz-border-radius-bottomright: var(--global-card-corner-radius);
  -moz-border-radius-bottomleft: var(--global-card-corner-radius);
  border-bottom-right-radius: var(--global-card-corner-radius);
  border-bottom-left-radius: var(--global-card-corner-radius);
}
@media only screen and (max-width: 734px) {
  .responsive-image {
    border-radius: 0px;
  }
}
.responsive-image img {
  -webkit-border-bottom-right-radius: var(--global-card-corner-radius);
  -webkit-border-bottom-left-radius: var(--global-card-corner-radius);
  -moz-border-radius-bottomright: var(--global-card-corner-radius);
  -moz-border-radius-bottomleft: var(--global-card-corner-radius);
  border-bottom-right-radius: var(--global-card-corner-radius);
  border-bottom-left-radius: var(--global-card-corner-radius);
}

.overview-earthing-collapsible-sections-bg {
  width: 682px;
  height: 750px;
  background-size: 682px 750px;
  background-repeat: no-repeat;
  background-image: url("./images/notes_bg_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-earthing-collapsible-sections-bg {
    background-image: url("./images/notes_bg_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-earthing-collapsible-sections-bg {
    width: 504px;
    height: 600px;
    background-size: 504px 600px;
    background-image: url("./images/notes_bg_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-earthing-collapsible-sections-bg {
    background-image: url("./images/notes_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-earthing-collapsible-sections-bg {
    width: 480px;
    height: 520px;
    background-size: 480px 520px;
    background-image: url("./images/notes_bg_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-earthing-collapsible-sections-bg {
    background-image: url("./images/notes_bg_small_2x.jpg");
  }
}

.overview-earthing-collapsible-sections-bg.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/notes_bg_large.jpg");
  background-size: contain !important;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-earthing-collapsible-sections-bg.scalable {
    background-image: url("./images/notes_bg_large_2x.jpg");
  }
}
.overview-earthing-collapsible-sections-bg.scalable::after {
  content: "";
  display: block;
  padding-bottom: 109.9706744868%;
}
@media only screen and (max-width: 1068px) {
  .overview-earthing-collapsible-sections-bg.scalable {
    background-image: url("./images/notes_bg_medium.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-earthing-collapsible-sections-bg.scalable {
    background-image: url("./images/notes_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-earthing-collapsible-sections-bg.scalable::after {
    padding-bottom: 119.0476190476%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-earthing-collapsible-sections-bg.scalable {
    background-image: url("./images/notes_bg_small.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-earthing-collapsible-sections-bg.scalable {
    background-image: url("./images/notes_bg_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-earthing-collapsible-sections-bg.scalable::after {
    padding-bottom: 108.3333333333%;
  }
}

.overview-earthing-collapsible-sections-bg.center {
  position: relative;
  left: 50%;
  margin-left: -341px;
}
@media only screen and (max-width: 1068px) {
  .overview-earthing-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -252px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-earthing-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-earthing-collapsible-sections-bg.use-width {
  max-width: 682px;
}
@media only screen and (max-width: 1068px) {
  .overview-earthing-collapsible-sections-bg.use-width {
    max-width: 504px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-earthing-collapsible-sections-bg.use-width {
    max-width: 480px;
  }
}

.overview-notes-collapsible-sections-ui {
  width: 430px;
  height: 448px;
  background-size: 430px 448px;
  background-repeat: no-repeat;
  background-image: url("./images/notes_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-notes-collapsible-sections-ui {
    background-image: url("./images/notes_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-notes-collapsible-sections-ui {
    width: 373px;
    height: 389px;
    background-size: 373px 389px;
    background-image: url("./images/notes_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-notes-collapsible-sections-ui {
    background-image: url("./images/notes_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-notes-collapsible-sections-ui {
    width: 294px;
    height: 308px;
    background-size: 294px 308px;
    background-image: url("./images/notes_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-notes-collapsible-sections-ui {
    background-image: url("./images/notes_small_2x.jpg");
  }
}

.overview-notes-collapsible-sections-ui.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/notes_large.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-size: contain !important;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-notes-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_large_2x.jpg");
  }
}
.overview-notes-collapsible-sections-ui.scalable::after {
  content: "";
  display: block;
  padding-bottom: 104.1860465116%;
}
@media only screen and (max-width: 1068px) {
  .overview-notes-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-notes-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-notes-collapsible-sections-ui.scalable::after {
    padding-bottom: 104.2895442359%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-notes-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-notes-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-notes-collapsible-sections-ui.scalable::after {
    padding-bottom: 104.7619047619%;
  }
}

.overview-notes-collapsible-sections-ui.center {
  position: relative;
  left: 50%;
  margin-left: -215px;
}
@media only screen and (max-width: 1068px) {
  .overview-notes-collapsible-sections-ui.center {
    left: 50%;
    margin-left: -186.5px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-notes-collapsible-sections-ui.center {
    left: 50%;
    margin-left: -147px;
  }
}
.overview-notes-collapsible-sections-ui.use-width {
  max-width: 430px;
}
@media only screen and (max-width: 1068px) {
  .overview-notes-collapsible-sections-ui.use-width {
    max-width: 373px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-notes-collapsible-sections-ui.use-width {
    max-width: 294px;
  }
}

.overview-lightning-collapsible-sections-bg {
  width: 682px;
  height: 750px;
  background-size: 682px 750px;
  background-repeat: no-repeat;
  background-image: url("./images/goals-lightning_system_bg_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-lightning-collapsible-sections-bg {
    background-image: url("./images/goals-lightning_system_bg_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-lightning-collapsible-sections-bg {
    width: 504px;
    height: 600px;
    background-size: 504px 600px;
    background-image: url("./images/goals-lightning_system_bg_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-lightning-collapsible-sections-bg {
    background-image: url("./images/goals-lightning_system_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-lightning-collapsible-sections-bg {
    width: 480px;
    height: 520px;
    background-size: 480px 520px;
    background-image: url("./images/goals-lightning_system_bg_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-lightning-collapsible-sections-bg {
    background-image: url("./images/goals-lightning_system_bg_small_2x.jpg");
  }
}

.overview-lightning-collapsible-sections-bg.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/goals-lightning_system_bg_large.jpg");
  background-size: contain !important;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-lightning-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-lightning_system_bg_large_2x.jpg");
  }
}
.overview-lightning-collapsible-sections-bg.scalable::after {
  content: "";
  display: block;
  padding-bottom: 109.9706744868%;
}
@media only screen and (max-width: 1068px) {
  .overview-lightning-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-lightning_system_bg_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-lightning-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-lightning_system_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-lightning-collapsible-sections-bg.scalable::after {
    padding-bottom: 119.0476190476%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-lightning-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-lightning_system_bg_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-lightning-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-lightning_system_bg_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-lightning-collapsible-sections-bg.scalable::after {
    padding-bottom: 108.3333333333%;
  }
}

.overview-lightning-collapsible-sections-bg.center {
  position: relative;
  left: 50%;
  margin-left: -341px;
}
@media only screen and (max-width: 1068px) {
  .overview-lightning-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -252px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-lightning-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-lightning-collapsible-sections-bg.use-width {
  max-width: 682px;
}
@media only screen and (max-width: 1068px) {
  .overview-lightning-collapsible-sections-bg.use-width {
    max-width: 504px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-lightning-collapsible-sections-bg.use-width {
    max-width: 480px;
  }
}

.overview-cabling-collapsible-sections-bg {
  width: 682px;
  height: 750px;
  background-size: 682px 750px;
  background-repeat: no-repeat;
  background-image: url("./images/goals-network_cabling_bg_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-cabling-collapsible-sections-bg {
    background-image: url("./images/goals-network_cabling_bg_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-cabling-collapsible-sections-bg {
    width: 504px;
    height: 600px;
    background-size: 504px 600px;
    background-image: url("./images/goals-network_cabling_bg_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-cabling-collapsible-sections-bg {
    background-image: url("./images/goals-network_cabling_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-cabling-collapsible-sections-bg {
    width: 480px;
    height: 520px;
    background-size: 480px 520px;
    background-image: url("./images/goals-network_cabling_bg_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-cabling-collapsible-sections-bg {
    background-image: url("./images/goals-network_cabling_bg_small_2x.jpg");
  }
}

.overview-cabling-collapsible-sections-bg.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/goals-network_cabling_bg_large.jpg");
  background-size: contain !important;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-cabling-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-network_cabling_bg_large_2x.jpg");
  }
}
.overview-cabling-collapsible-sections-bg.scalable::after {
  content: "";
  display: block;
  padding-bottom: 109.9706744868%;
}
@media only screen and (max-width: 1068px) {
  .overview-cabling-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-network_cabling_bg_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-cabling-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-network_cabling_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-cabling-collapsible-sections-bg.scalable::after {
    padding-bottom: 119.0476190476%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-cabling-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-network_cabling_bg_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-cabling-collapsible-sections-bg.scalable {
    background-image: url("./images/goals-network_cabling_bg_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-cabling-collapsible-sections-bg.scalable::after {
    padding-bottom: 108.3333333333%;
  }
}

.overview-cabling-collapsible-sections-bg.center {
  position: relative;
  left: 50%;
  margin-left: -341px;
}
@media only screen and (max-width: 1068px) {
  .overview-cabling-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -252px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-cabling-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-cabling-collapsible-sections-bg.use-width {
  max-width: 682px;
}
@media only screen and (max-width: 1068px) {
  .overview-cabling-collapsible-sections-bg.use-width {
    max-width: 504px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-cabling-collapsible-sections-bg.use-width {
    max-width: 480px;
  }
}

.overview-seamless-collapsible-sections-bg {
  width: 682px;
  height: 750px;
  background-size: 682px 750px;
  background-repeat: no-repeat;
  background-image: url("./images/seamless-network_bg_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-seamless-collapsible-sections-bg {
    background-image: url("./images/seamless-network_bg_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-bg {
    width: 504px;
    height: 600px;
    background-size: 504px 600px;
    background-image: url("./images/seamless-network_bg_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-bg {
    background-image: url("./images/seamless-network_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-bg {
    width: 480px;
    height: 520px;
    background-size: 480px 520px;
    background-image: url("./images/seamless-network_bg_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-bg {
    background-image: url("./images/seamless-network_bg_small_2x.jpg");
  }
}

.overview-seamless-collapsible-sections-bg.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/seamless-network_bg_large.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-size: contain !important;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-seamless-collapsible-sections-bg.scalable {
    background-image: url("./images/seamless-network_bg_large_2x.jpg");
  }
}
.overview-seamless-collapsible-sections-bg.scalable::after {
  content: "";
  display: block;
  padding-bottom: 109.9706744868%;
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-bg.scalable {
    background-repeat: no-repeat;
    background-image: url("./images/seamless-network_bg_medium.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-bg.scalable {
    background-image: url("./images/seamless-network_bg_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-bg.scalable::after {
    content: "";
    display: block;
    padding-bottom: 119.0476190476%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-bg.scalable {
    background-repeat: no-repeat;
    background-image: url("./images/seamless-network_bg_small.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-bg.scalable {
    background-image: url("./images/seamless-network_bg_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-bg.scalable::after {
    content: "";
    display: block;
    padding-bottom: 108.3333333333%;
  }
}

.overview-seamless-collapsible-sections-bg.center {
  position: relative;
  left: 50%;
  margin-left: -341px;
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -252px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-bg.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-seamless-collapsible-sections-bg .use-width {
  max-width: 682px;
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-bg .use-width {
    max-width: 504px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-bg .use-width {
    max-width: 480px;
  }
}

.overview-seamless-collapsible-sections-ui {
  width: 430px;
  height: 448px;
  background-size: 430px 448px;
  background-repeat: no-repeat;
  background-image: url("./images/notes_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-seamless-collapsible-sections-ui {
    background-image: url("./images/notes_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-ui {
    width: 373px;
    height: 389px;
    background-size: 373px 389px;
    background-image: url("./images/notes_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-ui {
    background-image: url("./images/notes_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-ui {
    width: 294px;
    height: 308px;
    background-size: 294px 308px;
    background-image: url("./images/notes_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-ui {
    background-image: url("./images/notes_small_2x.jpg");
  }
}

.overview-seamless-collapsible-sections-ui.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/notes_large.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-seamless-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_large_2x.jpg");
  }
}
.overview-seamless-collapsible-sections-ui.scalable::after {
  content: "";
  display: block;
  padding-bottom: 104.1860465116%;
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_medium.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_medium_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-ui.scalable::after {
    padding-bottom: 104.2895442359%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_small.jpg");
    background-size: cover;
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-seamless-collapsible-sections-ui.scalable {
    background-image: url("./images/notes_small_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-ui.scalable::after {
    padding-bottom: 104.7619047619%;
  }
}

.overview-seamless-collapsible-sections-ui.center {
  position: relative;
  left: 50%;
  margin-left: -215px;
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-ui.center {
    left: 50%;
    margin-left: -186.5px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-ui.center {
    left: 50%;
    margin-left: -147px;
  }
}

.overview-seamless-collapsible-sections-ui.use-width {
  max-width: 430px;
}
@media only screen and (max-width: 1068px) {
  .overview-seamless-collapsible-sections-ui.use-width {
    max-width: 373px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-seamless-collapsible-sections-ui.use-width {
    max-width: 294px;
  }
}

.overview-home-vision {
  width: 1390px;
  height: 690px;
  background-size: 1390px 690px;
  background-repeat: no-repeat;
  background-image: url("./images/vision_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-home-vision {
    background-image: url("./images/vision_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-home-vision {
    width: 1028px;
    height: 590px;
    background-size: 1028px 590px;
    background-image: url("./images/vision_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-home-vision {
    background-image: url("./images/vision_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-vision {
    width: 480px;
    height: 520px;
    background-size: 480px 520px;
    background-image: url("./images/vision_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-home-vision {
    background-image: url("./images/vision_small_2x.jpg");
  }
}

.overview-home-vision.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/vision_large.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-size: contain !important;
  background-position: 50%;
}
.overview-home-vision.scalable::after {
  content: "";
  display: block;
  padding-bottom: 49.6402877698%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-home-vision.scalable {
    background-image: url("./images/vision_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-home-vision.scalable {
    background-image: url("./images/vision_medium.jpg");
  }
  .overview-home-vision.scalable::after {
    padding-bottom: 57.3929961089%;
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-home-vision.scalable {
    background-image: url("./images/vision_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-vision.scalable {
    background-image: url("./images/vision_small.jpg");
  }
  .overview-home-vision.scalable::after {
    padding-bottom: 108.3333333333%;
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-home-vision.scalable {
    background-image: url("./images/vision_small_2x.jpg");
  }
}

.overview-home-vision.center {
  position: relative;
  left: 50%;
  margin-left: -695px;
}
@media only screen and (max-width: 1068px) {
  .overview-home-vision.center {
    left: 50%;
    margin-left: -514px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-vision.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-home-vision.use-width {
  max-width: 1390px;
}
@media only screen and (max-width: 1068px) {
  .overview-home-vision.use-width {
    max-width: 1028px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-vision.use-width {
    max-width: 480px;
  }
}

.overview-home-mission {
  width: 1390px;
  height: 690px;
  background-size: 1390px 690px;
  background-repeat: no-repeat;
  background-image: url("./images/tapbacks_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-home-mission {
    background-image: url("./images/tapbacks_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-home-mission {
    width: 1028px;
    height: 590px;
    background-size: 1028px 590px;
    background-image: url("./images/tapbacks_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-home-mission {
    background-image: url("./images/tapbacks_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-mission {
    width: 480px;
    height: 520px;
    background-size: 480px 520px;
    background-image: url("./images/tapbacks_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-home-mission {
    background-image: url("./images/tapbacks_small_2x.jpg");
  }
}

.overview-home-mission.scalable {
  background-repeat: no-repeat;
  background-image: url("./images/tapbacks_large.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-size: contain !important;
  background-position: 50%;
}
.overview-home-mission.scalable::after {
  content: "";
  display: block;
  padding-bottom: 49.6402877698%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-home-mission.scalable {
    background-image: url("./images/tapbacks_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-home-mission.scalable {
    background-image: url("./images/tapbacks_medium.jpg");
  }
  .overview-home-mission.scalable::after {
    padding-bottom: 57.3929961089%;
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-home-mission.scalable {
    background-image: url("./images/tapbacks_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-mission.scalable {
    background-image: url("./images/tapbacks_small.jpg");
  }
  .overview-home-mission.scalable::after {
    padding-bottom: 108.3333333333%;
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-home-mission.scalable {
    background-image: url("./images/tapbacks_small_2x.jpg");
  }
}

.overview-home-mission.center {
  position: relative;
  left: 50%;
  margin-left: -695px;
}
@media only screen and (max-width: 1068px) {
  .overview-home-mission.center {
    left: 50%;
    margin-left: -514px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-mission.center {
    left: 50%;
    margin-left: -240px;
  }
}
.overview-home-mission.use-width {
  max-width: 1390px;
}
@media only screen and (max-width: 1068px) {
  .overview-home-mission.use-width {
    max-width: 1028px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-home-mission.use-width {
    max-width: 480px;
  }
}

.overview-passwords-credentials-screen {
  width: 368px;
  height: 798px;
  background-size: 368px 798px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/screenshot_large.jpg");
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-passwords-credentials-screen {
    background-image: url("../../assets/images/screenshot_large_2x.jpg");
  }
}
@media only screen and (max-width: 1068px) {
  .overview-passwords-credentials-screen {
    width: 306px;
    height: 660px;
    background-size: 306px 660px;
    background-image: url("../../assets/images/screenshot_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-passwords-credentials-screen {
    background-image: url("../../assets/images/screenshot_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-passwords-credentials-screen {
    width: 198px;
    height: 426px;
    background-size: 198px 426px;
    background-image: url("../../assets/images/screenshot_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-passwords-credentials-screen {
    background-image: url("../../assets/images/screenshot_small_2x.jpg");
  }
}

.overview-passwords-credentials-screen.scalable {
  background-repeat: no-repeat;
  background-image: url("../../assets/images/screenshot_large.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-size: contain !important;
  background-position: 50%;
}
@media (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .overview-passwords-credentials-screen.scalable {
    background-image: url("../../assets/images/screenshot_large_2x.jpg");
  }
}
.overview-passwords-credentials-screen.scalable::after {
  content: "";
  display: block;
  padding-bottom: 216.847826087%;
}
@media only screen and (max-width: 1068px) {
  .overview-passwords-credentials-screen.scalable::after {
    padding-bottom: 215.6862745098%;
  }
}
@media only screen and (max-width: 734px) {
  .overview-passwords-credentials-screen.scalable::after {
    padding-bottom: 215.1515151515%;
  }
}
@media only screen and (max-width: 1068px) {
  .overview-passwords-credentials-screen.scalable {
    background-image: url("../../assets/images/screenshot_medium.jpg");
  }
}
@media only screen and (max-width: 1068px) and (min-resolution: 1.5dppx), only screen and (max-width: 1068px) and (min-resolution: 144dpi) {
  .overview-passwords-credentials-screen.scalable {
    background-image: url("../../assets/images/screenshot_medium_2x.jpg");
  }
}
@media only screen and (max-width: 734px) {
  .overview-passwords-credentials-screen.scalable {
    background-image: url("../../assets/images/screenshot_small.jpg");
  }
}
@media only screen and (max-width: 734px) and (min-resolution: 1.5dppx), only screen and (max-width: 734px) and (min-resolution: 144dpi) {
  .overview-passwords-credentials-screen.scalable {
    background-image: url("../../assets/images/screenshot_small_2x.jpg");
  }
}

.overview-passwords-credentials-screen.center {
  position: relative;
  left: 50%;
  margin-left: -184px;
}
@media only screen and (max-width: 1068px) {
  .overview-passwords-credentials-screen.center {
    left: 50%;
    margin-left: -153px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-passwords-credentials-screen.center {
    left: 50%;
    margin-left: -99px;
  }
}
.overview-passwords-credentials-screen.use-width {
  max-width: 368px;
}
@media only screen and (max-width: 1068px) {
  .overview-passwords-credentials-screen.use-width {
    max-width: 306px;
  }
}
@media only screen and (max-width: 734px) {
  .overview-passwords-credentials-screen.use-width {
    max-width: 198px;
  }
}

.align-center {
  align-items: center;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}