header {
  --header-color: var(--fds-fill);
  --dx-transition-delay: 1.25s;
  background-color: var(--header-color);
  display: flex;
  position: relative;
  top: 0;
  width: 100%;
  height: 64px;
  margin: auto;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier(0.4, 0, 0.6, 1) 80ms;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}
@media only screen and (max-width: 734px) {
  header {
    background-color: var(--header-color);
  }
}
header .zem-header-panel {
  width: 100%;
  max-width: 1680px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .global-logo {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
  color: black;
}

.globalnav-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.globalnav-content ul {
  margin: 0;
  width: auto;
  -webkit-user-select: none;
  user-select: none;
}
.globalnav-content ul li {
  display: block;
  box-sizing: border-box;
}
.globalnav-content .globalnav-menutrigger-button {
  position: relative;
  height: 48px;
  outline: none;
  background-color: transparent;
  color: black;
  z-index: 2001;
  display: flex;
  align-items: center;
}
.globalnav-content .globalnav-menutrigger-button span.menu {
  width: 76px;
  height: 32px;
  font-size: 17px;
  line-height: 1.1428571429;
  font-weight: 500;
  border-radius: 6px;
  background-color: var(--zem-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.globalnav-content .globalnav-menutrigger-button span.close {
  width: 48px;
  height: 48px;
  font-size: 32px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.globalnav-content .addTransiton {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.33s cubic-bezier(0.4, 0, 0.6, 1), opacity 0.33s var(--fds-animation-expand-collapse-out);
}
.globalnav-content .removeTransition {
  transform: translateY(0%);
  opacity: 1;
  padding-top: 56px;
  transition: transform 0.33s cubic-bezier(0.4, 0, 0.6, 1), opacity 0.33s var(--fds-animation-expand-collapse-in);
}
.globalnav-content #globalnav-flyout {
  background-color: #fafafc;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  margin: auto;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.globalnav-content .globalnav-submenu-trigger-group {
  position: relative;
  display: flex;
}
.globalnav-content .globalnav-submenu-trigger-group .globalnav-submenu-trigger-item {
  list-style: none;
}

.globalnav-content .globalnav-large {
  display: flex;
  align-items: center;
}
.globalnav-content .globalnav-large ul.globalnav-submenu-trigger-group {
  column-gap: 20px;
}
.globalnav-content .globalnav-large .globalnav-link {
  cursor: pointer;
  color: var(--fds-fill-gray);
  text-decoration: none !important;
  display: flex;
  align-items: center;
}
.globalnav-content .globalnav-large .globalnav-link span {
  font-size: 18px;
  line-height: 1.1428571429;
  font-weight: 500;
  letter-spacing: 0.007em;
  padding: 4px 16px;
  border-radius: 4px;
  height: 36px;
  display: flex;
  align-items: center;
}
.globalnav-content .globalnav-large .globalnav-link span:hover {
  color: black;
  background-color: var(--zem-background-color);
}
.globalnav-content .globalnav-large .globalnav-link .tds-icon {
  display: none;
}
.globalnav-content .globalnav-small {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  margin: auto;
}
.globalnav-content .globalnav-small ul {
  flex-direction: column;
}
.globalnav-content .globalnav-small ul li {
  height: 56px;
  display: flex;
  align-items: center;
  transition-delay: var(--dx-transition-delay);
}
.globalnav-content .globalnav-small ul li:not(:last-child) {
  margin-block-end: 16px;
}
.globalnav-content .globalnav-small .globalnav-link {
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: black;
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -7px;
  padding-top: 3px;
  padding-bottom: 4px;
  padding-inline-start: 48px;
  padding-inline-end: 48px;
  white-space: normal;
  transition-delay: 2s;
}
.globalnav-content .globalnav-small .globalnav-link span {
  font-size: var(--title-1);
  line-height: 1.1428571429;
  font-weight: 600;
  letter-spacing: 0.007em;
}
.globalnav-content .globalnav-small .globalnav-link .tds-icon {
  display: block;
}

.tds-icon {
  --tds-bezier: cubic-bezier(0.5, 0, 0, 0.75);
  --tds-size--2x: 18px;
  --tds-icon-size: 16px;
  --tds-size-1x: 8px;
  background: transparent;
  block-size: var(--tds-icon--size);
  content: "";
  display: block;
  inline-size: var(--tds-icon--size);
  max-inline-size: 100%;
  min-block-size: var(--tds-icon--size);
  min-inline-size: var(--tds-icon--size);
  overflow: hidden;
  text-indent: -9999px;
  -webkit-transform: var(--tds-icon--transform);
  transform: var(--tds-icon--transform);
  transition: color 0.33s ease, -webkit-transform 0.5s var(--tds-bezier);
  transition: color 0.33s ease, transform 0.5s var(--tds-bezier);
  transition: color 0.33s ease, transform 0.5s var(--tds-bezier), -webkit-transform 0.5s var(--tds-bezier);
}

.tds-icon--small {
  --tds-icon--size: var(--tds-size--2x);
}

.tds-site-nav-item :is(.tds-site-nav-item-trailing, .tds-icon[class*=chevron]:last-child) {
  color: red;
  transition: color 0.33s ease, -webkit-transform 0.5s var(--tds-bezier);
  transition: color 0.33s ease, transform 0.5s var(--tds-bezier);
  transition: color 0.33s ease, transform 0.5s var(--tds-bezier), -webkit-transform 0.5s var(--tds-bezier);
}

.tds-site-nav-items--vertical .tds-site-nav-item--large .tds-icon:last-child {
  -webkit-margin-start: var(--tds-size-1x);
  margin-inline-start: var(--tds-size-1x);
}

[dir=ltr] .tds-site-nav-items--vertical .tds-site-nav-item--large .tds-icon:last-child {
  margin-left: var(--tds-size-1x);
}

.tds-site-nav-items--vertical .tds-site-nav-item > .tds-icon:last-child:not(tds-icon .tds-icon),
.tds-site-nav-items--vertical .tds-site-nav-item > .tds-site-nav-item-trailing .tds-icon,
.tds-site-nav-items--vertical .tds-site-nav-item > tds-content:has(tds-icon):last-child tds-icon .tds-icon,
.tds-site-nav-items--vertical .tds-site-nav-item > tds-icon:last-child .tds-icon {
  -webkit-margin-end: 2px;
  margin-inline-end: 2px;
}